export enum CustomerLineStatusCdLabel {
  Approved = 'Approved',
  CreditReviewed = 'Credit Reviewed',
  Held = 'Held',
  NotInProcess = 'Submitted',
  NoUpdate = 'No update',
  PendingCredit = 'Pending Credit',
  Rejected = 'Rejected',
  SalesApproved = 'Sales Approved',
  SalesPending = 'Sales Pending',
}
