import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { User } from '@xpo-ltl/sdk-common';
import { UserActionTypes } from './user.action';

export interface UserState extends User {
  role: UserRole;
}

export const initialState: UserState = {
  role: null,
} as UserState;

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.GetUser: {
      return { ...state };
    }
    case UserActionTypes.GetUserRole: {
      return { role: state.role };
    }
    case UserActionTypes.SetUserRole: {
      return Object.assign({}, state, action.payload);
    }
    case UserActionTypes.SetUser: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};
