import { Action } from '@ngrx/store';

export enum LocationActionTypes {
  GetLocation = '[Location] GetLocation',
  SetLocation = '[Location] SetLocation',
  GetShowChangesOnly = '[Location] GetShowChangesOnly',
  SetShowChangesOnly = '[Location] SetShowChangesOnly',
  SetHeaderExpanded = '[Location] SetHeaderExpanded',
  SetLocalContacts = '[Location] SetLocalContacts',
}

export class GetLocation implements Action {
  readonly type = LocationActionTypes.GetLocation;
  constructor() {}
}

export class SetLocation implements Action {
  readonly type = LocationActionTypes.SetLocation;
  constructor(public payload) {}
}

export class GetShowChangesOnly implements Action {
  readonly type = LocationActionTypes.GetShowChangesOnly;
  constructor() {}
}

export class SetShowChangesOnly implements Action {
  readonly type = LocationActionTypes.SetShowChangesOnly;
  constructor(public payload) {}
}

export class SetLocalContacts implements Action {
  readonly type = LocationActionTypes.SetShowChangesOnly;
  constructor(public payload) {}
}

export class SetHeaderExpanded implements Action {
  readonly type = LocationActionTypes.SetShowChangesOnly;
  constructor(public payload) {}
}

export type LocationActions = GetLocation | SetLocation | GetShowChangesOnly | SetShowChangesOnly | SetHeaderExpanded | SetLocalContacts;
