/**
 * Logged in User Role
 *  - Update roles based on your app's needs!
 */
export enum UserRole {
  Coder = 'Coder',
  CreditAnalyst = 'Credit Analyst',
  CodingCreditManager = 'CodingCreditManager',
  noUpdateAuthorization = 'noUpdateAuthorization',
}
