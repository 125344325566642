import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerRequest, GetCustomerResp, RequestCustomerLocationFunction, RequestInvoicePreference } from '@xpo-ltl-2.0/sdk-customer';
import { cloneDeep, takeRightWhile, takeWhile } from 'lodash';
import { AppState } from '..';
import { getRequestedChangeCurrentLine, getChangeRequestData } from '../change-request/change-request.selectors';
import { getShowChangesOnly } from '../location/location.selectors';
import { InvoiceState } from './invoice.reducer';

export const getInvoices = createFeatureSelector<AppState, InvoiceState>('invoice');

export const getInvoiceChangesLocationData = createSelector(
  getInvoices,
  (invoiceState: InvoiceState) => invoiceState.locations
);

export const getInvoicesChangesDetails = createSelector(
  getInvoiceChangesLocationData,
  getRequestedChangeCurrentLine,
  getInvoices,
  getChangeRequestData,
  (locations, changesCurrentLine, invoiceState, changeRequest) => {
    let invoiceData;

    if (invoiceState.invoices?.invInstFullData.length && !document.URL.includes('new-location')) {
      invoiceData = invoiceState.invoices?.invInstFullData?.map((invoice, i) => {
        const billCd = invoice.invoiceInstruction.billCd,
          invoiceCd = invoice.invoiceInstruction.invoiceCd;

        const invoiceChanges = changesCurrentLine?.requestCustomerLocationFunction[0]?.requestInvoicePreference?.find(
          (currentInvoice) => {
            return currentInvoice.invoiceCd === invoiceCd && currentInvoice.billCd === billCd;
          }
        );
        const requestColumn = mapLocationChange(locations, invoiceChanges);
        return {
          current: invoice,
          request: cloneDeep(requestColumn || {}),
          position: i + 1,
          key: billCd + invoiceCd,
          changes: requestColumn ? true : false,
          prevBillTo: prevBillTo(requestColumn, changeRequest),
        };
      });
    } else {
      let invoices = emptyInvoices;
      const locationSeq = Number(document.URL.split('new-location/')[1]?.slice(0, 1)) - 1;
      let newLocationInvoices = changeRequest?.requestCustomerLocationFunction[locationSeq]?.requestInvoicePreference;
      if (newLocationInvoices?.length) {
        invoices = mapNewLocationInvoices(newLocationInvoices, emptyInvoices);
      }
      invoiceData = invoices.map((invoice, i) => {
        const billCd = invoice.invoiceInstruction.billCd,
          invoiceCd = invoice.invoiceInstruction.invoiceCd;

        const invoiceChanges = changesCurrentLine?.requestCustomerLocationFunction[0]?.requestInvoicePreference?.find(
          (currentInvoice) => {
            return currentInvoice.invoiceCd === invoiceCd && currentInvoice.billCd === billCd;
          }
        );
        const requestColumn = mapLocationChange(locations, invoiceChanges);
        return {
          current: invoice,
          request: cloneDeep(requestColumn || invoice),
          position: i + 1,
          key: billCd + invoiceCd,
          changes: requestColumn ? true : false,
          prevBillTo: prevBillTo(requestColumn, changeRequest),
        };
      });
    }

    if (invoiceData.length === 8) {
      invoiceData = [
        { isHeader: true, name: 'Prepaid' },
        ...invoiceData.slice(0, 4),
        { isHeader: true, name: 'Collect' },
        ...invoiceData.slice(4),
      ];
    } else {
      const prepaidInvoices = takeWhile(invoiceData, (i: any) => {
        return i.current.invoiceInstruction.billCd === 'Prepaid';
      });

      const collectInvoices = takeRightWhile(invoiceData, (i: any) => {
        return i.current.invoiceInstruction.billCd === 'Collect';
      });

      invoiceData = [
        { isHeader: true, name: 'Prepaid' },
        ...(prepaidInvoices || []),
        { isHeader: true, name: 'Collect' },
        ...(collectInvoices || []),
      ];
    }

    return invoiceData;
  }
);

const mapNewLocationInvoices = (newLocationInvoices: RequestInvoicePreference[], emptyLocationInvoices: any) => {
  for (const newInvoice of newLocationInvoices) {
    for (const emptyInvoice of emptyLocationInvoices) {
      const billCdEmpty = emptyInvoice.invoiceInstruction.billCd,
        invoiceCdEmpty = emptyInvoice.invoiceInstruction.invoiceCd,
        billCdNew = newInvoice.billCd,
        invoiceCdNew = newInvoice.invoiceCd;
      if (billCdEmpty === billCdNew && invoiceCdEmpty === invoiceCdNew) {
        if (newInvoice.sendToRequestFuncSequenceNbr && !newInvoice.sendToCustomerLocationFuncId) {
          emptyInvoice.invoiceInstruction.useAsEnteredInd = false;
          emptyInvoice.invoiceInstruction.billToRequest = true;
        }
        if (newInvoice.sendToCustomerLocationFuncId) {
          emptyInvoice.invoiceInstruction.useAsEnteredInd = false;
          emptyInvoice.invoiceInstruction.sendToFuncId = true;
        }
      }
    }
  }
  return emptyLocationInvoices;
};

export const prevBillTo = (requestColumn: any, changeRequest: CustomerRequest) => {
  let billTo;
  if (requestColumn && requestColumn.sendToRequestFuncSequenceNbr) {
    const index = changeRequest?.requestCustomerLocationFunction.findIndex(
      (req) => req.requestFuncSequenceNbr === requestColumn.sendToRequestFuncSequenceNbr
    );
    billTo = changeRequest?.requestCustomerLocationFunction[index];
  }
  return billTo;
};

export const getInvoicesData = createSelector(
  getInvoicesChangesDetails,
  getShowChangesOnly,
  (invoicesChanges, showChangesOnly) => {
    return { data: invoicesChanges, showChangesOnly: showChangesOnly };
  }
);

const emptyInvoices = [
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Prepaid',
      invoiceCd: 'Original',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: { useAsEnteredInd: true, billCd: 'Prepaid', invoiceCd: 'Corrected', billToRequest: false },
  },
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Prepaid',
      invoiceCd: 'BalDue',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Prepaid',
      invoiceCd: 'TrueDebtor',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Collect',
      invoiceCd: 'Original',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Collect',
      invoiceCd: 'Corrected',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: {
      useAsEnteredInd: true,
      billCd: 'Collect',
      invoiceCd: 'BalDue',
      billToRequest: false,
      sendToFuncId: false,
    },
  },
  {
    invoiceInstruction: { useAsEnteredInd: true, billCd: 'Collect', invoiceCd: 'TrueDebtor' },
  },
];

const mapLocationChange = (locationData: GetCustomerResp[], changes: RequestInvoicePreference) => {
  if (changes) {
    const location = locationData?.find((currentLoc) => {
      return currentLoc.customerLocationFuncId === changes.sendToCustomerLocationFuncId;
    });
    return location ? { ...location, ...changes } : changes;
  } else {
    return null;
  }
};
