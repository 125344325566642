import { Action } from '@ngrx/store';

export enum SearchRequestActionTypes {
  GetSearchRequestResults = '[SearchRequest] GetSearchRequestResults',
  SetSearchRequestResults = '[SearchRequest] SetSearchRequestResults',
}

export class GetSearchRequestResults implements Action {
  readonly type = SearchRequestActionTypes.GetSearchRequestResults;
  constructor() { }
}

export class SetSearchRequestResults implements Action {
  readonly type = SearchRequestActionTypes.SetSearchRequestResults;
  constructor(public payload) { }
}

export type SearchRequestAction = GetSearchRequestResults | SetSearchRequestResults;
