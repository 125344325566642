<div class="ncis-CustomerHistoryRow">
  <div class="ncis-CustomerHistoryRow-ribbon"></div>
  <div class="ncis-CustomerHistoryRow-action"></div>
  <div class="ncis-CustomerHistoryRow-wrapper">
    <div class="ncis-remarks">
      <span class="ncis-CustomerHistoryRow-label ncis-CustomerHistoryRow-label-bold">Remarks</span
      ><span class="ncis-CustomerHistoryRow-value">{{ requesterRemarks }}</span>
    </div>
    <ng-container *ngIf="isAccount || isInvoices">
      <table class="ncis-alias-details-table" mat-table [dataSource]="dataSource">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ isInvoices ? 'Invoicing Type' : '' }}</th>
          <td
            class="ncis-alias-details-table-column"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              'ncis-row-updated': element.updated,
              'ncis-row-edit': element.requestedChange,
              'ncis-table-title': element.isHeader
            }"
          >
            {{ element.name }}
          </td>
        </ng-container>

        <!-- Before change Column -->
        <ng-container matColumnDef="beforeChange">
          <th class="ncis-alias-details-table-padding" mat-header-cell *matHeaderCellDef>
            {{ this.action === 'Update' ? 'Before Change' : '' }}
          </th>
          <td
            class="ncis-alias-details-table-padding"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'ncis-row-updated': element.updated }"
          >
            {{ element.before }}
          </td>
        </ng-container>

        <!-- After change Column -->
        <ng-container matColumnDef="afterChange">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              {{ this.action === 'Update' ? 'After Change' : '' }}
            </span>
          </th>
          <td
            mat-cell
            class="ncis-alias-details-table-requested"
            *matCellDef="let element"
            [ngClass]="{ 'ncis-row-updated': element.updated }"
          >
            {{ element.after }}
          </td>
        </ng-container>

        <tr class="ncis-table-header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>

    <ng-container *ngIf="isAlias || isContact">
      <table class="ncis-alias-details-table" mat-table [dataSource]="dataSource">
        <!-- Details Column -->
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ action }}</span>
          </th>
          <td
            class="ncis-alias-details-table-column"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'ncis-row-edit': element.requestedChange, 'ncis-table-title': element.isHeader }"
          >
            <ng-container *ngIf="isAlias; else isContact">
              {{ element.text }}
            </ng-container>
            <ng-template #isContact>
              <div>
                {{ element.data?.contactPersonHist?.firstName }}
                {{ element.data?.contactPersonHist?.lastName }}
              </div>
              <div></div>
              <div *ngIf="element.data?.contactPersonHist?.emailId">Email: {{ element.data?.contactPersonHist?.emailId }}</div>
              <div>{{ formatPhone(element.data.contactPersonHist) }}</div>
              <div *ngIf="element.data?.contactPersonHist?.faxAreaCd">{{ formatFax(element.data.contactPersonHist) }}</div>
              <div></div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Before change Column -->
        <ng-container matColumnDef="beforeChange">
          <th class="ncis-alias-details-table-padding" mat-header-cell *matHeaderCellDef>Before Change</th>
          <td
            class="ncis-alias-details-table-padding"
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'ncis-row-updated': element.updated }"
          >
            {{ element.before }}
          </td>
        </ng-container>

        <!-- After change Column -->
        <ng-container matColumnDef="afterChange">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              After Change
            </span>
          </th>
          <td
            mat-cell
            class="ncis-alias-details-table-requested"
            *matCellDef="let element"
            [ngClass]="{ 'ncis-row-updated': element.updated }"
          >
            {{ element.after }}
          </td>
        </ng-container>

        <tr class="ncis-table-header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-container>
  </div>
</div>
