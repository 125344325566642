import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import { getChangeRequestData } from 'src/app/store/change-request/change-request.selectors';
import { DialogComponent, DialogConfig } from '../dialog';

@Component({
  selector: 'app-complete-request-dialog',
  templateUrl: './complete-request-dialog.component.html',
  styleUrls: ['./complete-request-dialog.component.scss'],
})
export class CompleteRequestDialogComponent implements OnInit {
  private ccEmailsRequest: string;
  private requestQueueName;
  private cstRequestId;
  form: FormGroup;

  constructor(
    private customerApi: CustomerApiService,
    private store: Store<AppState>,
    private router: Router,
    private dialogRef: MatDialogRef<CompleteRequestDialogComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getChangeRequestData), take(1)).subscribe((state) => {
      this.requestQueueName = state.requestQueueName;
      this.cstRequestId = state.cstRequestId;
      this.ccEmailsRequest = state.requestEmailAddress?.map((ccEmail) => ccEmail?.emailAddress)?.join(', ');
      this.createForm();
    });
  }

  nextRequest(): void {
    this.completeRequest()
      .pipe(take(1))
      .subscribe((_) => {
        this.customerApi
          .getRequestGrabAndLock({ rqstQueueName: this.requestQueueName })
          .pipe(take(1))
          .subscribe((res) => {
            if (res.requestId) {
              this.router.navigate([AppRoutes.CHANGE_REQUEST_PAGE, res.requestId]);
            } else {
              const dialog: DialogConfig = {
                title: 'There are no more requests in the queue.',
                icon: 'report_problem',
                actions: [
                  {
                    label: 'GO TO DASHBOARD',
                    type: 'primary',
                    resultType: 'CLOSE',
                    resultAction: false,
                    position: 'right',
                  },
                ],
              };
              this.dialog
                .open(DialogComponent, { maxWidth: '600px', data: dialog })
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                  this.router.navigate([AppRoutes.DASHBOARD_CODER_PAGE]);
                });
            }
            this.dialogRef.close();
          });
      });
  }

  goToDashboard(): void {
    this.completeRequest()
      .pipe(take(1))
      .subscribe((_) => {
        this.dialogRef.close();
        this.router.navigate([AppRoutes.DASHBOARD_CODER_PAGE]);
      });
  }

  stayOnRequest(): void {
    this.completeRequest()
      .pipe(take(1))
      .subscribe((_) => {
        this.dialogRef.close();
      });
  }

  getCcEmails(emails): Array<string> {
    const emailList = [];
    emails = emails.split(',');
    emails.forEach((email) => {
      email = email.trim();
      emailList.push(email);
    });
    return emailList;
  }

  private createForm(): void {
    this.form = new FormGroup({
      emailCopyList: new FormControl(this.ccEmailsRequest || '', [
        ValidatorHelper.ccEmailFormatValidator(),
        ValidatorHelper.emailsFormatValidator(),
        ValidatorHelper.emailsMaxLengthValidator(),
      ]),
      comments: new FormControl(''),
    });
  }

  private completeRequest(): Observable<any> {
    const errorHandler = (err) => {
      this.dialogRef.close();
      return throwError(err);
    };

    const emailList = this.form.value.emailCopyList ? this.getCcEmails(this.form.value.emailCopyList) : [];

    return this.customerApi
      .sendEmailForCompletedRequest({
        commentTxt: this.form.controls['comments'].value,
        forwardEmailAddresses: emailList.map((e) => e.trim()),
        requestId: this.cstRequestId,
      })
      .pipe(catchError(errorHandler), take(1));
  }
}
