export enum YesNo {
  TRUE = 'Yes',
  FALSE = 'No',
}

export enum YesNoValue {
  true = 'true',
  false = 'false',
}

export enum YesNoAbbreviated {
  Y = 'Yes',
  N = 'No',
}
