import { Injectable } from '@angular/core';
import { CustomerRequestQueueNameCd, CustomerTypeCd } from '@xpo-ltl/sdk-common';
import { Country } from '../../models/country';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  /** App constants go here */

  static countries: Country[] = [
    { name: 'United States', abbreviation: 'US' },
    { name: 'Canada', abbreviation: 'CN' },
    { name: 'Mexico', abbreviation: 'MX' },
  ];

  static tableHeaderHeight = 40;
  static tableRowHeight = 35;
  static tablePagination = 25;
  static textAreaMaxLength = 800;
  static noteBreakLength = 95;

  // TODO: add this queueList to a store NCIS-840
  static queueList = [
    {
      queueName: CustomerRequestQueueNameCd.CREDIT,
      queueNameLabel: CustomerRequestQueueNameCd.CREDIT.toUpperCase(),
      queueInstId: 48,
    },
    {
      queueName: CustomerRequestQueueNameCd.LOCAL,
      queueNameLabel: CustomerRequestQueueNameCd.LOCAL.toUpperCase(),
      queueInstId: 46,
    },
    {
      queueName: CustomerRequestQueueNameCd.MEXICO,
      queueNameLabel: CustomerRequestQueueNameCd.MEXICO.toUpperCase(),
      queueInstId: 49,
    },
    {
      queueName: CustomerRequestQueueNameCd.OTHERS,
      queueNameLabel: CustomerRequestQueueNameCd.OTHERS.toUpperCase(),
      queueInstId: 54,
    },
    {
      queueName: CustomerRequestQueueNameCd.NATIONAL,
      queueNameLabel: CustomerRequestQueueNameCd.NATIONAL.toUpperCase(),
      queueInstId: 47,
    },
    {
      queueName: CustomerRequestQueueNameCd.PRIORITY,
      queueNameLabel: CustomerRequestQueueNameCd.PRIORITY.toUpperCase(),
      queueInstId: 50,
    },
    {
      queueName: CustomerRequestQueueNameCd.SPECIAL,
      queueNameLabel: CustomerRequestQueueNameCd.SPECIAL.toUpperCase(),
      queueInstId: 52,
    },
  ];

  static snackbarDuration = 10000;
}
