import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerApiService, CustomerRequest } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { YesNo } from 'src/app/change-request-page/models/enums/yes-no';
import { AccountType } from 'src/app/shared/enums/account-form/account-type.enum';
import { CreditStatusHistory } from 'src/app/shared/enums/account-form/credit-status.enum';
import { ActionCodeDetails, ActionCodeMap } from 'src/app/shared/enums/action-code.enum';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { AppState } from 'src/app/store';
import {
  getChangeRequest,
  getChangeRequestId,
  getRequestedChangeCurrentLine,
} from 'src/app/store/change-request/change-request.selectors';
import { getLocationData } from 'src/app/store/location/location.selectors';

@Component({
  selector: 'detail-customer-history',
  templateUrl: './detail-customer-history.component.html',
  styleUrls: ['./detail-customer-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-CustomerHistory',
  },
})
export class DetailCustomerHistoryComponent implements ICellRendererAngularComp, OnDestroy {
  private destroy = new Subject();
  params: any;
  isChangeRequest = false;
  gridActions: any;
  changeRequestId = null;
  remarks = '';
  locationSeqNbr = '';
  customerRequest: CustomerRequest;
  requestChangesCurrentLocation: CustomerRequest;
  locationId;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<any>();
  requesterRemarks: string;
  type: string;
  action: string;
  isAlias: boolean;
  isInvoices: boolean;
  isAccount: boolean;
  isContact: boolean;
  aliasValidKeys: string[] = ['address', 'cityName', 'countryCd', 'name1', 'name2', 'stateCd', 'zip4Cd', 'zipCd'];
  contactValidKeys: string[] = [
    'businessPhoneAreaCd',
    'businessPhoneCountryCd',
    'businessPhoneNbr',
    'countryCd',
    'emailId',
    'faxAreaCd',
    'faxNbr',
    'firstName',
    'lastName',
    'title',
  ];
  accountFields: any[] = [
    {
      name: 'Account',
      isHeader: true,
    },
    {
      name: 'MAD CODE',
      key: 'madCd',
    },
    {
      name: 'STATUS',
      key: 'statusCd',
    },
    {
      name: 'ACCOUNT TYPE',
      key: 'acctTypeCd',
    },
    {
      name: 'GOVERNMENT',
      key: 'govtCustomerInd',
    },
    {
      name: 'AFFILIATE',
      key: 'affiliateInd',
    },
    {
      name: 'RESTRICTED',
      key: 'restrictChargeInd',
    },
    {
      name: 'SALES TERRITORY',
      key: 'salesTerritoryCd',
    },
    {
      name: 'PARENT',
      key: 'topParentMadCd',
    },
    {
      name: 'MERGE TO',
      key: 'mergedToMadCd',
    },
    {
      name: 'Location',
      isHeader: true,
    },
    {
      name: 'NAME',
      key: 'name1',
    },
    {
      name: 'NAME2',
      key: 'nameExt',
    },
    {
      name: 'ADDRESS',
      key: 'address',
    },
    {
      name: 'CITY',
      key: 'cityName',
    },
    {
      name: 'STATE',
      key: 'stateCd',
    },
    {
      name: 'POSTAL CODE',
      key: 'zipCd',
    },
    {
      name: 'COUNTRY',
      key: 'countryCd',
    },
    {
      name: 'Financial',
      isHeader: true,
    },
    {
      name: 'BANKRUPT',
      key: 'bankruptInd',
    },
    {
      name: 'CREDIT STATUS',
      key: 'creditStatusCd',
    },
    {
      name: 'CREDIT LIMIT',
      key: 'authorizationLimitAmount',
    },
    {
      name: 'HRC ID',
      key: 'authorizationNbr',
    },
    {
      name: 'PAYMENT TERMS',
      key: 'paymentTermCd',
    },
    {
      name: 'DUNS',
      key: 'dbCustomerNbr',
    },
    {
      name: 'NAICS CODE',
      key: 'standardIndlCd',
    },
    {
      name: 'TAX ID',
      key: 'taxId',
    },
    {
      name: 'Service Recipient',
      isHeader: true,
    },
    {
      name: 'SERVICE RECIPIENT NBR',
      key: 'serviceRecipientNbr',
    },
    {
      name: 'TYPE',
      key: 'typeOfServiceInd',
    },
    {
      name: 'NAME',
      key: 'serviceRecipientName',
    },
  ];

  invoiceFields: any[] = [
    {
      name: 'Prepaid',
      isHeader: true,
    },
    {
      name: 'ORIGINAL',
      billCd: 'Prepaid',
      invoiceCd: 'Original',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Prepaid',
      invoiceCd: 'Corrected',
      name: 'CORRECTED',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Prepaid',
      invoiceCd: 'BalDue',
      name: 'BALANCE DUE',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Prepaid',
      invoiceCd: 'TrueDebtor',
      name: 'TRUE DEBTOR',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      name: 'Collect',
      isHeader: true,
    },
    {
      billCd: 'Collect',
      invoiceCd: 'Original',
      name: 'ORIGINAL',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Collect',
      invoiceCd: 'Corrected',
      name: 'CORRECTED',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Collect',
      invoiceCd: 'BalDue',
      name: 'BALANCE DUE',
      key: 'bil2CustomerLocationFuncId',
    },
    {
      billCd: 'Collect',
      invoiceCd: 'TrueDebtor',
      name: 'TRUE DEBTOR',
      key: 'bil2CustomerLocationFuncId',
    },
  ];

  constructor(private store: Store<AppState>, private router: Router, private customerApi: CustomerApiService) {}

  agInit(params: any): void {
    this.type = params.node.data.customerDetailCd;
    this.isAlias = this.type === 'Alias';
    this.isAccount = this.type === 'Account';
    this.isInvoices = this.type === 'InvoiceInstructions';
    this.isContact = this.type === 'Contact';
    this.action = this.actionCodeFormat(params.node.data.actionCd);
    this.displayedColumns =
      this.action !== 'Update' && !this.isAccount && !this.isInvoices
        ? ['details']
        : this.isAccount || this.isInvoices
        ? ['name', 'beforeChange', 'afterChange']
        : ['beforeChange', 'afterChange'];
    params.data.actionCd = this.mapActionCd(params.data.actionCd);
    this.store.pipe(select(getLocationData), take(1)).subscribe((res) => {
      this.locationId = res.customerLocationFuncId;
      if (this.locationId) {
        this.customerApi
          .getCustomerHistoryDetails({ custLocationFuncId: +this.locationId }, params.data)
          .subscribe((resp) => {
            if (Object.keys(resp).length) {
              let data;
              switch (this.type) {
                case 'Alias':
                  switch (params.node.data.actionCd) {
                    case 'I':
                      data = [
                        {
                          text: resp.customerLocationFuncAliasHistAfter
                            ? `${resp.customerLocationFuncAliasHistAfter?.name1}, ${
                                resp.customerLocationFuncAliasHistAfter?.name2
                                  ? resp.customerLocationFuncAliasHistAfter?.name2 + ', '
                                  : ''
                              }${resp.customerLocationFuncAliasHistAfter?.address}, ${
                                resp.customerLocationFuncAliasHistAfter?.cityName
                              }, ${resp.customerLocationFuncAliasHistAfter?.stateCd}, ${
                                resp.customerLocationFuncAliasHistAfter?.zip4Cd
                                  ? resp.customerLocationFuncAliasHistAfter?.zipCd +
                                    '-' +
                                    resp.customerLocationFuncAliasHistAfter?.zip4Cd
                                  : resp.customerLocationFuncAliasHistAfter?.zipCd
                              }, ${resp.customerLocationFuncAliasHistAfter?.countryCd}`
                            : '',
                        },
                      ];
                      break;
                    case 'D':
                      data = [
                        {
                          text: resp.customerLocationFuncAliasHistBefore
                            ? `${resp.customerLocationFuncAliasHistBefore?.name1}, ${
                                resp.customerLocationFuncAliasHistBefore?.name2
                                  ? resp.customerLocationFuncAliasHistBefore?.name2 + ', '
                                  : ''
                              }${resp.customerLocationFuncAliasHistBefore?.address}, ${
                                resp.customerLocationFuncAliasHistBefore?.cityName
                              }, ${resp.customerLocationFuncAliasHistBefore?.stateCd}, ${
                                resp.customerLocationFuncAliasHistBefore?.zip4Cd
                                  ? resp.customerLocationFuncAliasHistBefore?.zipCd +
                                    '-' +
                                    resp.customerLocationFuncAliasHistBefore?.zip4Cd
                                  : resp.customerLocationFuncAliasHistBefore?.zipCd
                              }, ${resp.customerLocationFuncAliasHistBefore?.countryCd}`
                            : '',
                        },
                      ];
                      break;
                    case 'U':
                      data = this.mapValues(
                        resp.customerLocationFuncAliasHistBefore,
                        resp.customerLocationFuncAliasHistAfter,
                        'Alias'
                      );
                      break;
                  }
                  this.requesterRemarks =
                    resp.customerLocationFuncAliasHistAfter?.lastUpdateRemarks ||
                    resp.customerLocationFuncAliasHistBefore?.lastUpdateRemarks;
                  break;
                case 'Contact':
                  switch (params.node.data.actionCd) {
                    case 'I':
                      data = [{ data: resp.customerContactHistAfter }];
                      this.requesterRemarks =
                        resp.customerContactHistAfter?.contactCustomerRelationshipHist?.lastUpdateRemarks;
                      break;
                    case 'D':
                      data = [{ data: resp.customerContactHistBefore }];
                      this.requesterRemarks =
                        resp.customerContactHistBefore?.contactCustomerRelationshipHist?.lastUpdateRemarks;
                      break;
                    case 'U':
                      data = this.mapValues(
                        resp.customerContactHistBefore?.contactPersonHist,
                        resp.customerContactHistAfter,
                        'Contact'
                      );
                      this.requesterRemarks =
                        resp.customerContactHistAfter?.contactCustomerRelationshipHist?.lastUpdateRemarks;
                      break;
                  }
                  break;
                case 'Account':
                  switch (params.node.data.actionCd) {
                    case 'I':
                      data = this.mapValues(null, resp, 'Account', 'I');
                      break;
                    case 'U':
                      data = this.mapValues(resp, resp, 'Account', 'U');
                      break;
                  }
                  this.requesterRemarks = resp.customerLocationFunctnHistAfter?.lastUpdateRemarks;
                  break;
                case 'InvoiceInstructions':
                  break;
              }
              this.dataSource.data = data;
            }
          });
      }
    });

    this.isChangeRequest = this.isChangeRequestFn();
    this.params = params;

    this.store
      .select(getChangeRequestId)
      .pipe(takeUntil(this.destroy))
      .subscribe((changeRequestId) => {
        this.changeRequestId = changeRequestId;
      });

    this.store.select(getRequestedChangeCurrentLine).subscribe((res) => {
      const currLoc = res?.requestCustomerLocationFunction[0];
      this.requestChangesCurrentLocation = res;
      this.locationSeqNbr = currLoc?.requestFuncSequenceNbr;
    });

    this.store.select(getChangeRequest).subscribe((res) => {
      this.customerRequest = res.changeRequest;
    });

    this.gridActions = params.customActions || null;
  }

  mapActionCd(actionCd): string {
    return ActionCodeMap[actionCd];
  }

  mapValues(before, after, type, action?): any[] {
    const updateData = [];
    switch (type) {
      case 'Alias':
      case 'Contact':
        if (after) {
          Object.keys(after).forEach((k) => {
            const afterValue = after[k];
            if (afterValue !== undefined && this.isValidKey(k, type)) {
              updateData.push({ key: k, before: before[k], after: after[k], updated: before[k] !== after[k] });
            }
          });
        }
        return updateData;
      case 'Account':
        if (after.customerLocationFunctnHistAfter || before.customerLocationFunctnHistBefore) {
          this.accountFields.forEach((field) => {
            const afterValue = after.customerLocationFunctnHistAfter[field.key];

            field['before'] =
              action === 'I'
                ? afterValue
                : before?.customerLocationFunctnHistBefore
                ? before.customerLocationFunctnHistBefore[field.key]
                : '';
            field['after'] = action !== 'I' ? afterValue : '';

            switch (field.key) {
              case 'creditStatusCd':
                field['before'] = CreditStatusHistory[field['before']];
                if (action === 'U') {
                  field['after'] = CreditStatusHistory[field['after']];
                }
                break;
              case 'acctTypeCd':
                field['before'] = AccountType[field['before']];
                if (action === 'U') {
                  field['after'] = AccountType[field['after']];
                }
                break;
              case 'nationalInd':
                field['before'] = field['before'];
                if (action === 'U') {
                  field['after'] = field['after'];
                }
                break;
              case 'restrictChargeInd':
              case 'affiliateInd':
              case 'govtCustomerInd':
              case 'bankruptInd':
                field['before'] = YesNo[field['before']?.toString()?.toUpperCase()];
                if (action === 'U') {
                  field['after'] = YesNo[field['after']?.toString()?.toUpperCase()];
                }
                break;
              case 'name1':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.party1?.partyName
                    : before
                    ? before.customerLocationBefore?.party1?.partyName
                    : '';
                field['after'] = action !== 'I' ? after.customerLocationAfter?.party1?.partyName : '';
                break;
              case 'nameExt':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.nameExt
                    : before
                    ? before.customerLocationBefore?.nameExt
                    : '';
                field['after'] =
                  action !== 'I'
                    ? after.customerLocationAfter?.nameExt || after.customerLocationAfter?.party2?.partyName
                    : '';
                break;
              case 'address':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.customerAddress?.address
                    : before
                    ? before.customerLocationBefore?.customerAddress?.address
                    : '';
                field['after'] = action !== 'I' ? after.customerLocationAfter?.customerAddress?.address : '';
                break;
              case 'cityName':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.customerAddress?.cityName
                    : before
                    ? before.customerLocationBefore?.customerAddress?.cityName
                    : '';
                field['after'] = action !== 'I' ? after.customerLocationAfter?.customerAddress?.cityName : '';
                break;
              case 'stateCd':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.customerAddress?.stateCd
                    : before
                    ? before.customerLocationBefore?.customerAddress?.stateCd
                    : '';
                field['after'] = action !== 'I' ? after.customerLocationAfter?.customerAddress?.stateCd : '';
                break;
              case 'zipCd':
                field['before'] =
                  action === 'I'
                    ? this.formatZipCd(after.customerLocationAfter?.customerAddress)
                    : before
                    ? this.formatZipCd(before.customerLocationBefore?.customerAddress)
                    : '';
                field['after'] = action !== 'I' ? this.formatZipCd(after.customerLocationAfter?.customerAddress) : '';
                break;
              case 'countryCd':
                field['before'] =
                  action === 'I'
                    ? after.customerLocationAfter?.customerAddress?.countryCd
                    : before
                    ? before.customerLocationBefore?.customerAddress?.countryCd
                    : '';
                field['after'] = action !== 'I' ? after.customerLocationAfter?.customerAddress?.countryCd : '';
                break;
              case 'salesTerritoryCd':
                field['before'] =
                  action === 'I'
                    ? after.salesAssignmentHistAfter?.salesTerritoryCd
                    : before
                    ? before.salesAssignmentHistBefore?.salesTerritoryCd
                    : '';
                field['after'] = action !== 'I' ? after.salesAssignmentHistAfter?.salesTerritoryCd : '';
                break;
              case 'serviceRecipientName':
                field['before'] = after.serviceRecipientHistBefore?.serviceRecipientName;
                field['after'] = after.serviceRecipientHistAfter?.serviceRecipientName;
                break;
              case 'typeOfServiceInd':
                field['after'] = after.serviceRecipientHistAfter?.typeOfServiceInd;
                field['before'] = after.serviceRecipientHistBefore?.typeOfServiceInd;
                break;
            }
            field['updated'] = field['before'] !== field['after'];
          });
        }

        const functionCd = after.customerLocationFunctnHistAfter?.functionCd;
        return this.accountFields.filter((item) =>
          functionCd === CustomerFunctionCd.CORPORATE
            ? item.key !== 'restrictChargeInd' &&
              item.name !== 'Service Recipient' &&
              item.key !== 'serviceRecipientNbr' &&
              item.key !== 'typeOfServiceInd' &&
              item.key !== 'serviceRecipientName' &&
              item.key !== 'mergedToMadCd'
            : functionCd === CustomerFunctionCd.BILL_TO
            ? item.key !== 'govtCustomerInd' &&
              item.key !== 'affiliateInd' &&
              item.key !== 'mergedToMadCd' &&
              item.key !== 'salesTerritoryCd'
            : item.key !== 'restrictChargeInd' &&
              item.key !== 'govtCustomerInd' &&
              item.key !== 'affiliateInd' &&
              item.name !== 'Service Recipient' &&
              item.key !== 'serviceRecipientNbr' &&
              item.key !== 'typeOfServiceInd' &&
              item.key !== 'serviceRecipientName'
        );
      case 'InvoiceInstructions':
        after.forEach((element, index) => {
          this.invoiceFields.forEach((field) => {
            if (!field.isHeader && element.billCd === field.billCd && element.invoiceCd === field.invoiceCd) {
              field['before'] = element.bil2MadCd || 'Same as location';
              if (after && action !== 'I') {
                field['after'] = after[index].bil2MadCd || 'Same as location';
              }
            }
          });
        });
        return this.invoiceFields.filter((field) => field.isHeader || field.before);
    }
  }

  private formatZipCd(address): string {
    return address ? (address.zip4Cd ? address?.zipCd + '-' + address?.zip4Cd : address?.zipCd) : '';
  }

  isValidKey(key, type): boolean {
    switch (type) {
      case 'Alias':
        if (this.aliasValidKeys.includes(key)) {
          return true;
        }
        break;
      case 'Contact':
        if (this.contactValidKeys.includes(key)) {
          return true;
        }
        break;
      case 'Account':
        return true;
    }
    return false;
  }

  formatPhone(data): string {
    return data ? 'Phone: ' + data?.businessPhoneAreaCd + '-' + data?.businessPhoneNbr : '';
  }

  formatFax(data): string {
    return data?.faxNbr ? 'Fax: ' + data?.faxAreaCd + '-' + data?.faxNbr : '';
  }

  refresh(): boolean {
    return false;
  }

  actionCodeFormat(value): string {
    return ActionCodeDetails[value];
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
