<div class="ncis-CustomerBusinessName">
  <div class="ncis-LocationDetails-card ncis-LocationDetails-card-table">
    <div class="ncis-bna-requester-notes" *ngIf="hasChanges">
      <p class="ncis-bna-requester-notes-title">Requester Notes</p>
      <div class="ncis-bna-requester-notes-content">
        <span>NOTES</span>
        <span>{{ notes }}</span>
      </div>
    </div>
    <div class="ncis-bna-location-data-container" *ngIf="locationData">
      <p class="ncis-bna-location-data-title">Location</p>
      <p class="ncis-bna-location-data-name1">{{ locationData?.customerLocation.party1.partyName }}</p>
      <p
        class="ncis-bna-location-data-name2"
        *ngIf="locationData.customerLocation?.party2 || locationData?.customerLocation.nameExt"
      >
        {{ locationData?.customerLocation.party2?.partyName || locationData?.customerLocation.nameExt }}
      </p>
      <p class="ncis-bna-location-data-address">
        {{ locationData?.customerLocation.customerAddress.address }}
      </p>
      <p class="ncis-bna-location-data-region-data">
        {{ locationData?.customerLocation.customerAddress.cityName }},
        {{ locationData?.customerLocation.customerAddress.stateCd }}
        {{ locationData?.customerLocation.customerAddress.zipCd }}
        {{
          locationData?.customerLocation.customerAddress.zip4Cd
            ? '-' + locationData?.customerLocation.customerAddress.zip4Cd
            : ''
        }},
        {{ locationData?.customerLocation.customerAddress.countryCd }}
      </p>
    </div>
    <div class="ncis-bna-xpo-board-container" [ngClass]="{ 'no-rows': noData }">
      <xpo-board
        [dataSource]="dataSource"
        [viewDataStore]="viewDataStore"
        [viewTemplates]="viewTemplates"
        [boardOptions]="boardOptions"
      >
        <xpo-board-actions *ngIf="!isChangeRequest" filterActions>
          <button
            id="alias-add-btn"
            (click)="addBusinessName()"
            [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
            mat-icon-button
            style="font-size: 14px;"
          >
            <mat-icon style="font-size: 16px; margin-right: 8px;" inline="true">add_circle</mat-icon> Add
          </button>
        </xpo-board-actions>

        <xpo-ag-grid-board
          rowModelType="client-side"
          rowSelection="false"
          [enableSorting]="true"
          sizeColumnsToFit="true"
          [customGridOptions]="customGridOptions"
          (gridBoardReady)="gridBoardReady($event)"
        >
        </xpo-ag-grid-board>
        <xpo-board-no-results-display>
          <xpo-empty-state>
            <xpo-empty-state-icon>
              <mat-icon>insert_drive_file</mat-icon>
            </xpo-empty-state-icon>
            <xpo-empty-state-description
              >There are no business name aliases for this location.
            </xpo-empty-state-description>
          </xpo-empty-state>
        </xpo-board-no-results-display>
      </xpo-board>
      <app-custom-pagination [dataSource]="dataSource" [gridApi]="gridApi"></app-custom-pagination>
    </div>
  </div>
</div>
