<div class="ncis-account-details-related-locations">
  <span class="ncis-related-locations-title">Related Locations</span>
  <ng-container *ngIf="relatedLocations.length > 0; else noRelatedLocations">
    <div *ngFor="let location of relatedLocations" class="ncis-related-location">
      <span
        >{{ location.type }}: <a [routerLink]="'/location/' + location.link" target="_blank"> {{ location.id }}</a
        >{{ location.text }}</span
      >
    </div>
  </ng-container>
  <ng-template #noRelatedLocations>
    <div class="ncis-related-location">
      <span>No related locations.</span>
    </div>
  </ng-template>
</div>
