<div class="export-dialog-page-content-container" tabindex="1">
  <div class="results-container">
    <xpo-card class="ncis-Customer-card ncis-Card-marginTop">
      <div class="ncis-export-dialog-board-container">
        <xpo-board
          [dataSource]="dataSource"
          [viewDataStore]="viewDataStore"
          [viewTemplates]="viewTemplates"
          [boardOptions]="boardOptions"
          (boardReady)="gridBoardReady($event)"
        >
          <xpo-board-actions filterActions>
            <xpo-button-group>
              <button (click)="exportToCsv()" mat-button style="font-size: 14px;">
                <mat-icon style="font-size: 16px; margin-right: 8px;" inline="true">download</mat-icon>
                <span>Export to CSV</span>
              </button>
              <button (click)="exportToXlsx()" mat-button style="font-size: 14px;">
                <mat-icon style="font-size: 16px; margin-right: 8px;" inline="true">download</mat-icon>
                <span>Export to XLSX</span>
              </button>
            </xpo-button-group>
          </xpo-board-actions>
          <xpo-ag-grid-board
            rowModelType="client-side"
            [customGridOptions]="customGridOptions"
            (gridBoardReady)="gridBoardReady($event)"
          >
          </xpo-ag-grid-board>
        </xpo-board>
      </div>
    </xpo-card>
  </div>
</div>
