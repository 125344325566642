import { Action } from '@ngrx/store';

export enum ServiceRecipientActionTypes {
  SetIsProfile = '[ServiceRecipient] SetIsProfile',
  GetIsProfile = '[ServiceRecipient] GetIsProfile',
  GetCurrentServiceRecipient = '[ServiceRecipient] GetCurrentServiceRecipient',
  SetCurrentServiceRecipient = '[ServiceRecipient] SetCurrentServiceRecipient',
}

export class SetIsProfile implements Action {
  readonly type = ServiceRecipientActionTypes.SetIsProfile;
  constructor(public payload) {}
}

export class GetIsProfile implements Action {
  readonly type = ServiceRecipientActionTypes.GetIsProfile;
  constructor(public payload) {}
}

export class GetCurrentServiceRecipient implements Action {
  readonly type = ServiceRecipientActionTypes.GetCurrentServiceRecipient;
  constructor(public payload) {}
}

export class SetCurrentServiceRecipient implements Action {
  readonly type = ServiceRecipientActionTypes.SetCurrentServiceRecipient;
  constructor(public payload) {}
}

export type ServiceRecipientActions = SetIsProfile | GetIsProfile | GetCurrentServiceRecipient | SetCurrentServiceRecipient;
