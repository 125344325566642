export enum CustomerFunctionCdLabel {
  Corporate = 'Corporate',
  'Bill-To' = 'Bill-To',
  PickupOrDelivery = 'Pickup & Delivery',
}

export enum CustomerFunctionCdValue {
  Corporate = 'Corporate',
  'Bill-To' = 'Bill-To',
  'Pickup & Delivery' = 'PickupOrDelivery',
}
