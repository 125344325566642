export enum ServiceRecipientSortFields {
  serviceRecipientNbr = 'svcRcpntNbr',
  address = 'addrTxt',
  city = 'cityTxt',
  contactAreaCd = 'cntctAreaCd',
  contactFirstName = 'cntctFirstNmTxt',
  contactLastName = 'cntctLastNmTxt',
  contactPhone = 'cntctPhoneTxt',
  email = 'emailTxt',
  state = 'stateTxt',
  serviceRecipientName = 'svcRcpntNm',
  typeOfServiceInd = 'typeOfSvcInd',
  zipCd = 'zip6Txt',
}

export enum SortOrder {
  asc = 'ASCENDING',
  desc = 'DESCENDING',
}
