export enum CreditStatus {
  Credit = 'Credit',
  NoCredit = 'No Credit',
  Voluntary = 'Voluntary',
  Cash = 'Cash',
}

export enum CreditStatusHistory {
  CREDIT = 'Credit',
  NO_CREDT = 'No Credit',
  VOLUNTARY = 'Voluntary',
  $ = 'Cash',
  B = 'Bankrupt',
}

export enum CreditStatusValue {
  Credit = 'Credit',
  NoCredit = 'NoCredit',
  Voluntary = 'Voluntary',
  Cash = 'Cash',
}
