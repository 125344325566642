import { Component, ElementRef, HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phone-number-editor',
  templateUrl: './phone-number-editor.component.html',
  styleUrls: ['./phone-number-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'phone-number-editor-host',
  },
})
export class PhoneNumberEditorComponent implements ICellRendererAngularComp, OnDestroy {
  isEditMode: boolean;
  parsedValue: string;
  isEditable: boolean;
  phoneNbrControl: UntypedFormControl;
  showCountryCd: boolean;
  currentErrorMessage;

  private params;
  private destroy$ = new Subject();

  constructor(private errorStateManager: ErrorStateManagerService, private ref: ElementRef) {}

  refresh(): boolean {
    return false;
  }

  agInit(params): void {
    this.params = params;
    this.parsedValue = this.getParsedValue(params.value);
    this.isEditable = params.isEditable;
    this.showCountryCd = params.key === 'phone';
    const validators = this.showCountryCd
      ? [ValidatorHelper.phoneNbrValidator('Invalid Phone Number'), ValidatorHelper.requiredPhoneNbr('Phone number is a mandatory field.')]
      : [ValidatorHelper.phoneNbrValidator('Invalid Phone Number')];

    this.phoneNbrControl = new UntypedFormControl(params.value, validators);

    if (!this.phoneNbrControl.valid && this.isEditable) {
      this.currentErrorMessage = this.errorStateManager.getErrorMessage(params.node.rowIndex + '', this.params.key);
    }

    this.phoneNbrControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (!this.phoneNbrControl.valid) {
        this.currentErrorMessage = Object.keys(this.phoneNbrControl.errors).reduce(
          (acc, curr) => `${acc}${this.phoneNbrControl.errors[curr]}. `,
          ''
        );
      } else {
        this.currentErrorMessage = '';
      }
    });
  }

  toggleMode(): void {
    if (this.isEditable) {
      this.isEditMode = !this.isEditMode;
    }
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent): void {
    if (this.isEditable) {
      // stop event bubbling to prevent quitting edit mode
      e.stopImmediatePropagation();
      if (!this.isEditMode) {
        this.toggleMode();
      }
    }
  }

  @HostListener('keypress', ['$event'])
  onKeypress(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.toggleMode();
      this.updateValue();
    }
  }

  onBlur(e): void {
    const parent: HTMLElement = this.ref.nativeElement;
    if (parent.contains(e) || e?.className?.includes('mat-option')) {
      return;
    }
    this.toggleMode();
    this.updateValue();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    // prevent arrow keys causing blur
    if (e.key.includes('Arrow')) {
      e.stopImmediatePropagation();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateValue(): void {
    if (!this.phoneNbrControl.valid) {
      this.errorStateManager.setErrorState(this.params.node.rowIndex + '', { id: this.params.key, message: this.currentErrorMessage });
    } else {
      this.errorStateManager.cleanErrorState(this.params.node.rowIndex + '', this.params.key);
    }

    this.parsedValue = this.getParsedValue(this.phoneNbrControl.value);

    this.params.updateValue(this.phoneNbrControl.value, this.params.node);
  }

  getParsedValue(value): string {
    return value
      ? `${value.countryCd ? '+' + value.countryCd : ''}${' ' + '(' + value.areaCd + ')'} ${value.number?.slice(
          0,
          3
        )}-${value.number?.slice(3)} ${value.extension ? 'ext. ' + value.extension : ''}`
      : '';
  }
}
