import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { ContactCustomerRelationship, ContactNote, ContactPerson, UpdateCustomerRqst } from '@xpo-ltl-2.0/sdk-customer';
import { ActionCd, CustomerContactRoleCd, CustomerDetailCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { ContactRoleCdLabel } from 'src/app/shared/enums/contact-role-code-label.enum';
import { StatesService } from 'src/app/shared/services/states/states.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import { getLocationData } from 'src/app/store/location/location.selectors';

@Component({
  selector: 'app-new-contact-dialog',
  templateUrl: './new-contact-dialog.component.html',
  styleUrls: ['./new-contact-dialog.component.scss'],
})
export class NewContactDialogComponent implements OnInit, OnDestroy {
  newContactForm: UntypedFormGroup;
  title: string;
  contactTypes;
  countryList = ['US', 'CA', 'MX'];
  states$;
  countrySubscription$: BehaviorSubject<string>;
  destroy$ = new Subject<void>();
  successButtonTitle: string;
  closeButtonTitle: string;
  isChangeRequest: boolean;

  constructor(
    private dialog: MatDialogRef<NewContactDialogComponent>,
    private statesService: StatesService,
    private store: Store<AppState>,
    private locationDetailsService: LocationDetailService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.isChangeRequest = this.data.isChangeRequest;
    const phoneData = {
      countryCd: this.data.businessPhoneCountryCd,
      areaCd: this.data.businessPhoneAreaCd,
      number: this.data.businessPhoneNbr,
    };
    const faxData = {
      areaCd: this.data.faxAreaCd,
      number: this.data.faxNbr,
    };
    this.newContactForm = new UntypedFormGroup({
      contactRoleCd: new UntypedFormControl({ value: this.data.contactRoleCd || '', disabled: this.data.action !== 'ADD' }, [
        ValidatorHelper.required('Contact Type is required'),
      ]),
      title: new UntypedFormControl(this.data.title || ''),
      firstName: new UntypedFormControl(this.data.firstName || '', [
        ValidatorHelper.required('First Name is required.'),
        ValidatorHelper.maxLength(10, 'First name can be up to 10 char, due to legacy CIS limit.'),
      ]),
      lastName: new UntypedFormControl(this.data.lastName || '', [
        ValidatorHelper.required('Last Name is required.'),
        ValidatorHelper.maxLength(20, 'Last name can be up to 20 char, due to legacy CIS limit'),
      ]),
      cityName: new UntypedFormControl(this.data.cityName || ''),
      stateCd: new UntypedFormControl(this.data.stateCd || ''),
      zipCd: new UntypedFormControl(this.data.zipCd || ''),
      countryCd: new UntypedFormControl(this.data.countryCd || 'US'),
      phoneNbr: new UntypedFormControl(phoneData, [
        ValidatorHelper.requiredPhoneNbr('Phone Number is required.'),
        ValidatorHelper.phoneNbrValidator('Phone Number is not valid.'),
      ]),
      faxNbr: new UntypedFormControl(faxData),
      emailId: new UntypedFormControl(this.data.emailId || '', [
        ValidatorHelper.emailFormatValidator(),
        ValidatorHelper.maxLength(70, 'Email can be up to 70 char, due to legacy CIS limit'),
      ]),
      contactNote: new UntypedFormControl(this.data.contactNote?.note),
      remarks: new UntypedFormControl('', [ValidatorHelper.required('Remarks is required.')]),
      address: new UntypedFormControl(this.data.address || ''),
      businessPhoneExt: new UntypedFormControl(this.data.businessPhoneExt || ''),
      mailingName: new UntypedFormControl(this.data.mailingName || ''),
      lastUpdateRemarks: new UntypedFormControl({ value: this.data.lastUpdateRemarks, disabled: true }),
    });

    if (this.data.isChangeRequest) {
      this.newContactForm.disable();
    }

    this.countrySubscription$ = new BehaviorSubject<string>(this.newContactForm.controls['countryCd'].value);
    this.title = this.data.action === 'ADD' ? 'Add Contact Person' : 'Edit Contact Person';
    this.successButtonTitle = this.data.action === 'ADD' ? 'ADD' : 'SAVE';
    this.closeButtonTitle = this.isChangeRequest ? 'CLOSE' : 'CANCEL';
    this.contactTypes = Object.keys(CustomerContactRoleCd).map((k) => ({
      label: ContactRoleCdLabel[CustomerContactRoleCd[k]],
      value: CustomerContactRoleCd[k],
    }));

    this.newContactForm.markAllAsTouched();

    this.states$ = this.countrySubscription$.pipe(
      switchMap((countryCd) => {
        return this.statesService.getStates(countryCd);
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.countrySubscription$.complete();
  }

  onSave(): void {
    this.store
      .select(getLocationData)
      .pipe(take(1))
      .subscribe((locData) => {
        const data = this.newContactForm.getRawValue();
        const contactData = this.data;
        const requestPayload = new UpdateCustomerRqst();
        requestPayload.customerDetailCd = CustomerDetailCd.CONTACT;
        const contactPerson = new ContactPerson();
        const contactRelationship = new ContactCustomerRelationship();
        const contactNote = new ContactNote();
        const [zipCd, zip4Cd] = data.zipCd !== undefined ? data.zipCd?.split('-') : contactData.zipCd || '';

        contactPerson.businessPhoneAreaCd = data.phoneNbr.areaCd;
        contactPerson.businessPhoneCountryCd = data.phoneNbr.countryCd;
        contactPerson.businessPhoneExt = data.businessPhoneExt;
        contactPerson.businessPhoneNbr = data.phoneNbr.number;
        contactPerson.firstName = data.firstName;
        contactPerson.lastName = data.lastName;
        contactPerson.faxNbr = data.faxNbr.number;
        contactPerson.faxAreaCd = data.faxNbr.areaCd;
        contactPerson.contactPersonId = this.data.contactPersonId;
        contactPerson.emailId = data.emailId;
        // if values are == undefined means they are not being changed
        // therefore just keep existing value
        contactPerson.title = data.title !== undefined ? data.title : contactData.title;
        contactPerson.address = data.address !== undefined ? data.address : contactData.address;
        contactPerson.cityName = data.cityName !== undefined ? data.cityName : contactData.cityName;
        contactPerson.stateCd = data.stateCd !== undefined ? data.stateCd : contactData.stateCd;
        contactPerson.countryCd = data.countryCd !== undefined ? data.countryCd : contactData.countryCd;
        contactPerson.zipCd = zipCd;
        contactPerson.zip4Cd = zip4Cd;
        contactRelationship.contactRoleCd = data.contactRoleCd;
        contactRelationship.customerLocationFuncId = locData.customerLocationFuncId;
        contactRelationship.inboundOutbCd = this.data.inboundOutbCd || 'BOTH';
        contactNote.note = data.contactNote || contactData.contactNote?.note;
        contactNote.contactPersonId = contactData.contactPersonId;
        contactNote.sequenceNbr = contactData.contactNote?.sequenceNbr + 1 || null;
        if (contactData.contactNote?.sequenceNbr || data.contactNote) {
          requestPayload.contactNote = contactNote;
        }
        requestPayload.contactPerson = contactPerson;
        requestPayload.contacCustomerRel = contactRelationship;
        requestPayload.actionCd = ActionCd[this.data.actionCd] || ActionCd[this.data.action];
        requestPayload.ignoreAddressValidation = true;
        contactPerson.lastUpdateRemarks = data.remarks || data.comments;
        contactRelationship.lastUpdateRemarks =
          this.data.action === 'DELETE' ? data.comments : contactData.lastUpdateRemarks || data.remarks;

        this.locationDetailsService.updateCustomer(
          requestPayload,
          locData.customerLocationFuncId,
          () => {
            this.closeForm({ confirmed: true });
          },
          false,
          false,
          false,
          true
        );
      });
  }

  onCancel(): void {
    this.closeForm({ confirmed: false });
  }

  countryChange(e: MatSelectChange): void {
    this.countrySubscription$.next(e.value);
  }

  getErrorMessage(controlName: string): string {
    return this.newContactForm &&
      this.newContactForm.controls[controlName].invalid &&
      this.newContactForm.controls[controlName].touched
      ? Object.keys(this.newContactForm.controls[controlName].errors).reduce((acc, curr) => {
          acc += this.newContactForm.controls[controlName].errors[curr] + '\n';
          return acc;
        }, '')
      : '';
  }

  private closeForm(result): void {
    this.dialog.close(result);
  }
}
