<form class="ncis-pricing-details-form" [formGroup]="pricingDetailsForm" *ngIf="pricingDetailsForm">
  <div class="ncis-pricing-details-form-title">PRICING DETAILS</div>
  <div class="ncis-pricing-details-form-full-name">
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Agreement ID</span>
      <mat-form-field>
        <input formControlName="agreemmentId" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>OBI</span>
      <mat-form-field>
        <input formControlName="obi" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-pricing-details-form-address">
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Sales Owner</span>
      <mat-form-field>
        <input formControlName="salesOwner" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Primary Account</span>
      <mat-form-field>
        <input formControlName="primaryAccount" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Status</span>
      <mat-form-field>
        <input formControlName="status" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Effective Date</span>
      <mat-form-field>
        <input formControlName="effectiveDate" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-pricing-details-form-field-wrapper">
      <span>Expiration Date</span>
      <mat-form-field>
        <input formControlName="expirationDate" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div>
    <button mat-stroked-button (click)="close()">CLOSE</button>
  </div>
</form>
