import { Component } from '@angular/core';
import { CustomerLineStatusCdLabel } from 'src/app/shared/enums/customer-line-status-cd-label.enum';
import { CustomerLineStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-line-status-cell-renderer',
  templateUrl: './line-status-cell-renderer.component.html',
  styleUrls: ['./line-status-cell-renderer.component.scss'],
})
export class LineStatusCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  getClass(status: CustomerLineStatusCd): string {
    let className = '';

    switch (true) {
      case status === CustomerLineStatusCd.HELD:
        className = 'yellow';
        break;
      case status === CustomerLineStatusCd.REJECTED:
        className = 'red';
        break;
      case status === CustomerLineStatusCd.APPROVED:
      case status === CustomerLineStatusCd.SALES_APPROVED:
        className = 'green';
        break;
      default:
        break;
    }

    return className;
  }

  getStatusName(statusCd): string {
    if (statusCd) {
      return CustomerLineStatusCdLabel[statusCd];
    } else {
      return '';
    }
  }
}
