import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CustomerRequest } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerFunctionCd, CustomerLineStatusCd } from '@xpo-ltl/sdk-common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { AppState } from 'src/app/store';
import { getRequestedChangeCurrentLine } from 'src/app/store/change-request/change-request.selectors';
import { UpdateChangesRequestCount } from 'src/app/store/details/details.actions';
import { getAccountDetails } from 'src/app/store/details/details.selectors';
import { SetShowChangesOnly } from 'src/app/store/location/location.action';
import { getLocationData } from 'src/app/store/location/location.selectors';
import { getShowChangesOnly } from 'src/app/store/location/location.selectors';

@Component({
  selector: 'app-customer-tabs',
  templateUrl: './customer-tabs.component.html',
  styleUrls: ['./customer-tabs.component.scss'],
})
export class CustomerTabsComponent implements OnInit, OnChanges {
  @Input() isChangeRequest: boolean;
  @Input() stickyTabs: boolean;
  @Input() showCheckbox: boolean;
  @Input() isParent: boolean;
  @Input() parentSequenceNbr: number;
  @Input() isNewLocation: boolean;
  @Input() newAccountInfoChangesCount: number;

  hasInvoiceInstructions: boolean;
  changeRequestDataCurrentLocation: CustomerRequest;
  statusIsApprovedOrReject: boolean;
  locationStatusIsHeld: boolean;
  businessNameChangesCount: number;
  contactsChangesCount: number;
  invoiceChangesCount: number;
  aliasHasHeldLine: boolean;
  contactHasHeldLine: boolean;
  checkedShowChangesOnly: boolean;
  invoiceHasHeldLine: boolean;
  locationHasChanges: boolean;
  accountInfoChangesCount: number = 0;

  private destroy$ = new Subject();

  constructor(
    private errorStateManager: ErrorStateManagerService,
    private store: Store<AppState>,
    private snackbar: XpoSnackBar
  ) {}

  ngOnInit(): void {
    this.setLocationHasChanges();
    if (this.isChangeRequest && this.locationHasChanges) {
      this.store.pipe(takeUntil(this.destroy$), select(getShowChangesOnly)).subscribe((resp) => {
        this.checkedShowChangesOnly = resp;
      });

      this.store
        .select(getRequestedChangeCurrentLine)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resp) => {
          if (resp) {
            this.changeRequestDataCurrentLocation = resp;
            const remarkChange = resp.requestCustomerLocationFunction[0]?.requestNote?.filter(
              (n) => n.noteForCd === 'FunctionRemark'
            );
            if (remarkChange.length) {
              this.accountInfoChangesCount += 1;
            }
            this.businessNameChangesCount = this.countPendingChanges(
              resp.requestCustomerLocationFunction[0]?.requestCustomerFunctionAlias
            );
            this.invoiceChangesCount = this.countPendingChanges(
              resp.requestCustomerLocationFunction[0]?.requestInvoicePreference
            );
            this.contactsChangesCount = this.countPendingChanges(resp.requestContactPerson);
            this.statusIsApprovedOrReject =
              resp.requestCustomerLocationFunction[0]?.statusCd === CustomerLineStatusCd.REJECTED ||
              resp.requestCustomerLocationFunction[0]?.statusCd === CustomerLineStatusCd.APPROVED;
            this.store.dispatch(
              new UpdateChangesRequestCount({
                changesRequestCount:
                  this.contactsChangesCount + this.businessNameChangesCount + this.accountInfoChangesCount,
              })
            );
            this.hasInvoiceInstructions =
              resp.requestCustomerLocationFunction[0]?.functionCd !== CustomerFunctionCd.CORPORATE;
            this.aliasHasHeldLine = this.hasHeldLine(
              resp.requestCustomerLocationFunction[0]?.requestCustomerFunctionAlias
            );
            this.locationStatusIsHeld = this.hasHeldLine(resp.requestCustomerLocationFunction);
            this.contactHasHeldLine = this.hasHeldLine(resp.requestContactPerson);
            this.invoiceHasHeldLine = this.hasHeldLine(
              resp.requestCustomerLocationFunction[0]?.requestInvoicePreference
            );
          }
        });

      this.changeValueShowChangesOnly({ checked: false });
    }
  }

  resetShowChangesOnly(ev: any, tab: number): void {
    if (tab === 0) {
      this.changeValueShowChangesOnly({ checked: false });
    } else {
      this.changeValueShowChangesOnly({ checked: true });
    }
    this.errorStateManager.clearAll();
  }

  changeValueShowChangesOnly({ checked }): void {
    this.checkedShowChangesOnly = checked;
    this.store.dispatch(new SetShowChangesOnly({ showChangesOnly: checked }));
  }

  ngOnChanges(): void {
    this.store.select(getLocationData).subscribe((loc) => {
      const urlPath = window.location.pathname.split('/');
      this.parentSequenceNbr = +urlPath[urlPath.findIndex((s) => s === 'parent') + 1];
      this.setLocationHasChanges();
      if (!this.isNewLocation) {
        this.hasInvoiceInstructions =
          loc?.customerLocation.customerLocationFunction[0].functionCd !== CustomerFunctionCd.CORPORATE;
      }
    });

    this.store.pipe(select(getAccountDetails)).subscribe((state) => {
      this.accountInfoChangesCount = state?.data?.filter((item) => item.requestedChange).length;
    });
  }

  private hasHeldLine(data = []): boolean {
    return data.some((i) => i.statusCd === CustomerLineStatusCd.HELD);
  }

  private countPendingChanges(data = []): number {
    return data.filter((i) => {
      return ![CustomerLineStatusCd.APPROVED, CustomerLineStatusCd.REJECTED].includes(i.statusCd);
    }).length;
  }

  private setLocationHasChanges(): void {
    this.locationHasChanges = this.isParent ? this.parentSequenceNbr > 0 : this.isChangeRequest;
  }
}
