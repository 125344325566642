import { Action } from '@ngrx/store';

export enum InvoiceActionTypes {
  GetInvoiceChangesLocationData = '[SearchRequest] GetInvoiceChangesLocationData',
  SetInvoiceChangesLocationData = '[SearchRequest] SetInvoiceChangesLocationData',
  GetInvoicesCurrentLocation = '[SearchRequest] GetInvoicesCurrentLocation',
  SetInvoicesCurrentLocation = '[SearchRequest] SetInvoicesCurrentLocation',
}

export class GetInvoiceChangesLocationData implements Action {
  readonly type = InvoiceActionTypes.GetInvoiceChangesLocationData;
  constructor() {}
}

export class SetInvoiceChangesLocationData implements Action {
  readonly type = InvoiceActionTypes.SetInvoiceChangesLocationData;
  constructor(public payload) {}
}

export class GetInvoicesCurrentLocation implements Action {
  readonly type = InvoiceActionTypes.GetInvoicesCurrentLocation;
  constructor() {}
}

export class SetInvoicesCurrentLocation implements Action {
  readonly type = InvoiceActionTypes.SetInvoicesCurrentLocation;
  constructor(public payload) {}
}

export type InvoiceAction =
  | GetInvoiceChangesLocationData
  | SetInvoiceChangesLocationData
  | GetInvoicesCurrentLocation
  | SetInvoicesCurrentLocation;
