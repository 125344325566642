import { Action } from '@ngrx/store';

export enum DetailsActionsTypes {
  SetCurrentCountry = '[Details] SetCurrentCountry',
  GetCurrentCountry = '[Details] GetCurrentCountry',
  SetEditMode = '[Details] SetEditMode',
  GetEditMode = '[Details] GetEditMode',
  SetProcessingBulkDelete = '[Details] SetProcessingBulkDelete',
  GetProcessingBulkDelete = '[Details] GetProcessingBulkDelete',
  SetChangesRequestCount = '[Details] SetChangesRequestCount',
  UpdateChangesRequestCount = '[Details] UpdateChangesRequestCount',
  GetChangesRequestCount = '[Details] GetChangesRequestCount',
}

export class SetCurrentCountry implements Action {
  readonly type = DetailsActionsTypes.SetCurrentCountry;
  constructor(public payload) { }
}

export class GetCurrentCountry implements Action {
  readonly type = DetailsActionsTypes.GetCurrentCountry;
  constructor(public payload) { }
}

export class SetEditMode implements Action {
  readonly type = DetailsActionsTypes.SetEditMode;
  constructor(public payload) { }
}

export class GetEditMode implements Action {
  readonly type = DetailsActionsTypes.GetEditMode;
  constructor(public payload) { }
}

export class SetProcessingBulkDelete implements Action {
  readonly type = DetailsActionsTypes.SetProcessingBulkDelete;
  constructor(public payload) { }
}

export class GetProcessingBulkDelete implements Action {
  readonly type = DetailsActionsTypes.GetProcessingBulkDelete;
  constructor(public payload) { }
}

export class UpdateChangesRequestCount implements Action {
  readonly type = DetailsActionsTypes.UpdateChangesRequestCount;
  constructor(public payload) { }
}

export class SetChangesRequestCount implements Action {
  readonly type = DetailsActionsTypes.SetChangesRequestCount;
  constructor(public payload) { }
}

export class GetChangesRequestCount implements Action {
  readonly type = DetailsActionsTypes.GetChangesRequestCount;
  constructor(public payload) { }
}

export type DetailsActions =
  | SetCurrentCountry
  | GetCurrentCountry
  | GetEditMode
  | SetEditMode
  | GetProcessingBulkDelete
  | SetProcessingBulkDelete
  | UpdateChangesRequestCount
  | GetChangesRequestCount;
