import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {XpoAgGridBoardData} from '@xpo-ltl/ngx-board/ag-grid';
import {XpoBoardData, XpoBoardDataSource, XpoBoardState} from '@xpo-ltl/ngx-board/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';

import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { AppState } from 'src/app/store';
import { getBusinessNameAliases } from 'src/app/store/details/details.selectors';
import { getAccountAlias } from 'src/app/store/location/location.selectors';

@Injectable({ providedIn: 'root' })
export class CustomerBusinessNameService extends XpoBoardDataSource {
  constructor(private customerService: CustomerApiService, private store: Store<AppState>, private router: Router) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    // This for dev purposes do not remove
    console.log('MockGridDataSource state', state);
    const aliases = this.isChangeRequest() ? this.store.select(getBusinessNameAliases) : this.store.select(getAccountAlias);

    this.pageSize = 10;

    const subscription$: Observable<any> = aliases;
    return subscription$.pipe(
      take(1),
      map((resp) => {
        return resp.length === 0
          ? new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state))
          : new XpoBoardData(state, resp, resp.length, this.pageSize);
      })
    );
  }

  isChangeRequest(): boolean {
    const isParent = window.location.pathname.includes('parent');
    let parentSequenceNbr;

    if (isParent) {
      const urlPath = window.location.pathname.split('/');
      parentSequenceNbr = +urlPath[urlPath.findIndex((s) => s === 'parent') + 1];
    }
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return isParent ? parentSequenceNbr > 0 : urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }
}
