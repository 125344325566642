import { Injectable } from '@angular/core';
import { GridApi } from 'ag-grid-community';
@Injectable({
  providedIn: 'root',
})
export class GridApiContextService {
  private gridApi: GridApi;

  constructor() {}

  saveGridApi(currentGridApi: GridApi): void {
    this.gridApi = currentGridApi;
  }

  getCurrentGridApi(): GridApi {
    return this.gridApi;
  }
}
