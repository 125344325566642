import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
];

@NgModule({
    declarations: [...dialogs],
    imports: [
        // MATERIAL OR NGX CORE COMPONENTS
        XpoDialogModule,
        MatDialogModule,
        MatIconModule,
    ],
    exports: [...dialogs],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogModule {}
