import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerApiService, GetRequestGrabAndLockResp, RequestQueueFullData } from '@xpo-ltl-2.0/sdk-customer';
import * as Highcharts from 'highcharts';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { AppState } from 'src/app/store';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { UserRole } from '../../enums/user-role/user-role.enum';
import { DialogComponent, DialogConfig } from '../dialog';

@Component({
  selector: 'app-label-chart-metrics',
  templateUrl: 'label-chart-metrics.component.html',
  styleUrls: ['label-chart-metrics.component.scss'],
})
export class LabelChartMetricsComponent implements OnInit, OnChanges {
  @Input() queueData: RequestQueueFullData;
  columnOptions: any;
  globalOptions: Highcharts.Options;
  chartData: any;
  isHover: boolean = false;
  isOutside: boolean = false;
  isWithin: boolean = false;
  disableProcess: boolean = false;

  constructor(
    private customerApi: CustomerApiService,
    private utilsService: UtilsService,
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {
    this.store.pipe(select(getLoggedInUserRole)).subscribe((role) => {
      this.disableProcess = role === UserRole.noUpdateAuthorization;
    });
  }

  ngOnInit(): void {
    this.globalOptions = {
      lang: {
        thousandsSep: '',
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartData = this.getChartData(this.queueData);
    if (this.queueData) {
      this.columnOptions = {
        chart: { type: 'pie' },
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%'],
            borderWidth: 0,
          },
          series: {},
        },
        tooltip: {
          enabled: false,
          valueSuffix: '%',
        },
        series: [
          {
            name: 'Versions',
            data: this.chartData,
            size: '90%',
            innerSize: '90%',
            dataLabels: {
              enabled: false,
            },
            id: 'versions',
            point: {
              events: {
                mouseOver: (e) => {
                  this.isHover = true;
                  this.isOutside = e.target.name === 'Outside SLA';
                  this.isWithin = e.target.name === 'Within SLA';
                },
                mouseOut: (e) => {
                  this.isHover = false;
                  this.isOutside = false;
                  this.isWithin = false;
                },
              },
            },
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 400,
              },
              chartOptions: {
                series: [
                  {},
                  {
                    id: 'versions',
                    dataLabels: {
                      enabled: false,
                    },
                  },
                ],
              },
            },
          ],
        },
      };
    }
  }

  getChangeRequest(queueName): void {
    this.customerApi.getRequestGrabAndLock({ rqstQueueName: queueName }).subscribe(
      (res: GetRequestGrabAndLockResp) => {
        if (res.requestId) {
          this.router.navigate([AppRoutes.CHANGE_REQUEST_PAGE, res.requestId]);
        } else {
          const dialog: DialogConfig = {
            title: 'There are no more requests in the queue.',
            icon: 'report_problem',
            actions: [
              {
                label: 'Close',
                type: 'primary',
                resultType: 'CLOSE',
                resultAction: false,
                position: 'right',
              },
            ],
          };
          this.dialog.open(DialogComponent, { maxWidth: '600px', data: dialog }).afterClosed();
        }
      },
      (err) => {
        this.utilsService.showServiceErrMessage(err);
      }
    );
  }

  getChartData(queue: RequestQueueFullData): any[] {
    if (queue.unprocessedRequest) {
      const totalSla = queue.withinSLARequests + queue.outsideSLARequests;
      const slaInPercentage = queue.withinSLARequests / totalSla;
      const slaOutPercentage = queue.outsideSLARequests / totalSla;

      if (slaInPercentage === 0) {
        return [
          {
            name: 'Outside SLA',
            y: 100,
            color: '#D3302F',
          },
        ];
      } else if (slaOutPercentage === 0) {
        return [
          {
            name: 'Within SLA',
            y: 100,
            color: '#4BAF4F',
          },
        ];
      } else {
        return [
          {
            name: 'Outside SLA',
            y: slaOutPercentage,
            color: '#D3302F',
          },
          {
            name: 'Within SLA',
            y: slaInPercentage,
            color: '#4BAF4F',
          },
        ];
      }
    } else {
      return [
        {
          name: 'Empty',
          y: 100,
          color: '#7F7F7F',
        },
      ];
    }
  }
}
