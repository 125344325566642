import { Action } from '@ngrx/store';

export enum CustomerHistoryActionTypes {
  GetCustomerHistory = '[CustomerHistory] GetCustomerHistory',
  SetCustomerHistory = '[CustomerHistory] SetCustomerHistory',
}

export class GetCustomerHistory implements Action {
  readonly type = CustomerHistoryActionTypes.GetCustomerHistory;
  constructor() {}
}

export class SetCustomerHistory implements Action {
  readonly type = CustomerHistoryActionTypes.SetCustomerHistory;
  constructor(public payload) {}
}

export type CustomerHistoryAction = GetCustomerHistory | SetCustomerHistory;
