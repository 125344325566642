<div class="ncis-company-information-navbar-container" [ngClass]="{ sticky: stickyTabs }">
  <div class="ncis-company-information-navbar-links-wrapper">
    <a
      id="account-tab-container"
      matBadge="{{ isNewLocation ? newAccountInfoChangesCount : accountInfoChangesCount }}"
      matBadgeOverlap="false"
      matBadgeSize="medium"
      [ngClass]="{ 'line-processed': isChangeRequest && statusIsApprovedOrReject }"
      [matBadgeHidden]="
        isNewLocation
          ? newAccountInfoChangesCount === 0 || statusIsApprovedOrReject || locationStatusIsHeld
          : !isChangeRequest ||
            accountInfoChangesCount === 0 ||
            (isChangeRequest && statusIsApprovedOrReject) ||
            locationStatusIsHeld ||
            !locationHasChanges
      "
      [routerLink]="'account-information'"
      routerLinkActive="active"
      (click)="resetShowChangesOnly($event, 0)"
    >
      Account Information
      <mat-icon
        id="account-tab-success"
        *ngIf="isChangeRequest && statusIsApprovedOrReject && locationHasChanges"
        style="color: #388e3c; margin-left: 8px;"
      >
        check_circle
      </mat-icon>
      <xpo-status-indicator
        *ngIf="locationStatusIsHeld"
        [statusColor]="'orange'"
        statusText="HOLD"
        isLabel="true"
        style="margin-left: 8px;"
      ></xpo-status-indicator>
    </a>
    <a
      id="alias-tab-container"
      matBadge="{{ businessNameChangesCount }}"
      matBadgeOverlap="false"
      matBadgeSize="medium"
      [matBadgeHidden]="!isChangeRequest || businessNameChangesCount === 0 || aliasHasHeldLine || !locationHasChanges"
      [ngClass]="{
        'line-processed':
          isChangeRequest &&
          businessNameChangesCount === 0 &&
          (changeRequestDataCurrentLocation?.requestCustomerLocationFunction)[0]?.requestCustomerFunctionAlias?.length >
            0,
        disabled: isNewLocation
      }"
      [routerLink]="'aliases'"
      routerLinkActive="active"
      (click)="resetShowChangesOnly($event, 1)"
    >
      Business Name Alias
      <mat-icon
        id="alias-tab-success"
        *ngIf="
          isChangeRequest &&
          businessNameChangesCount === 0 &&
          (changeRequestDataCurrentLocation?.requestCustomerLocationFunction)[0]?.requestCustomerFunctionAlias?.length >
            0 &&
          locationHasChanges
        "
        style="color: #388e3c; margin-left: 8px;"
        >check_circle
      </mat-icon>
      <xpo-status-indicator
        *ngIf="aliasHasHeldLine"
        [statusColor]="'orange'"
        statusText="HOLD"
        isLabel="true"
        style="margin-left: 8px;"
      ></xpo-status-indicator>
    </a>
    <a
      id="contact-tab-container"
      matBadge="{{ contactsChangesCount }}"
      matBadgeOverlap="false"
      matBadgeSize="medium"
      [matBadgeHidden]="!isChangeRequest || contactsChangesCount === 0 || contactHasHeldLine || !locationHasChanges"
      [ngClass]="{
        'line-processed':
          isChangeRequest &&
          contactsChangesCount === 0 &&
          changeRequestDataCurrentLocation?.requestContactPerson?.length > 0,
        disabled: isNewLocation
      }"
      [routerLink]="'contacts'"
      routerLinkActive="active"
      (click)="resetShowChangesOnly($event, 2)"
    >
      Contacts
      <mat-icon
        id="contact-tab-success"
        *ngIf="
          isChangeRequest &&
          contactsChangesCount === 0 &&
          changeRequestDataCurrentLocation?.requestContactPerson?.length > 0 &&
          locationHasChanges
        "
        style="color: #388e3c; margin-left: 8px;"
        >check_circle
      </mat-icon>
      <xpo-status-indicator
        *ngIf="contactHasHeldLine"
        [statusColor]="'orange'"
        statusText="HOLD"
        isLabel="true"
        style="margin-left: 8px;"
      ></xpo-status-indicator>
    </a>
    <a
      id="invoice-tab-container"
      *ngIf="hasInvoiceInstructions"
      matBadge="{{ invoiceChangesCount }}"
      matBadgeOverlap="false"
      matBadgeSize="medium"
      [matBadgeHidden]="!isChangeRequest || invoiceChangesCount === 0 || invoiceHasHeldLine || !locationHasChanges"
      [ngClass]="{
        'line-processed':
          isChangeRequest &&
          invoiceChangesCount === 0 &&
          (changeRequestDataCurrentLocation?.requestCustomerLocationFunction)[0]?.requestInvoicePreference?.length > 0,
        disabled: isNewLocation
      }"
      [routerLink]="'invoice-instructions'"
      routerLinkActive="active"
      (click)="resetShowChangesOnly($event, 3)"
    >
      Invoice Instructions
      <mat-icon
        id="invoice-tab-success"
        *ngIf="
          isChangeRequest &&
          invoiceChangesCount === 0 &&
          (changeRequestDataCurrentLocation?.requestCustomerLocationFunction)[0]?.requestInvoicePreference?.length >
            0 &&
          locationHasChanges
        "
        style="color: #388e3c; margin-left: 8px;"
        >check_circle
      </mat-icon>
      <xpo-status-indicator
        *ngIf="invoiceHasHeldLine"
        [statusColor]="'orange'"
        statusText="HOLD"
        isLabel="true"
        style="margin-left: 8px;"
      ></xpo-status-indicator>
    </a>
  </div>
  <div class="ncis-show-changes">
    <mat-checkbox
      [checked]="checkedShowChangesOnly"
      *ngIf="locationHasChanges && showCheckbox"
      (change)="changeValueShowChangesOnly($event, 4)"
      >Show Changes Only
    </mat-checkbox>
  </div>
</div>
