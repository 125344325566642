<div (click)="onClick($event)" *ngIf="!isEditMode; else isEditing">{{ params.parsedValue || params.value }}</div>
<ng-template #isEditing>
  <mat-select
    (selectionChange)="onChange($event)"
    class="container"
    [value]="params.value"
    (click)="preventClose($event)"
    disableOptionCentering="true"
  >
    <mat-option *ngFor="let option of options | async" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</ng-template>
