import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { getEmployees } from 'src/app/store/change-request/change-request.selectors';

@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(value: string): Observable<string> {
    return this.store.pipe(
      select(getEmployees),
      map((employees) => {
        if (!value || (employees && !employees.length)) {
          return '';
        }

        const employee = employees.find((emp) => emp?.interfaceEmployee?.employeeId === value);

        if (employee) {
          return employee ? employee.interfaceEmployee.firstName + ' ' + employee.interfaceEmployee.lastName : '';
        } else {
          return value;
        }
      })
    );
  }
}
