export enum PaymentTerms {
  Delinquent = 'Delinquent',
  Immediate = 'Immediate',
  Term15Days = '15 days',
  Term20Days = '20 days',
  Term30Days = '30 days',
  Term35Days = '35 days',
  Term40Days = '40 days',
  Term45Days = '45 days',
  Term50Days = '50 days',
  Term55Days = '55 days',
  Term60Days = '60 days',
  Term75Days = '75 days',
  Term90Days = '90 days',
  Term120Days = '120 days',
  Term180Days = '180 days',
}

export enum PaymentTermsValue {
  Delinquent = 'Delinquent',
  Immediate = 'Immediate',
  Term15Days = 'Term15Days',
  Term20Days = 'Term20Days',
  Term30Days = 'Term30Days',
  Term35Days = 'Term35Days',
  Term40Days = 'Term40Days',
  Term45Days = 'Term45Days',
  Term50Days = 'Term50Days',
  Term55Days = 'Term55Days',
  Term60Days = 'Term60Days',
  Term75Days = 'Term75Days',
  Term90Days = 'Term90Days',
  Term120Days = 'Term120Days',
  Term180Days = 'Term180Days',
}
