import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ncisXpoFocusOnSubmit]',
})
export class NcisXpoFocusOnSubmitDirective {
  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit(): void {
    const invalidElements = this.el.nativeElement.getElementsByClassName('ng-invalid');
    const abElements = [...invalidElements].filter((element) => element.className.includes('mat-'));
    if (abElements.length > 0) {
      abElements[1].focus();
    }
  }
}
