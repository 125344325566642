import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerDetailCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import moment from 'moment';
import { YesNo } from 'src/app/change-request-page/models/enums/yes-no';
import { EmployeesService } from 'src/app/change-request-page/services/employees.service';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';

@Component({
  selector: 'app-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.scss'],
})
export class PricingDialogComponent implements OnInit {
  pricingDetailsForm: UntypedFormGroup;
  salesOwner: string;
  primaryAccount: string;
  pricingStatus: string;
  pricingData: any;
  expiryDate: any;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialogRef<PricingDialogComponent>,
    private employeeService: EmployeesService,
    private locationDetailService: LocationDetailService,
    private customerApi: CustomerApiService,
    private snackbar: XpoSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.locationDetailService.hasActivePricing(this.data.legacyCisCustomerNbr, false).subscribe(
      (res) => {
        this.pricingData = res.agreementData;
        if (!this.pricingData) {
          this.snackbar.open({
            message: 'The pricing Agreement service is not available.',
            status: 'error',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
        }
        this.expiryDate = res.expiryDate;
        const salesOwnerId = this.pricingData?.accountOwner?.salesOwnerEmployeeId;
        const primaryAcctId = this.pricingData?.agreements && this.pricingData?.agreements[0].primaryAcctId;

        this.createForm();

        if (salesOwnerId) {
          this.employeeService.getEmployee(salesOwnerId).subscribe((name) => {
            this.salesOwner = name;
            this.pricingDetailsForm.controls['salesOwner'].setValue(name);
          });
        }

        if (primaryAcctId) {
          this.pricingDetailsForm.controls['primaryAccount'].setValue('Loading...');
          this.customerApi
            .getCustomer(
              { id: primaryAcctId },
              {
                customerIdTypeCd: CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID,
                customerDetailCd: [CustomerDetailCd.ACCOUNT],
                inheritContacts: true,
              },
              { loadingOverlayEnabled: false }
            )
            .subscribe((data) => {
              this.primaryAccount = data.customerLocation?.customerLocationFunction.find(
                (loc) => loc.legacyCisCustomerNbr === primaryAcctId
              )?.madCd;
              this.pricingDetailsForm.controls['primaryAccount'].setValue(this.primaryAccount);
            });
        }
      },
      (_) => {
        this.pricingStatus = 'The pricing Agreement service is not available.';
        this.createForm();
      }
    );
  }

  private createForm(): void {
    const notAvailable = this.pricingStatus === 'The pricing Agreement service is not available.';
    const lastExpiryAgreement = this.pricingData?.agreements
      ? this.pricingData?.agreements?.sort((a, b) => (a.expiryDate > b.expiryDate ? -1 : 1))[0]
      : {};
    const account = this.pricingData?.account;
    const status = lastExpiryAgreement.expiryDate
      ? moment(lastExpiryAgreement?.expiryDate).isAfter(moment())
        ? 'Active'
        : 'Expired'
      : '';

    this.pricingDetailsForm = this.fb.group({
      agreemmentId: [
        notAvailable || !lastExpiryAgreement?.agreementSequenceNbr
          ? ''
          : `${account.acctInstId}-${lastExpiryAgreement?.agreementSequenceNbr}-${lastExpiryAgreement?.agreementCustomerVersion}`,
      ],
      obi: [
        notAvailable
          ? ''
          : this.pricingData?.agreements && YesNo[this.pricingData?.agreements[0]?.obiInd?.toString()?.toUpperCase()],
      ],
      salesOwner: [''],
      primaryAccount: [lastExpiryAgreement?.primaryAcctId],
      status: [status],
      effectiveDate: [
        notAvailable || !lastExpiryAgreement?.effectiveDate
          ? ''
          : lastExpiryAgreement && moment(lastExpiryAgreement?.effectiveDate).format('MM/DD/YYYY'),
      ],
      expirationDate: [
        notAvailable || !lastExpiryAgreement?.effectiveDate
          ? ''
          : lastExpiryAgreement && moment(lastExpiryAgreement?.expiryDate).format('MM/DD/YYYY'),
      ],
    });

    this.pricingDetailsForm.disable();
  }

  close(): void {
    this.dialog.close();
  }
}
