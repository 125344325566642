import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutocompleteOff]',
})
export class AutocompleteOffDirective implements AfterViewInit {
  private element: HTMLInputElement;
  constructor(private elRef: ElementRef) {
    this.element = elRef ? elRef.nativeElement : null;
  }

  ngAfterViewInit(): void {
    if (this.element) {
      this.element.autocomplete = 'new-password';
    }
  }
}
