<div style="padding: 0 16px;" mat-dialog-content>
  <div class="service-recipient-dialog-header cdk-cursor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <p>Results of: "{{ search }}"</p>
  </div>
  <div class="service-recipient-dialog-content">
    <xpo-board
      [dataSource]="dataSource"
      [viewDataStore]="viewDataStore"
      [viewTemplates]="viewTemplates"
      [boardOptions]="boardOptions"
      (selectionChange)="onSelectionChange($event)"
    >
      <xpo-ag-grid-board
        rowSelection="false"
        rowModelType="infinite"
        [enableSorting]="true"
        [sizeColumnsToFit]="true"
        [customGridOptions]="customGridOptions"
        (gridBoardReady)="gridBoardReady($event)"
      >
      </xpo-ag-grid-board>
<!--      <xpo-board-pagination #myPagination></xpo-board-pagination>-->
    </xpo-board>

    <div class="service-recipient-dialog-content-actions">
      <button (click)="saveRecipient()" [disabled]="!rowSelected" mat-flat-button>Select Recipient</button>
      <button (click)="close()" mat-stroked-button>Cancel</button>
    </div>
  </div>
</div>
