import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { CustomerApiService, ServiceRecipient } from '@xpo-ltl-2.0/sdk-customer';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { ValidatorHelper } from 'src/app/shared/validators';
import { ServiceRecipientResultDialogComponent } from '../service-recipient-result-dialog/service-recipient-result-dialog.component';

@Component({
  selector: 'app-service-recipient-input',
  templateUrl: './service-recipient-input.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ServiceRecipientInputComponent,
    },
  ],
  styleUrls: ['./service-recipient-input.component.scss'],
})
export class ServiceRecipientInputComponent
  implements OnInit, OnDestroy, MatFormFieldControl<number>, ControlValueAccessor {
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = !!req;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = !!value;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  set value(number) {
    this._value = number;
    this.stateChanges.next();
  }

  get value(): number {
    return this._value;
  }

  get empty(): boolean {
    return !!this._value;
  }

  get errorState(): boolean {
    return this.ngControl?.control?.invalid;
  }

  constructor(
    private customerApi: CustomerApiService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    @Optional() public parentFormField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  static nextId = 0;
  placeholder;
  stateChanges = new Subject<void>();
  focused: boolean;
  controlType = 'mat-input';
  results: Observable<any[]>;
  searchControl: UntypedFormControl;
  noResults: boolean;
  hasValue = false;

  private _required: boolean;
  private _disabled: boolean;
  private _value: any;
  private onChangeFn;
  private onTouchedFn;
  private destroy$ = new Subject();
  private cancel$ = new Subject();
  private valueSelected = false;
  loading: boolean;

  @HostBinding() id: string = `phone-number-input-${ServiceRecipientInputComponent.nextId}`;
  @Output() numberChanged = new EventEmitter<ServiceRecipient>();

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement.querySelector('.service-recipient-input-container');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.searchControl?.disable();
    } else {
      this.searchControl?.enable();
    }
  }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl({ disabled: this.disabled, value: this.value });
    this.hasValue = !!this.value;

    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.inspectChanges(this.searchControl.valueChanges);
  }

  private inspectChanges(obs: Observable<any>): void {
    obs.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
      if (!this.isObject(val)) {
        if (this.isAllNumbers(val)) {
          this.searchServiceRecipientByNumber(val);
        } else {
          this.searchServiceRecipientByName(val);
        }
      }
    });
  }

  private isAllNumbers(text: string): boolean {
    return text
      ?.trim()
      .split('')
      .every((l) => /^\d$/.test(l));
  }

  private isObject(val): boolean {
    return typeof val === 'object' && val !== null;
  }

  searchServiceRecipientByName(value): void {
    this.loading = true;
    this.noResults = false;
    this.valueSelected = false;
    this.cancel$.next();

    this.openDialog(value.trim())
      .subscribe(
        (data) => {
          if (data) {
            this.noResults = false;
            this.results = of([
              {
                text: `${data.serviceRecipientNbr} - ${data.serviceRecipientName}`,
                value: data,
              },
            ]);
          } else {
            this.noResults = true;
          }
        },
        () => {
          this.noResults = true;
        }
      )
      .add((_) => (this.loading = false));
  }

  private openDialog(search: string): Observable<any> {
    return this.dialog
      .open(ServiceRecipientResultDialogComponent, {
        data: {
          search: search,
        },
        minWidth: 1000,
        disableClose: true,
      })
      .afterClosed();
  }

  searchServiceRecipientByNumber(value): void {
    this.loading = true;
    this.noResults = false;
    this.valueSelected = false;
    this.cancel$.next();
    this.customerApi
      .listServiceRecipientProfile(
        { serviceRecipientNbr: value?.trim(), serviceRecipientName: null, typeOfServiceInd: null },
        { loadingOverlayEnabled: false }
      )
      .pipe(
        takeUntil(this.cancel$),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (res) => {
          const serviceRecipient = res.serviceReciepientProfiles[0].serviceRecipient;
          serviceRecipient.serviceRecipientName = serviceRecipient.serviceRecipientName.substring(0, 28)?.trim();
          this.results = of([
            {
              text: `${serviceRecipient.serviceRecipientNbr} - ${serviceRecipient.serviceRecipientName}`,
              value: serviceRecipient,
            },
          ]);
          this.noResults = false;
        },
        () => {
          this.noResults = true;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.cancel$.next();
    this.cancel$.complete();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  updateValue(event: MatAutocompleteSelectedEvent): void {
    this.value = event.option.value.serviceRecipientNbr;
    this.hasValue = true;
    this.onChangeFn(this.value);
    this.numberChanged.emit(event.option.value);
    this.valueSelected = true;
  }

  removeValue(e): void {
    e.stopImmediatePropagation();
    this.hasValue = false;
    this.value = undefined;
    this.onChangeFn(this.value);
    this.numberChanged.emit(null);
    this.searchControl.setValue('');
    this.valueSelected = true;
  }

  showServiceRecipientNbr = (value): string => {
    return value?.serviceRecipientNbr || this.value;
  }

  handleBlur(): void {
    if (!this.valueSelected && this.serviceRecipientNumberHasChanged()) {
      ValidatorHelper.addControlError(this.ngControl.control, {
        noValueSelected: 'Please select a Service Recipient from the list.',
      });
    } else {
      ValidatorHelper.removeControlError(this.ngControl.control, 'noValueSelected');
    }
  }

  private serviceRecipientNumberHasChanged(): boolean {
    // handle null and undefined as empty string
    return this.searchControl.dirty && this.searchControl.value !== (this.value?.toString() || '');
  }
}
