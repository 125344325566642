<div fxLayout="row">
  <xpo-card class="highcharts-figure">
    <div class="title-container">
      <p>
        <span class="queue-name">{{ queueData.queueName }}</span>
      </p>
      <p><span>Total Unprocessed: </span>{{ queueData.unprocessedRequest }}</p>
      <p><span>Pending: </span>{{ queueData.totalPendingRequests }}</p>
    </div>
    <xpo-highcharts-chart *ngIf="queueData" [options]="columnOptions"> </xpo-highcharts-chart>
    <div *ngIf="queueData.unprocessedRequest" [ngClass]="{ 'is-outside-sla': isHover && isOutside }"
      class="chart-center-container">
      <div *ngIf="isHover">
        <div *ngIf="isOutside" class="default-text hover-text">
          <span class="outside-number">{{ queueData.outsideSLARequests }}</span>
          <p>
            Outside SLA
          </p>
          <div class="item-separator"></div>
          <p>{{ queueData.oldestRequestId }} - {{ queueData.oldestRequestDate | date }}</p>
        </div>
        <div *ngIf="isWithin" class="default-text hover-text">
          <span class="within-number">{{ queueData.withinSLARequests }}</span>
          <p>
            Within SLA
          </p>
          <div class="item-separator"></div>
        </div>
      </div>
      <div *ngIf="!isHover">
        <div class="default-text">
          <p>
            Outside SLA <span class="outside-number">{{ queueData.outsideSLARequests }}</span>
          </p>
          <div class="item-separator"></div>
          <p>
            Within SLA <span class="within-number">{{ queueData.withinSLARequests }}</span>
          </p>
          <div class="item-separator"></div>
        </div>
        <button mat-flat-button [disabled]="disableProcess" (click)="getChangeRequest(queueData.queueName)">
          PROCESS
        </button>
      </div>
    </div>
    <p class="empty-text" *ngIf="!queueData.unprocessedRequest">Empty</p>
  </xpo-card>
</div>
