<form
  tabindex="0"
  (focusout)="emitBlur($event)"
  [formGroup]="phoneNumberForm"
  role="group"
  [ngClass]="{ 'is-disabled': disabled }"
  class="phone-number-input-container"
  [attr.aria-describedby]="userAriaDescribedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()"
>
  <!-- <mat-select *ngIf="showCountryCd" formControlName="phoneCountryCd" style="width: 40px;">
    <mat-option value="1">US</mat-option>
    <mat-option value="1">CA</mat-option>
    <mat-option value="52">MX</mat-option>
  </mat-select> -->
  <span *ngIf="showCountryCd">
    +
    <input
      formControlName="phoneCountryCd"
      [ngStyle]="{ width: (phoneCountryCd?.value?.length * 11 || 11) + 'px' }"
      #phoneCountryCd
      tabindex="0"
      maxlength="3"
      class="phone-input-text phone-country-code"
      type="text"
      (keydown)="onKeyDown($event)"
    />&nbsp;
  </span>
  (<input
    formControlName="phoneAreaCd"
    tabindex="0"
    maxlength="3"
    #phoneAreaCd
    (input)="areaCdChange($event)"
    class="phone-input-text area-code"
    type="text"
    (keydown)="onKeyDown($event)"
  />)&nbsp;
  <input
    formControlName="phonePrefix"
    tabindex="0"
    maxlength="3"
    #phonePrefix
    (input)="suffixChange($event)"
    class="phone-input-text phone-prefix"
    type="text"
    (keydown)="onKeyDown($event)"
  />-
  <input
    formControlName="phoneSuffix"
    (keydown)="onKeyDown($event)"
    tabindex="0"
    maxlength="4"
    #phoneSuffix
    class="phone-input-text phone-suffix"
    type="text"
  />
  <span *ngIf="showPhoneExtension">
    ext.
    <input
      formControlName="phoneExtension"
      #phoneExtension
      tabindex="0"
      maxlength="6"
      class="phone-input-text phone-extension"
      type="text"
      (keydown)="onKeyDown($event)"
    />
  </span>
</form>
