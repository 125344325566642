<div class="custom-pagination-wrapper">
  <div class="buttons-container">
    <div class="buttons-wrapper">
      <button matIconButton (click)="goToFirst()" [disabled]="!api || currentPage === 1">
        <mat-icon>first_page</mat-icon>
      </button>
      <button matIconButton (click)="goToPreviousPage()" [disabled]="!api || currentPage === 1">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button matIconButton (click)="goToNextPage()" [disabled]="!api || currentPage === totalPages">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button matIconButton (click)="goToLast()" [disabled]="!api || currentPage === totalPages">
        <mat-icon>last_page</mat-icon>
      </button>
      <div class="go-to-page-container">
        <input appOnlyNumbers type="text" #page matInput [value]="currentPage" />
        <button (click)="goToPage(+page.value)" [disabled]="totalPages === 1">GO</button>
      </div>
    </div>
    <div class="pages-counter">
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
    </div>
  </div>
  <div class="page-size-container">
    <select (change)="setPaginationSize($event)">
      <option *ngFor="let pageSize of pageSizesOptions" [value]="pageSize">{{ pageSize }}</option>
    </select>
    <mat-label>{{ pageSizeInfo }}</mat-label>
  </div>
</div>
