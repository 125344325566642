import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { AppState } from 'src/app/store';
import { SetUser, SetUserRole } from 'src/app/store/user/user.action';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRole } from '../../enums/user-role/user-role.enum';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  user: User;
  private isProduction = false;
  private roleNames = {};
  private readonly removeDomainRegExp = /.*\//gm;

  constructor(private configManagerService: ConfigManagerService, private store: Store<AppState>) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    this.roleNames = {
      [UserRole.Coder]: this.isProduction ? ['LTL_CUST_MAST_SPEC'] : ['TST_LTL_CUST_MAST_SPEC'],
      [UserRole.CreditAnalyst]: this.isProduction ? ['LTL_CUST_MAST_CREDIT'] : ['TST_LTL_CUST_MAST_CREDIT'],
      [UserRole.CodingCreditManager]: this.isProduction ? ['LTL_CUST_MAST_MGR'] : ['TST_LTL_CUST_MAST_MGR'],
    };
  }

  isAuthorizedUser(user: User): boolean {
    this.user = user;
    this.store.dispatch(new SetUser(user));
    // return (
    //   this.hasRole(user.roles, this.roleNames[UserRole.Coder]) ||
    //   this.hasRole(user.roles, this.roleNames[UserRole.CreditAnalyst]) ||
    //   this.hasRole(user.roles, this.roleNames[UserRole.CodingCreditManager])
    // );
    return true;
  }

  /** TODO: some authorized users may have mutliple roles... see role-check-guard.ts as it handles this!!! */
  hasMultipleRoles(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.Coder]) &&
      this.hasRole(user.roles, this.roleNames[UserRole.CreditAnalyst])
    );
  }

  get isBasicUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.CreditAnalyst]);
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const splitUserRolesArr = _.map(userRoles, (role: string) => {
      return role.replace(this.removeDomainRegExp, '');
    });
    const results = _.intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results && !!results.length;
  }

  setRole(): void {
    let currentRole: UserRole;
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.Coder])) {
      currentRole = UserRole.Coder;
    }
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.CreditAnalyst])) {
      currentRole = UserRole.CreditAnalyst;
    }
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.CodingCreditManager])) {
      currentRole = UserRole.CodingCreditManager;
    }
    if (!currentRole) {
      currentRole = UserRole.noUpdateAuthorization;
    }
    this.store.dispatch(new SetUserRole({ role: currentRole }));
  }
}
