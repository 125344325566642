import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { UserRole } from '../enums/user-role/user-role.enum';

@Pipe({
  name: 'userAccess',
})
export class UserAccessPipe implements PipeTransform {


  transform(userRole: Observable<UserRole>, restrictedTo: UserRole[], isValid: boolean): Observable<boolean> {
    return new Observable((observer) => {
      userRole.pipe(take(1), skipWhile((role) => !role)).subscribe((role) => {
        const hasAccess = restrictedTo.includes(role);
        if (isValid !== undefined) {
          observer.next(hasAccess || isValid);
        } else {
          observer.next(hasAccess);
        }
      })
    })
  }
}
