import { GetCustomerResp, GetInvoicingInstructionsResp } from '@xpo-ltl-2.0/sdk-customer';
import { InvoiceActionTypes } from './invoice.actions';

export interface InvoiceState {
  locations: GetCustomerResp[];
  invoices: GetInvoicingInstructionsResp;
}

export const initialState: InvoiceState = {
  locations: [],
  invoices: null,
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case InvoiceActionTypes.GetInvoiceChangesLocationData: {
      return { ...state };
    }
    case InvoiceActionTypes.SetInvoiceChangesLocationData: {
      return Object.assign({}, state, action.payload);
    }
    case InvoiceActionTypes.GetInvoicesCurrentLocation: {
      return { ...state };
    }
    case InvoiceActionTypes.SetInvoicesCurrentLocation: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};
