<xpo-ag-grid>
  <ag-grid-angular
    class="instruction-history-grid"
    (gridReady)="onGridReady($event)"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [columnDefs]="columnInstructionDefs"
    [gridOptions]="gridInstructionOptions"
    (firstDataRendered)="onFirstDataRendered($event)"
    [rowData]="rowData"
  >
  </ag-grid-angular>
</xpo-ag-grid>
