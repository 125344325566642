import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { select, Store } from '@ngrx/store';
import {
  CustomerApiService,
  CustomerRequest,
  GetCustomerResp,
  RequestInvoicePreference,
  RequestNote,
} from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import {
  ActionCd,
  CustomerFunctionCd,
  CustomerLineStatusCd,
  CustomerNoteForCd,
  CustomerNoteTypeCd,
  CustomerRequestStatusCd,
} from '@xpo-ltl/sdk-common';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ChangeRequestService } from 'src/app/change-request-page/services/change-request.service';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { DialogComponent, DialogConfig } from 'src/app/shared/components/dialog';
import { CustomerLineStatusCdLabel } from 'src/app/shared/enums/customer-line-status-cd-label.enum';
import { InvoiceInstructionLabel } from 'src/app/shared/enums/invoice-instruction-label.enum';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import {
  getRequestedChangeCurrentLine,
  loggedInUserIsOwner,
} from 'src/app/store/change-request/change-request.selectors';
import { SetEditMode } from 'src/app/store/details/details.actions';
import { getIsEditMode } from 'src/app/store/details/details.selectors';
import { getInvoicesData } from 'src/app/store/invoice/invoice.selectors';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { getLocationData, getLocationId } from '../../../store/location/location.selectors';
import { UserRole } from '../../enums/user-role/user-role.enum';

@Component({
  selector: 'app-customer-invoice-instructions',
  templateUrl: './customer-invoice-instructions.component.html',
  styleUrls: ['./customer-invoice-instructions.component.scss', '../../../shared/styles/shared-styles.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-customer-invoice-instructions-container',
  },
})
export class CustomerInvoiceInstructionsComponent implements OnInit, OnDestroy {
  constructor(
    private locationDetailsService: LocationDetailService,
    private snackbar: XpoSnackBar,
    private customerApi: CustomerApiService,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private changeRequestService: ChangeRequestService,
    public dialog: MatDialog
  ) {
    this.isChangeRequest = window.location.pathname.includes('change-request');
  }

  statusEnum = CustomerLineStatusCdLabel;
  showChangesOnly: boolean = true;
  form: UntypedFormGroup;
  isEditMode = false;
  hasChanges = false;
  dataSourceInvoice = new MatTableDataSource<any>();
  displayedColumns: string[] = ['invoicetype', 'current'];
  gridOptions: any;
  columnDefs: any;
  invoicesData: any;
  noInvoicingInstructions: string = 'Same as location.';
  billToOnRequest: string = 'Bill-to on request';
  changeRequestData: CustomerRequest;
  loggedInUserIsOwner: boolean;
  statusIsApproveOrRejected: boolean;
  statusIsApproved: boolean;
  isCompleted: boolean = false;
  statusIsHeld: boolean;
  invoiceForm: UntypedFormGroup;
  changeRequestDataCurrentLocation: CustomerRequest;
  invoiceChangesLocationData: GetCustomerResp[];
  locationId: string;
  readOnly: boolean = false;
  requesterNote: string;
  requesterRemarks: string;
  commentsNote: RequestNote;
  shouldShowStatusIndicator = false;
  isChangeRequest: boolean;
  invoiceInstructionLabels = InvoiceInstructionLabel;
  minDate = new Date();
  locationData: GetCustomerResp;
  isNewLocation: boolean = false;
  madCode: string;
  prepaidCopyMadCd: string = '';
  collectCopyMadCd: string = '';
  userRoles$: Observable<UserRole>;
  allInvoicesChanged: boolean = false;

  private destroy = new Subject();

  ngOnInit(): void {
    this.store
      .select((state) => {
        return { changeRequestData: state.changeRequest.changeRequest };
      })
      .subscribe((res) => {
        this.changeRequestData = res.changeRequestData;
        this.isCompleted = this.changeRequestData?.statusCd === CustomerRequestStatusCd.COMPLETED;
        if (this.changeRequestData && this.changeRequestData.statusCd !== CustomerRequestStatusCd.PROCESS) {
          this.readOnly = true;
        }
      });

    this.store.pipe(select(getLocationId)).subscribe((id) => {
      this.locationId = id;
    });

    this.userRoles$ = this.store.select(getLoggedInUserRole);

    this.store.select(getLocationData).subscribe((res) => {
      this.locationData = res;
      this.madCode = this.locationData?.customerLocation?.customerLocationFunction[0]?.madCd;
    });

    this.store
      .select(getRequestedChangeCurrentLine)
      .pipe(takeUntil(this.destroy))
      .subscribe((resp) => {
        if (resp) {
          this.shouldShowStatusIndicator = false;
          const status = resp.requestCustomerLocationFunction[0]?.requestInvoicePreference
            ? resp.requestCustomerLocationFunction[0]?.requestInvoicePreference[0]?.statusCd
            : '';
          this.statusIsHeld = status === CustomerLineStatusCd.HELD;
          this.statusIsApproved = status === CustomerLineStatusCd.APPROVED;
          this.statusIsApproveOrRejected =
            status === CustomerLineStatusCd.REJECTED || status === CustomerLineStatusCd.APPROVED;
          this.changeRequestDataCurrentLocation = resp;
          this.allInvoicesChanged =
            this.changeRequestDataCurrentLocation?.requestCustomerLocationFunction[0]?.requestInvoicePreference
              ?.length === 8;
          const currLoc = resp.requestCustomerLocationFunction[0];
          this.isNewLocation = !currLoc?.madCd;
          if (this.locationHasChanges()) {
            this.requesterNote =
              (currLoc.requestNote?.find((n) => n.noteForCd === CustomerNoteForCd.FUNCTION_NOTE) || ({} as any)).note ||
              '';
            this.requesterRemarks =
              (
                currLoc.requestInvoicePreference[0]?.requestNote?.find(
                  (n) => n.noteTypeCd === CustomerNoteTypeCd.GENERAL
                ) || ({} as any)
              ).note || '';
            this.commentsNote = this.changeRequestService.getProcessNote(
              resp.requestCustomerLocationFunction[0]?.requestInvoicePreference[0]?.requestNote
            );
            this.shouldShowStatusIndicator =
              this.locationHasChanges() &&
              ![CustomerLineStatusCd.NO_UPDATE, CustomerLineStatusCd.NOT_IN_PROCESS].includes(
                currLoc.requestInvoicePreference[0]?.statusCd
              );
          }
        }
      });
    this.store.select(getInvoicesData).subscribe((res) => {
      this.showChangesOnly = res.showChangesOnly;
      this.invoicesData = res.data;
      const filteredChanges = this.filterChangesOnly(this.invoicesData);
      this.hasChanges = filteredChanges.length ? true : false;
      this.displayedColumns = this.hasChanges ? ['invoicetype', 'current', 'request'] : ['invoicetype', 'current'];
      this.dataSourceInvoice.data = this.showChangesOnly && this.locationHasChanges() ? filteredChanges : res.data;
      this.createRequestForm();
      this.createFormGroup();
    });

    this.store.pipe(select(getIsEditMode), takeUntil(this.destroy)).subscribe(this.toggleEditMode.bind(this));

    this.store.select(loggedInUserIsOwner).subscribe((is) => {
      this.loggedInUserIsOwner = is;
      if (this.loggedInUserIsOwner) {
        this.readOnly = false;
      }
    });
  }

  filterChangesOnly(data): any[] {
    return data
      ?.filter((d) => d.changes || d.isHeader)
      .reduce((acc, curr, idx, arr) => {
        const shouldPush = curr.isHeader ? (arr[idx + 1] && arr[idx + 1].isHeader ? false : arr[idx + 1]) : true;
        if (shouldPush) {
          acc.push(curr);
        }
        return acc;
      }, []);
  }

  createFormGroup(): void {
    this.invoiceForm = this.fb.group({
      comments: [
        this.requesterRemarks || this.commentsNote?.note || '',
        this.locationHasChanges()
          ? [ValidatorHelper.noWhiteSpace(), Validators.maxLength(800)]
          : [
              ValidatorHelper.noWhiteSpace(),
              Validators.maxLength(50),
              ValidatorHelper.required('Remarks is required.'),
            ],
      ],
    });
    this.invoiceForm.markAllAsTouched();
  }

  locationHasChanges(): boolean {
    return (
      this.isChangeRequest &&
      this.changeRequestDataCurrentLocation?.requestCustomerLocationFunction[0]?.requestInvoicePreference?.length > 0
    );
  }

  getRemainingCharacters(): number {
    const charCount = this.invoiceForm.get('comments').value ? this.invoiceForm.get('comments').value.length : 0;
    return ConstantsService.textAreaMaxLength - charCount;
  }

  enterEditMode(): void {
    this.store.dispatch(new SetEditMode({ isEditMode: true }));
  }

  toggleEditMode(isEditMode: boolean): void {
    if (isEditMode && !this.isEditMode) {
      this.isEditMode = true;
      if (!this.locationHasChanges()) {
        this.displayedColumns.push('request');
      } else if (!this.hasChanges) {
        this.displayedColumns = ['invoicetype', 'current', 'request'];
      }
    } else {
      if (this.isEditMode) {
        this.isEditMode = false;
        if (!this.locationHasChanges() && this.displayedColumns.length >= 3) {
          this.displayedColumns.pop();
        } else if (!this.hasChanges) {
          this.displayedColumns = ['invoicetype', 'current'];
        }
      }
    }
  }

  private createRequestForm(): void {
    const dataSourceInvoiceFiltered: Array<any> = this.dataSourceInvoice.data.filter((i) => !i.isHeader);
    this.form = new UntypedFormGroup(
      dataSourceInvoiceFiltered.reduce((acc, curr) => {
        acc[curr.key + 'MadCd'] = new UntypedFormControl(
          {
            value: curr.changes
              ? curr.request?.sendToCustomerLocationFuncId
                ? curr.request?.sendToCustomerLocationFuncId
                : ''
              : !this.locationHasChanges()
              ? curr.request?.invoiceInstruction?.customerLocationFuncId
              : '',
            disabled: !this.locationHasChanges()
              ? false
              : this.statusIsApproveOrRejected ||
                (!curr.request?.sendToCustomerLocationFuncId && !curr.request?.sendToRequestFuncSequenceNbr),
          },
          [this.bill2CustomerLocationValidator.bind(curr.key), this.pendingApprovalBillToValidator(curr)]
        );
        acc[curr.key + 'EffectiveStart'] = new UntypedFormControl(
          {
            value: curr.changes || !this.locationHasChanges() ? new Date() : new Date(),
            disabled: this.statusIsApproveOrRejected,
          },
          curr.changes || !this.locationHasChanges() ? [Validators.required] : []
        );
        acc[curr.key + 'SelfInvoicing'] = new UntypedFormControl({
          value: !this.locationHasChanges()
            ? false
            : !curr.request?.sendToCustomerLocationFuncId && !curr.request?.sendToRequestFuncSequenceNbr,
          disabled: this.statusIsApproveOrRejected,
        });
        if (curr.request?.customerLocation?.customerLocationFunction) {
          const customerLocation = curr.request?.customerLocation?.customerLocationFunction?.find(
            (element) => element?.customerLocationFuncId === curr.request?.sendToCustomerLocationFuncId
          );
          curr.request.madCd = curr.request?.prevBillTo ? curr.request?.prevBillTo?.madCd : customerLocation?.madCd;
        }
        return acc;
      }, {}),
      !this.isChangeRequest ? this.validateAtLeastOneChange : null
    );
    dataSourceInvoiceFiltered.forEach((element) => this.determineSelfInvoicing(element));
    this.form.markAllAsTouched();
  }

  private determineSelfInvoicing(element): void {
    if (
      element.request?.sendToCustomerLocationFuncId === +this.locationId ||
      (this.madCode && this.madCode === element?.request?.madCd)
    ) {
      const madCdControl = this.form.get(`${element.key}MadCd`);
      madCdControl.patchValue('');
      madCdControl.disable();

      const invControl = this.form.controls[element.key + 'SelfInvoicing'];
      invControl.setValue(true);
      this.updateInvoiceLocation({ element: element, customerRequestData: {} });
    }
  }

  validateAtLeastOneChange(control): any {
    const invoiceOptions = [
      'CollectBalDue',
      'CollectCorrected',
      'CollectOriginal',
      'CollectTrueDebtor',
      'PrepaidBalDue',
      'PrepaidCorrected',
      'PrepaidOriginal',
      'PrepaidTrueDebtor',
    ];
    return invoiceOptions.some((option) => {
      return control.get(option + 'SelfInvoicing')?.value || control.get(option + 'MadCd')?.value;
    })
      ? null
      : { atLeastOne: true };
  }

  onMadCdSelected(data): void {
    if (data.customerRequestData) {
      const madCdToCopy = data.customerRequestData?.customerLocation?.customerLocationFunction[0]?.madCd;
      switch (data.element.key) {
        case 'PrepaidOriginal':
          this.prepaidCopyMadCd = madCdToCopy;
          this.collectCopyMadCd = madCdToCopy;
          this.dataSourceInvoice.data
            .filter((i) => !i.isHeader)
            .forEach((invoice) => {
              this.handleSelfInvoicing({ checked: false }, invoice);
              this.copyDown({ customerRequestData: data.customerRequestData, element: invoice });
            });
          break;
        case 'CollectOriginal':
          this.collectCopyMadCd = madCdToCopy;
          this.dataSourceInvoice.data
            .filter((i) => !i.isHeader && i.position > 4)
            .forEach((invoice) => {
              this.handleSelfInvoicing({ checked: false }, invoice);
              this.copyDown({ customerRequestData: data.customerRequestData, element: invoice });
            });
          break;
        default:
          this.copyDown(data);
          break;
      }
    }
  }

  copyDown(data): void {
    this.form.get(data.element.key + 'MadCd').patchValue(data.customerRequestData?.customerLocationFuncId);
    this.updateInvoiceLocation(data);
    const currentTopParentId = this.locationData.customerLocation.customerLocationFunction[0]
      .customerLocationFunctionTopParent?.customerLocationFuncId;
    const currentBill2 = this.dataSourceInvoice.data.find((el) => {
      return el.key === data.element.key;
    }).current.invoiceInstruction?.bil2CustomerLocationFuncId;
    const bill2TopParentId =
      data.customerRequestData?.customerLocation.customerLocationFunction[0].customerLocationFunctionTopParent
        ?.customerLocationFuncId;
    const currentFunctionCd = this.locationData.customerLocation.customerLocationFunction[0].functionCd;
    const bill2FunctionCd = data.customerRequestData?.customerLocation.customerLocationFunction[0].functionCd;

    if (data.customerRequestData) {
      switch (true) {
        case data.customerRequestData?.customerLocation.customerLocationFunction[0].customerLocationFuncId ===
          this.locationData.customerLocationFuncId:
          this.form.controls[data.element.key + 'MadCd'].setErrors({
            sameAsLocation: 'Send Bill-To cannot be the same as the customer.',
          });
          break;
        case data.customerRequestData?.customerLocation.customerLocationFunction[0].customerLocationFuncId ===
          currentBill2:
          this.form.controls[data.element.key + 'MadCd'].setErrors({
            equalCurrent: 'Bill-To cannot be the same as the current Send Bill-To.',
          });
          break;
        case bill2FunctionCd !== CustomerFunctionCd.BILL_TO:
          this.form.controls[data.element.key + 'MadCd'].setErrors({
            onlyBill2: 'Send Bill-To must be a Bill-To customer.',
          });
          break;
        case data.customerRequestData?.customerLocation.customerLocationFunction[0].statusCd !== 'ACTIVE':
          this.form.controls[data.element.key + 'MadCd'].setErrors({
            activeOnly: 'Bill-To must be an Active customer.',
          });
          break;
        default:
          this.form.controls[data.element.key + 'MadCd'].setErrors(null);
          break;
      }
    }
  }

  getErrorMessage(key): string {
    return Object.keys(this.form?.controls[key + 'MadCd'].errors || {}).reduce((acc, curr) => {
      return acc + this.form?.controls[key + 'MadCd'].errors[curr];
    }, '');
  }

  approve(): void {
    if (this.invoiceForm.valid && !this.form.errors) {
      this.confirmApprove().subscribe((resp) => {
        if (resp) {
          this.store.pipe(take(1), select(getRequestedChangeCurrentLine)).subscribe((state) => {
            const line = Object.assign({}, state.requestCustomerLocationFunction[0]);
            const requestPayload = Object.assign({}, this.changeRequestData);
            line.requestContactCustomerRelationship = [];
            requestPayload.requestCustomerLocationFunction = [line];
            requestPayload.requestContactPerson = [];
            line.requestCustomerFunctionAlias = [];

            const invoices = cloneDeep(line.requestInvoicePreference);

            line.requestInvoicePreference = this.saveInvoiceChanges(invoices);

            this.changeRequestService
              .approveInvoiceCustomerRequest(
                requestPayload,
                line.requestInvoicePreference[0]?.statusCd,
                this.invoiceForm.get('comments'),
                'InvoiceInstructions',
                line
              )
              .subscribe((res) => {
                if (res) {
                  this.locationDetailsService.getInvoiceInstructions(+this.locationId).subscribe((response) => {
                    this.snackbar.open({
                      message: 'A requested change has been processed.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    this.exitEditMode();
                  });
                }
              });
          });
        }
      });
    }
  }

  private confirmApprove(): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Update request line',
      content: `This request line was previously <b>Rejected</b>. Would you like to <b>Approve</b> it?.
      <br\><br\>
      NOTE: You can't undo this change.
      `,
      icon: 'help',
      actions: [
        {
          label: 'CONFIRM',
          type: 'primary',
          resultType: 'CLOSE',
          resultAction: true,
          position: 'right',
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CLOSE',
          resultAction: false,
          position: 'left',
        },
      ],
    };

    if (this.statusIsApproveOrRejected) {
      return this.dialog
        .open(DialogComponent, { maxWidth: '600px', data: dialog })
        .afterClosed()
        .pipe(map((resp) => resp.resultAction));
    }

    return of(true);
  }

  saveInvoiceChanges(invoices): RequestInvoicePreference[] {
    const time = moment();
    this.dataSourceInvoice.data
      .filter((i) => !i.isHeader)
      .forEach((invoice) => {
        const billCd = invoice.current?.invoiceInstruction?.billCd,
          invoiceCd = invoice.current?.invoiceInstruction?.invoiceCd;
        if (invoice.changes) {
          invoices.forEach((lineInvoice) => {
            if (lineInvoice.billCd === billCd && lineInvoice.invoiceCd === invoiceCd) {
              lineInvoice.effStartTmst = moment(this.form.value[invoice.key + 'EffectiveStart'])
                .set({
                  hour: time.get('hour'),
                  minute: time.get('minute'),
                  second: time.get('second'),
                })
                .toDate();
              lineInvoice.sendToCustomerLocationFuncId = this.form.get(`${invoice.key}MadCd`)?.value;
            }
          });
        } else {
          const newInvoiceChange = new RequestInvoicePreference();
          newInvoiceChange.billCd = billCd;
          newInvoiceChange.invoiceCd = invoiceCd;
          newInvoiceChange.sendToCustomerLocationFuncId = this.form.get(`${invoice.key}MadCd`)?.value;
          newInvoiceChange.effStartTmst = moment(this.form.value[invoice.key + 'EffectiveStart'])
            .set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: time.get('second'),
            })
            .toDate();
          newInvoiceChange.requestFuncSequenceNbr = '1';
          newInvoiceChange.listActionCd = ActionCd.NO_ACTION;

          invoices.push(newInvoiceChange);
        }
      });
    return invoices;
  }

  reject(): void {
    this.enableValidators();
    if (this.invoiceForm.get('comments').value) {
      this.processRequest('reject', CustomerLineStatusCd.REJECTED);
    }
  }

  hold(): void {
    this.enableValidators();
    if (this.invoiceForm.get('comments').value) {
      this.processRequest('hold', CustomerLineStatusCd.HELD);
    }
  }

  enableValidators(): void {
    this.invoiceForm.controls['comments'].setValidators([
      Validators.required,
      ValidatorHelper.noWhiteSpace(),
      Validators.maxLength(800),
    ]);
    this.invoiceForm.controls['comments'].updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.exitEditMode();
  }

  exitEditMode(): void {
    this.store.dispatch(new SetEditMode({ isEditMode: false }));
  }

  saveInvoices(): void {
    const request = {
      invoiceInstructions: [],
    };

    this.dataSourceInvoice.data
      .filter((i) => {
        const { key } = i;
        return (
          !i.isHeader && (this.form.controls[key + 'MadCd'].value || this.form.controls[key + 'SelfInvoicing'].value)
        );
      })
      .forEach((invoice) => {
        const billCd = invoice.current?.invoiceInstruction?.billCd,
          invoiceCd = invoice.current?.invoiceInstruction?.invoiceCd,
          bill2MadCd = this.form.get(`${invoice.key}MadCd`)?.value,
          useAsEntered = !this.form.value[`${invoice.key}SelfInvoicing`];
        request.invoiceInstructions.push({
          customerLocationFuncId: this.locationId,
          parentBil2CustomerLocationFuncId: this.form.get(`${invoice.key}MadCd`)?.value,
          billCd: billCd,
          invoiceCd: invoiceCd,
          useAsEnteredInd: useAsEntered,
          effectiveStartDateTime: this.form.value[invoice.key + 'EffectiveStart']?.valueOf(),
          effectiveEndDateTime: invoice.current?.invoiceInstruction?.effectiveEndDateTime || 64060531200000,
          lastUpdateRemarks: this.invoiceForm.value.comments,
        });
      });
    this.customerApi.updateInvoiceInstructions(request).subscribe((res) => {
      this.locationDetailsService.getInvoiceInstructions(+this.locationId).subscribe((response) => {
        this.snackbar.open({
          message: 'Invoice instruction changes are saved.',
          status: 'success',
          matConfig: {
            duration: ConstantsService.snackbarDuration,
          },
        });
        this.exitEditMode();
      });
    });
  }

  bill2CustomerLocationValidator(key, control: AbstractControl): any | null {
    const isSelfInvoicing = control?.parent?.controls[key + 'SelfInvoicing'];

    return isSelfInvoicing && !control.value
      ? { required: 'You must enter a MAD code for this invoice instruction or check the self invoice.' }
      : null;
  }

  pendingApprovalBillToValidator(element): ValidatorFn {
    return (control) => {
      return element?.prevBillTo?.statusCd === CustomerLineStatusCd.APPROVED &&
        element?.request?.sendToRequestFuncSequenceNbr
        ? null
        : element?.request?.sendToRequestFuncSequenceNbr && !element?.request?.sendToCustomerLocationFuncId
        ? { pendingBill2: 'Pending bill-to approval.' }
        : null;
    };
  }

  getStatusText(): string {
    const statusCd = this.changeRequestDataCurrentLocation?.requestCustomerLocationFunction[0]
      ?.requestInvoicePreference[0]?.statusCd;
    switch (true) {
      case statusCd === CustomerLineStatusCd.HELD:
        return 'On Hold';
      default:
        return statusCd;
    }
  }

  getStatusColor(): string {
    const statusCd = this.changeRequestDataCurrentLocation?.requestCustomerLocationFunction[0]
      ?.requestInvoicePreference[0]?.statusCd;
    switch (true) {
      case statusCd === CustomerLineStatusCd.HELD:
      case statusCd === CustomerLineStatusCd.SALES_PENDING:
        return 'orange';
      case statusCd === CustomerLineStatusCd.APPROVED:
      case statusCd === CustomerLineStatusCd.SALES_APPROVED:
      case statusCd === CustomerLineStatusCd.CREDIT_REVIEWED:
        return 'green';
      case statusCd === CustomerLineStatusCd.REJECTED:
        return 'red';
      default:
        return '';
    }
  }

  handleSelfInvoicing(event, element): void {
    const control = this.form.controls[element.key + 'MadCd'];
    const invControl = this.form.controls[element.key + 'SelfInvoicing'];
    if (event.checked) {
      control.patchValue('');
      control.disable();
      this.updateInvoiceLocation({ element: element, customerRequestData: {} });

      if (element.current.bill2CustLocation && element.current.invoiceInstruction.useAsEnteredInd) {
        control.setErrors({ alreadySelfInvoice: 'Current and new invoicing instructions cannot be the same.' });
        this.form.setErrors({
          ['alreadySelfInvoice' + element.key]: 'Current and new invoicing instructions cannot be the same.',
        });
      } else {
        ValidatorHelper.removeControlError(this.form, 'alreadySelfInvoice' + element.key);
        control.setErrors(null);
      }
    } else {
      control.enable();
      invControl.setValue(false);
      control.setErrors(null);
    }
  }

  private updateInvoiceLocation(data): void {
    const invoice = this.dataSourceInvoice.data.find((e) => e.position === data.element.position);
    let bill2CustLocation = invoice.request.bill2CustLocation;
    if (bill2CustLocation) {
      bill2CustLocation = data.customerRequestData?.customerLocation;
    } else {
      invoice.request.customerLocation = data.customerRequestData?.customerLocation;
    }
  }

  private processRequest(action: string, status: string): void {
    this.store.pipe(take(1), select(getRequestedChangeCurrentLine)).subscribe((state) => {
      const line = Object.assign({}, state.requestCustomerLocationFunction[0]);
      const requestPayload = Object.assign({}, this.changeRequestData);
      line.requestContactCustomerRelationship = [];
      requestPayload.requestCustomerLocationFunction = [line];
      requestPayload.requestContactPerson = [];
      line.requestCustomerFunctionAlias = [];

      this.changeRequestService
        .rejectHoldInvoiceCustomerRequest(
          requestPayload,
          line.requestInvoicePreference[0]?.statusCd,
          this.invoiceForm.get('comments'),
          action,
          'INVOICE_INSTRUCTIONS',
          status,
          line
        )
        .subscribe((response) => {
          if (response) {
            this.snackbar.open({
              message: 'A requested change has been processed.',
              status: 'success',
              matConfig: {
                duration: ConstantsService.snackbarDuration,
              },
            });
            this.exitEditMode();
          }
        });
    });
  }
}
