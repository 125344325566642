import { Injectable } from '@angular/core';
import { GetInterfaceEmployeeResp, HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  employeesList: Map<string, GetInterfaceEmployeeResp> = new Map();

  constructor(private hrApiService: HumanResourceApiService) {}

  addEmployee(employee: GetInterfaceEmployeeResp): void {
    this.employeesList.set(employee.interfaceEmployee.employeeId, employee);
  }

  getEmployee(id: string): Observable<string> {
    if (this.employeesList.has(id)) {
      return of(this.getName(this.employeesList.get(id)));
    } else {
      return this.hrApiService.getInterfaceEmployee({ employeeId: id }, { loadingOverlayEnabled: false }).pipe(
        switchMap((response) => {
          if (response.data.interfaceEmployee) {
            this.addEmployee(response.data);
            return of(this.getName(response.data));
          }
          return of('Inactive employee');
        })
      );
    }
  }

  getName(employee: GetInterfaceEmployeeResp): string {
    return employee.interfaceEmployee.firstName + ' ' + employee.interfaceEmployee.lastName;
  }
}
