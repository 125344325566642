import { Pipe, PipeTransform } from '@angular/core';
import { CustomerLineStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'requestLineStatusColor',
})
export class RequestLineStatusColorPipe implements PipeTransform {

  transform(statusCd: CustomerLineStatusCd): string {
    switch (true) {
      case statusCd === CustomerLineStatusCd.HELD:
      case statusCd === CustomerLineStatusCd.SALES_PENDING:
        return 'orange';
      case statusCd === CustomerLineStatusCd.APPROVED:
      case statusCd === CustomerLineStatusCd.SALES_APPROVED:
      case statusCd === CustomerLineStatusCd.CREDIT_REVIEWED:
        return 'green';
      case statusCd === CustomerLineStatusCd.REJECTED:
        return 'red';
      default:
        return '';
    }
  }
}
