import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CustomerLocationFunction,
  CustomerRequest,
  GetCustomerResp,
  RequestCustomerLocationFunction,
} from '@xpo-ltl-2.0/sdk-customer';
import {
  CustomerAccountTypeCd,
  CustomerCreditStatusCd,
  CustomerFunctionCd,
  CustomerLineStatusCd,
  CustomerPaymentTermsCd,
  CustomerStatusCd,
  CustomerTypeOfSvcIndicatorCd,
} from '@xpo-ltl/sdk-common';
import { merge } from 'lodash';
import { YesNo, YesNoValue } from 'src/app/change-request-page/models/enums/yes-no';
import { AccountDetails } from 'src/app/location-details-page/company-information/models/account-details';
import { CustomerFunctionCdLabel } from 'src/app/shared/enums/function-cdl.enum';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from '..';
import { AccountStatus, AccountStatusValue } from '../../shared/enums/account-form/account-status.enum';
import { AccountType } from '../../shared/enums/account-form/account-type.enum';
import { Country, CountryValue } from '../../shared/enums/account-form/country.enum';
import { CreditStatus, CreditStatusValue } from '../../shared/enums/account-form/credit-status.enum';
import { PaymentTerms, PaymentTermsValue } from '../../shared/enums/account-form/payment-terms.enum';
import {
  getChangeRequest,
  getChangeRequestChangesOnlyData,
  getRequestedChangeCurrentLine,
} from '../change-request/change-request.selectors';
import {
  getAccountAlias,
  getAccountContact,
  getLocationData,
  getShowChangesOnly,
} from '../location/location.selectors';
import { getLoggedInUserRole } from '../user/user.selectors';
import { DetailsState } from './details.reducer';
import { getNewLocationSchema } from './new-location-details';

export const getCurrentCountry = createFeatureSelector<AppState, DetailsState>('details');
export const getEditMode = createFeatureSelector<AppState, DetailsState>('details');
export const getChangesRequestCount = createFeatureSelector<AppState, DetailsState>('details');

export const getBusinessNameAliases = createSelector(
  getAccountAlias,
  getShowChangesOnly,
  getRequestedChangeCurrentLine,
  (aliases, isChangesOnly, currentCrData) => {
    const mergedCrData = (currentCrData.requestCustomerLocationFunction[0].requestCustomerFunctionAlias || []).reduce(
      (acc, rc) => {
        let toRet;
        if (rc.actionCd !== 'ADD') {
          const oldData = aliases.find((a) => a.sequenceNbr === rc.existngAliasSequenceNbr);
          toRet = merge({}, rc, oldData);
        } else {
          toRet = rc;
        }
        return acc.concat(toRet);
      },
      []
    );
    if (isChangesOnly) {
      return mergedCrData;
    } else {
      return [...mergedCrData, ...aliases];
    }
  }
);

export const getContacts = createSelector(
  getAccountContact,
  getShowChangesOnly,
  getRequestedChangeCurrentLine,
  getChangeRequest,
  (contacts, isChangesOnly, currentCrData, changeRequest) => {
    const mergedCrData = (
      currentCrData.requestCustomerLocationFunction[0]?.requestContactCustomerRelationship || []
    ).reduce((acc, rc) => {
      let toRet;

      if (rc.actionCd === 'ADD' || rc.actionCd === 'DELETE') {
        toRet = merge(
          {},
          changeRequest.changeRequest.requestContactPerson.find(
            (c) => c.requestContactPersonSequenceNbr === +rc.requestContactPersonSequenceNbr
          ),
          rc
        );
      } else {
        const currentContact = contacts.find(
          (c) =>
            c.contactPersonId ===
            changeRequest.changeRequest.requestContactPerson[+rc.requestContactPersonSequenceNbr - 1].contactPersonId
        );
        toRet = merge(
          {},
          rc,
          changeRequest.changeRequest.requestContactPerson[+rc.requestContactPersonSequenceNbr - 1],
          currentContact,
          currentContact.contactPerson
        );
      }

      return acc.concat({ ...toRet.contactPerson, ...toRet });
    }, []);
    if (isChangesOnly) {
      return mergedCrData;
    } else {
      return [...mergedCrData, ...contacts.map((contact) => ({ ...contact.contactPerson, ...contact }))];
    }
  }
);

export const getCurrentContactChanges = createSelector(
  getChangeRequest,
  getAccountContact,
  getRequestedChangeCurrentLine,
  getLocationData,
  (changeRequest, contacts, currentLocChanges, locData, { currentContactId, sequenceNbr }) => {
    const contactChanges = merge(
      {},
      contacts.find((c) => c.contactPersonId === currentContactId),
      changeRequest.changeRequest.requestContactPerson.find((c) => c.requestContactPersonSequenceNbr === sequenceNbr),
      currentLocChanges.requestCustomerLocationFunction[0]?.requestContactCustomerRelationship?.find(
        (r) => r.requestContactPersonSequenceNbr === sequenceNbr
      )
    );

    contactChanges.requestNote = getContactNotes(currentLocChanges, sequenceNbr);
    contactChanges.contactNote = contactChanges?.requestContactNote
      ? contactChanges.requestContactNote
      : locData.cstContactNotes
          ?.slice()
          .reverse()
          .find((n) => n.contactPersonId === currentContactId);

    return contactChanges;
  }
);

const getContactNotes = (changeRequest, sequenceNbr) => {
  return changeRequest.requestContactPerson?.find((c) => c.requestContactPersonSequenceNbr === sequenceNbr)
    ?.requestNote;
};

export const getTotalChangesCount = createSelector(getChangeRequestChangesOnlyData, (state) => {
  return state.requestCustomerLocationFunction.reduce((acc, loc) => {
    let accountChanges = 0,
      totalChangesCount = 0,
      totalContactChanges = 0;
    if (loc.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS) {
      const schema = loc.customerLocationFuncId
        ? getLocationSchema(
            {
              customerLocation: {
                party1: {},
              },
              functionCd: loc.functionCd,
              govtCustomerInd: false,
              restrictChargeInd: false,
            } as any,
            {
              customerLocation: {
                party1: {},
                customerAddress: {},
              },
            } as any
          ).map((p) => mapPropertyValues(p, loc))
        : getNewLocationSchema(loc).map((p) => mapPropertyValuesForNewLocation(p, loc));
      accountChanges = schema.filter((p) => p.requestedChange).length;
      const remarkChange = loc.requestNote?.filter((n) => n.noteForCd === 'FunctionRemark');
      if (remarkChange.length) {
        accountChanges += 1;
      }
    }

    totalChangesCount += accountChanges + acc;

    totalChangesCount += loc.requestCustomerFunctionAlias
      ? loc.requestCustomerFunctionAlias.filter((a) => a.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length
      : 0;

    totalChangesCount += loc.requestInvoicePreference
      ? loc.requestInvoicePreference.filter((a) => a.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length
      : 0;

    totalContactChanges = loc.requestContactCustomerRelationship?.length || 0;

    if (totalContactChanges > 0) {
      const validContactsCount = state.requestContactPerson
        .filter(({ requestContactPersonSequenceNbr: id1 }) =>
          loc.requestContactCustomerRelationship.some(({ requestContactPersonSequenceNbr: id2 }) => id1 === Number(id2))
        )
        .filter((c) => c.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length;

      totalChangesCount += validContactsCount;
    }

    return totalChangesCount;
  }, 0);
});

export const getChangesCountForCurrentLocation = createSelector(
  getChangeRequestChangesOnlyData,
  (state: CustomerRequest, { locationId, seqNbr }) => {
    let totalChangesCount = 0;
    let totalContactChanges = 0;

    if (state) {
      const currentLocations = state.requestCustomerLocationFunction.filter((l) =>
        l.customerLocationFuncId ? l.customerLocationFuncId === locationId : l.requestFuncSequenceNbr === seqNbr
      );

      currentLocations.forEach((loc) => {
        if (loc.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS) {
          const locationSchema = locationId
            ? getLocationSchema(
                {
                  customerLocation: {
                    party1: {},
                  },
                  functionCd: loc.functionCd,
                  govtCustomerInd: false,
                  restrictChargeInd: false,
                } as any,
                {
                  customerLocation: {
                    party1: {},
                    customerAddress: {},
                  },
                } as any
              ).map((p) => mapPropertyValues(p, loc))
            : getNewLocationSchema(loc).map((p) => mapPropertyValuesForNewLocation(p, loc));

          totalChangesCount += locationSchema.filter((p) => p.requestedChange).length;
          const remarkChange = loc.requestNote?.filter((n) => n.noteForCd === 'FunctionRemark');
          if (remarkChange.length) {
            totalChangesCount += 1;
          }
        }

        totalChangesCount += loc.requestCustomerFunctionAlias
          ? loc.requestCustomerFunctionAlias.filter((a) => a.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length
          : 0;

        totalChangesCount += loc.requestInvoicePreference
          ? loc.requestInvoicePreference.filter((a) => a.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length
          : 0;

        totalContactChanges = loc.requestContactCustomerRelationship?.length || 0;

        if (totalContactChanges > 0) {
          const validContactsCount = state.requestContactPerson
            .filter(({ requestContactPersonSequenceNbr: id1 }) =>
              loc.requestContactCustomerRelationship.some(
                ({ requestContactPersonSequenceNbr: id2 }) => id1 === Number(id2)
              )
            )
            .filter((c) => c.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS).length;

          totalChangesCount += validContactsCount;
        }
      });
    }

    return totalChangesCount;
  }
);

export const getAccountDetails = createSelector(
  getLocationData,
  getRequestedChangeCurrentLine,
  getShowChangesOnly,
  (locData, currentLocCrData, showChangesOnly) => {
    let accountDetails;

    if (
      currentLocCrData &&
      locData &&
      locData?.customerLocationFuncId === currentLocCrData?.requestCustomerLocationFunction[0]?.customerLocationFuncId
    ) {
      accountDetails = getLocationSchema(locData.customerLocation.customerLocationFunction[0], locData, true).map((p) =>
        mapPropertyValues(p, currentLocCrData.requestCustomerLocationFunction[0])
      );
    } else {
      accountDetails = [];
    }

    return { data: accountDetails, showChangesOnly: showChangesOnly, locationData: locData };
  }
);

export const getAccountDetailsForOutsideView = createSelector(
  getLocationData,
  getLoggedInUserRole,
  (locData, loggedInUserRole) => {
    return getLocationSchema(locData.customerLocation.customerLocationFunction[0], locData, false, loggedInUserRole);
  }
);

export const getCurrentCountrySelected = createSelector(getCurrentCountry, (state: DetailsState) => {
  return state.currentCountrySelected;
});

export const getIsEditMode = createSelector(getEditMode, (state) => state.isEditMode);

export const getIsProcessingBulkDelete = createSelector(getEditMode, (state) => state.isProcessingBulkDelete);

export const getChangesCount = createSelector(getChangesRequestCount, (state) => state.changesRequestCount);

export const getNewLocationAccountDetails = createSelector(getRequestedChangeCurrentLine, (crData) => {
  return getNewLocationSchema(crData?.requestCustomerLocationFunction[0]).map((p) =>
    mapPropertyValuesForNewLocation(p, crData?.requestCustomerLocationFunction[0])
  );
});

export const getNewLocationAccountDetailsOutsideRequest = createSelector(
  getRequestedChangeCurrentLine,
  (crData, { newLoc }) => {
    return getNewLocationSchema(newLoc).map((p) => mapPropertyValuesForNewLocation(p, newLoc));
  }
);

function mapPropertyValues(property: AccountDetails, crData: RequestCustomerLocationFunction): AccountDetails {
  let requestedValue;
  if (property.isHeader) {
    return property;
  }

  if (property.key.includes('zip')) {
    requestedValue = crData['zipCd'] + crData['zip4Cd'] || undefined;
  } else {
    requestedValue = crData[property.key];
  }

  if (requestedValue !== undefined) {
    switch (true) {
      case property.key === 'acctTypeCd':
      case property.key === 'creditStatusCd':
      case property.key === 'paymentTermCd':
        property.value = requestedValue;
        break;
      case property.key === 'govtCustomerInd':
      case property.key === 'restrictChargeInd':
      case property.key === 'pricingInd':
        const parsedValue = YesNo[requestedValue.toString().toUpperCase()];
        property.requestedValue = parsedValue || requestedValue;
        break;
      case property.key === 'affiliateInd':
        property.requestedValue = requestedValue ? 'Yes' : 'No';
        break;
      case property.key.includes('zip'):
        const [currentZipCd, currentZip4Cd] = (property.currentValue || '').split('-');
        const zipCd = crData['zipCd'] || currentZipCd;
        const zip4Cd = crData['zip4Cd'] === undefined ? currentZip4Cd : crData['zip4Cd'];
        property.requestedValue = zip4Cd ? `${zipCd}-${zip4Cd}` : zipCd;
        break;
      case property.key === 'customerStatusCd':
        property.requestedValue = requestedValue.toUpperCase();
        break;
      case property.key === 'parentCorpMadCd':
        property.requestedValue = {
          id: crData['parentCorpCustomerLocationFuncId'],
          madCd:
            crData[property.key] === '' && crData.newParentCorpLineSequenceNbr
              ? 'Parent pending approval'
              : crData[property.key],
        } as any;
        break;
      case property.key === 'bankruptInd':
        property.requestedValue = requestedValue;
        break;
      case property.key === 'serviceRecipientNbr':
        property.requestedValue = property.requestedValue === 0 ? '' : property.requestedValue;
        break;
      default:
        property.requestedValue = requestedValue.toString();
        break;
    }
    property['requestedChange'] = true;
  } else {
    if (property.key === 'parentCorpMadCd') {
      if (property.currentValue) {
        property.requestedValue = {
          madCd: property.currentValue,
          id: property.value,
        };
      }
      if (
        crData.actionCd === 'UPDATE' &&
        crData.statusCd !== CustomerLineStatusCd.APPROVED &&
        crData.newParentCorpLineSequenceNbr &&
        !crData.parentCorpMadCd
      ) {
        property.requestedValue = {
          id: '',
          madCd: 'Parent pending approval',
        } as any;
        property['requestedChange'] = true;
      }
    } else {
      property.requestedValue = property.currentValue;
    }
  }

  return property;
}

function mapPropertyValuesForNewLocation(
  property: AccountDetails,
  crData: RequestCustomerLocationFunction
): AccountDetails {
  // create new function because new locations have different scenarios
  const requestedValue = crData[property.key];
  if (property.isHeader) {
    return property;
  }

  if (requestedValue !== undefined) {
    switch (property.key) {
      case 'acctTypeCd':
      case 'creditStatusCd':
      case 'paymentTermCd':
      case 'bankruptInd':
        property.value = requestedValue;
        break;
      case 'govtCustomerInd':
      case 'restrictChargeInd':
      case 'pricingInd':
      case 'affiliateInd':
        const parsedValue = YesNo[requestedValue.toString().toUpperCase()];
        property.requestedValue = parsedValue || requestedValue;
        break;
      case 'customerStatusCd':
        property.requestedValue = crData?.madCd ? crData?.customerStatusCd : 'PENDING APPROVAL';
        break;
      case 'functionCd':
        property.requestedValue = CustomerFunctionCdLabel[requestedValue];
        break;
      case 'parentCorpCustomerLocationFuncId':
        property.requestedValue = {
          madCd: crData['parentCorpMadCd'],
          id: crData[property.key],
        };
        break;
      case 'serviceRecipientNbr':
        property.requestedValue = property.requestedValue === 0 ? '' : property.requestedValue;
        break;
      default:
        property.requestedValue = requestedValue.toString();
        break;
    }
    property['requestedChange'] = true;
  } else {
    if (property.key === 'parentCorpCustomerLocationFuncId') {
      if (
        crData.statusCd !== CustomerLineStatusCd.APPROVED &&
        crData.newParentCorpLineSequenceNbr &&
        !crData.parentCorpMadCd
      ) {
        property.requestedValue = {
          id: '',
          madCd: 'Parent pending approval',
        } as any;
      }
    }
  }

  return property;
}

function defaultPaymentTerm(creditStatus: CustomerCreditStatusCd): CustomerPaymentTermsCd {
  switch (creditStatus) {
    case CustomerCreditStatusCd.NO_CREDIT:
      return CustomerPaymentTermsCd.IMMEDIATE;
    case CustomerCreditStatusCd.CREDIT:
      return CustomerPaymentTermsCd.TERM_15_DAYS;
    default:
      return CustomerPaymentTermsCd.TERM_15_DAYS;
  }
}

function getLocationSchema(
  currentLoc: CustomerLocationFunction,
  getCustResp: GetCustomerResp,
  isChangeRequest = false,
  loggedInUserRole = UserRole.Coder
): AccountDetails[] {
  let accountDetails;
  const isCTSMadCode = currentLoc.madCd?.substring(5, 8)?.toUpperCase() === 'CTS';

  switch (currentLoc.functionCd) {
    case CustomerFunctionCd.CORPORATE:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'STATUS',
          currentValue: currentLoc.statusCd,
          key: 'customerStatusCd',
          required: true,
          type: 'select',
          options: [
            { label: AccountStatus.active, value: AccountStatusValue.active },
            { label: AccountStatus.expired, value: AccountStatusValue.expired },
          ],
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ACCOUNT TYPE',
          currentValue: AccountType[currentLoc.acctTypeCd],
          value: currentLoc.acctTypeCd,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'GOVERNMENT',
          currentValue: currentLoc.govtCustomerInd ? 'Yes' : 'No',
          key: 'govtCustomerInd',
          required: true,
          type: 'select',
          options: [
            { label: YesNo.TRUE, value: YesNo.TRUE },
            { label: YesNo.FALSE, value: YesNo.FALSE },
          ],
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.notBothValidator('affiliateInd', 'Cannot have Government and Affiliate enabled.'),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'AFFILIATE',
          currentValue: currentLoc.affiliateInd ? 'Yes' : 'No',
          key: 'affiliateInd',
          required: true,
          type: 'select',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.notBothValidator('govtCustomerInd', 'Cannot have Government and Affiliate enabled.'),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'PARENT',
          currentValue: currentLoc.customerLocationFunctionParent?.madCd,
          customerNbr: currentLoc.customerLocationFunctionParent?.customerLocationFuncId || '',
          key: 'parentCorpMadCd',
          required: false,
          type: 'parent',
          editable: true,
          value: currentLoc.customerLocationFunctionParent?.customerLocationFuncId,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'SALES TERRITORY',
          currentValue: getCustResp?.customerSalesTerritoryAssign?.salesTerritoryCd,
          key: 'salesTerritoryCd',
          type: 'text',
          editable: true,
          required: false,
          validators: [ValidatorHelper.maxLength(5, 'Must contain less than 6 characters.')],
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          currentValue: getCustResp.customerLocation.party1.partyName,
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          currentValue: getCustResp.customerLocation.party2
            ? '% ' + getCustResp.customerLocation.party2.partyName
            : getCustResp.customerLocation.nameExt || '',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ADDRESS',
          currentValue: getCustResp.customerLocation.customerAddress.address,
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          currentValue: getCustResp.customerLocation.customerAddress.cityName,
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          currentValue: getCustResp.customerLocation.customerAddress.stateCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [ValidatorHelper.required('State is required.')],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'POSTAL CODE',
          currentValue:
            getCustResp.customerLocation.customerAddress.countryCd === 'US'
              ? getCustResp.customerLocation.customerAddress.zip4Cd
                ? `${getCustResp.customerLocation.customerAddress.zipCd}-${getCustResp.customerLocation.customerAddress.zip4Cd}`
                : getCustResp.customerLocation.customerAddress.zipCd
              : getCustResp.customerLocation.customerAddress.zipCd,
          key: 'zipCd',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'COUNTRY',
          currentValue: getCustResp.customerLocation.customerAddress.countryCd,
          key: 'countryCd',
          required: true,
          type: 'select',
          validators: [ValidatorHelper.required('Country is required.')],
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          currentValue: currentLoc?.inheritCd === 'C' || currentLoc?.inheritCd === 'B',
          key: 'inheritCd',
          required: true,
          type: 'checkbox',
          editable: true,
          disable: false,
          excludeFromRequest: false,
          financial: true,
        },
        {
          name: 'BANKRUPT',
          currentValue: currentLoc.bankruptInd,
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          disable: currentLoc?.inheritCd === 'C' ? true : false,
        },
        {
          name: 'CREDIT STATUS',
          currentValue: CreditStatus[currentLoc.creditStatusCd],
          value: currentLoc.creditStatusCd,
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
          disable: currentLoc?.inheritCd === 'C' ? true : false,
        },
        {
          name: 'CREDIT LIMIT',
          currentValue: currentLoc.authrzLimitAmount,
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.creditLimitValidator(currentLoc?.creditStatusCd),
          ],
          disable: currentLoc?.inheritCd === 'C' ? true : false,
          financial: true,
        },
        {
          name: 'HRC ID',
          currentValue: currentLoc.authrzNbr,
          key: 'authorizationNbr',
          required: false,
          type: 'text',
          editable: true,
          validators:
            CreditStatus[currentLoc.creditStatusCd] === CreditStatusValue.Credit
              ? [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')]
              : [],
          disable: false,
          financial: true,
        },
        {
          name: 'PAYMENT TERMS',
          currentValue:
            PaymentTerms[currentLoc.paymentTermCd] ||
            (currentLoc.statusCd === 'EXPIRED' ? PaymentTerms[defaultPaymentTerm(currentLoc?.creditStatusCd)] : ''),
          value:
            currentLoc.paymentTermCd ||
            (currentLoc.statusCd === 'EXPIRED' ? defaultPaymentTerm(currentLoc?.creditStatusCd) : ''),
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          disable: currentLoc?.inheritCd === 'C' ? true : false,
          validators: [ValidatorHelper.required('Enter value for required field.')],
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          currentValue: currentLoc?.inheritCd === 'D' || currentLoc?.inheritCd === 'B',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !currentLoc?.customerLocationFunctionParent,
          excludeFromRequest: true,
          financial: true,
        },
        {
          name: 'DUNS',
          currentValue: currentLoc.dbCustomerNbr,
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
          financial: true,
        },
        {
          name: 'NAICS CODE',
          currentValue: currentLoc.standardIndlCd,
          required: false,
          type: 'text',
          key: 'standardIndlCd',
          editable: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          financial: true,
        },
        {
          name: 'TAX ID',
          excludeFromRequest: true,
          currentValue: currentLoc?.taxId,
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
      ];
      break;
    case CustomerFunctionCd.BILL_TO:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'STATUS',
          currentValue: currentLoc.statusCd,
          key: 'customerStatusCd',
          required: true,
          type: 'select',
          options: [
            { label: AccountStatus.active, value: AccountStatusValue.active },
            { label: AccountStatus.inactive, value: AccountStatusValue.inactive },
            { label: AccountStatus.expired, value: AccountStatusValue.expired },
          ],
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ACCOUNT TYPE',
          currentValue: AccountType[currentLoc.acctTypeCd],
          value: currentLoc.acctTypeCd,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.InheritFromParent, value: CustomerAccountTypeCd.INHERIT_FROM_PARENT },
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: true,
        },
        {
          name: 'RESTRICTED',
          currentValue:
            YesNo[
              (getCustResp?.serviceRecipient?.typeOfServiceInd === CustomerTypeOfSvcIndicatorCd.RESTRICTED)
                .toString()
                .toUpperCase()
            ],
          key: 'restrictChargeInd',
          required: true,
          type: 'select',
          options: [
            { label: YesNo.TRUE, value: YesNoValue.true },
            { label: YesNo.FALSE, value: YesNoValue.false },
          ],
          editable: false,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'PARENT',
          currentValue: currentLoc.customerLocationFunctionParent?.madCd,
          customerNbr: currentLoc.customerLocationFunctionParent?.customerLocationFuncId || '',
          key: 'parentCorpMadCd',
          required: false,
          type: 'parent',
          editable: true,
          value: currentLoc.customerLocationFunctionParent?.customerLocationFuncId,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          currentValue: getCustResp.customerLocation.party1.partyName,
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          currentValue: getCustResp.customerLocation.party2
            ? '% ' + getCustResp.customerLocation.party2.partyName
            : getCustResp.customerLocation.nameExt || '',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable:
            !!getCustResp?.serviceRecipient?.serviceRecipientNbr ||
            (!isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst),
        },
        {
          name: 'ADDRESS',
          currentValue: getCustResp.customerLocation.customerAddress.address,
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          currentValue: getCustResp.customerLocation.customerAddress.cityName,
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          currentValue: getCustResp.customerLocation.customerAddress.stateCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [ValidatorHelper.required('State is required.')],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'POSTAL CODE',
          currentValue:
            getCustResp.customerLocation.customerAddress.countryCd === 'US'
              ? getCustResp.customerLocation.customerAddress.zip4Cd
                ? `${getCustResp.customerLocation.customerAddress.zipCd}-${getCustResp.customerLocation.customerAddress.zip4Cd}`
                : getCustResp.customerLocation.customerAddress.zipCd
              : getCustResp.customerLocation.customerAddress.zipCd,
          key: 'zipCd',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'COUNTRY',
          currentValue: getCustResp.customerLocation.customerAddress.countryCd,
          key: 'countryCd',
          required: true,
          type: 'select',
          validators: [ValidatorHelper.required('Country is required.')],
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          currentValue: !!currentLoc?.customerLocationFunctionParent,
          key: 'inheritCd',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
          excludeFromRequest: false,
        },
        {
          name: 'BANKRUPT',
          currentValue: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.bankruptInd
            : currentLoc.bankruptInd,
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          disable: currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'CREDIT STATUS',
          currentValue: CreditStatus[currentLoc.creditStatusCd],
          value: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.creditStatusCd
            : currentLoc.creditStatusCd,
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'CREDIT LIMIT',
          currentValue: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.authrzLimitAmount
            : currentLoc.authrzLimitAmount,
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.creditLimitValidator(currentLoc?.creditStatusCd),
            ValidatorHelper.spacesFormatValidator(),
          ],
          financial: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'HRC ID',
          currentValue: currentLoc.authrzNbr,
          key: 'authorizationNbr',
          required: CreditStatus[currentLoc.creditStatusCd] === CreditStatusValue.Credit,
          type: 'text',
          editable: true,
          validators:
            CreditStatus[currentLoc.creditStatusCd] === CreditStatusValue.Credit
              ? [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')]
              : [],
          financial: true,
          disable: false,
        },
        {
          name: 'PAYMENT TERMS',
          currentValue:
            PaymentTerms[currentLoc.paymentTermCd] ||
            (currentLoc.statusCd === 'EXPIRED' ? PaymentTerms[defaultPaymentTerm(currentLoc?.creditStatusCd)] : ''),
          value: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.paymentTermCd
            : currentLoc.paymentTermCd ||
              (currentLoc.statusCd === 'EXPIRED' ? defaultPaymentTerm(currentLoc?.creditStatusCd) : ''),
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
          validators: [ValidatorHelper.required('Enter value for required field.')],
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          currentValue: currentLoc?.inheritCd === 'D' || currentLoc?.inheritCd === 'B',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          financial: true,
          disable: !currentLoc?.customerLocationFunctionParent,
          excludeFromRequest: true,
        },
        {
          name: 'DUNS',
          currentValue: currentLoc.dbCustomerNbr,
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
        },
        {
          name: 'NAICS CODE',
          currentValue: currentLoc.standardIndlCd,
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          key: 'standardIndlCd',
        },
        {
          name: 'TAX ID',
          excludeFromRequest: true,
          currentValue: currentLoc?.taxId,
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
        { name: 'Service Recipient', isHeader: true },
        {
          name: 'SERVICE RECIPIENT NBR',
          currentValue: getCustResp.serviceRecipient ? getCustResp.serviceRecipient.serviceRecipientNbr : '',
          key: 'serviceRecipientNbr',
          type: 'service-recipient',
          required: false,
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'TYPE',
          currentValue: getCustResp.serviceRecipient ? getCustResp.serviceRecipient.typeOfServiceInd : '',
          key: 'typeOfSvcInd',
        },
        {
          name: 'NAME',
          currentValue: getCustResp.serviceRecipient ? getCustResp.serviceRecipient.serviceRecipientName : '',
          key: 'svcRcpntNm',
        },
      ];
      break;
    case CustomerFunctionCd.PICKUP_OR_DELIVERY:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'STATUS',
          currentValue: currentLoc.statusCd,
          key: 'customerStatusCd',
          required: true,
          type: 'select',
          options: [
            { label: AccountStatus.active, value: AccountStatusValue.active },
            { label: AccountStatus.inactive, value: AccountStatusValue.inactive },
            { label: AccountStatus.expired, value: AccountStatusValue.expired },
          ],
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'MERGE TO',
          key: 'mergedToMadCd',
          required: false,
          type: 'text',
          editable: false,
          requestOnly: true,
        },
        {
          name: 'ACCOUNT TYPE',
          currentValue: AccountType[currentLoc.acctTypeCd],
          value: currentLoc.acctTypeCd,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.InheritFromParent, value: CustomerAccountTypeCd.INHERIT_FROM_PARENT },
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: true,
        },
        {
          name: 'PARENT',
          currentValue: currentLoc.customerLocationFunctionParent?.madCd,
          customerNbr: currentLoc.customerLocationFunctionParent?.customerLocationFuncId || '',
          key: 'parentCorpMadCd',
          required: false,
          type: 'parent',
          editable: true,
          value: currentLoc.customerLocationFunctionParent?.customerLocationFuncId,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'SALES TERRITORY',
          currentValue: getCustResp?.customerSalesTerritoryAssign?.salesTerritoryCd,
          key: 'salesTerritoryCd',
          type: 'text',
          editable: true,
          required: false,
          validators: [ValidatorHelper.maxLength(5, 'Must contain less than 6 characters.')],
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          currentValue: getCustResp.customerLocation.party1.partyName,
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          currentValue: getCustResp.customerLocation.party2
            ? '% ' + getCustResp.customerLocation.party2.partyName
            : getCustResp.customerLocation.nameExt || '',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ADDRESS',
          currentValue: getCustResp.customerLocation.customerAddress.address,
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.isPoBox(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          currentValue: getCustResp.customerLocation.customerAddress.cityName,
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          currentValue: getCustResp.customerLocation.customerAddress.stateCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [
            ValidatorHelper.required('State is required.'),
            ValidatorHelper.valueChangedValidator(
              false,
              getCustResp.customerLocation.customerAddress.stateCd,
              'Cannot change State/Province on a P&D.'
            ),
          ],
          disable: isChangeRequest ? loggedInUserRole === UserRole.CreditAnalyst : true,
        },
        {
          name: 'POSTAL CODE',
          currentValue:
            getCustResp.customerLocation.customerAddress.countryCd === 'US'
              ? getCustResp.customerLocation.customerAddress.zip4Cd
                ? `${getCustResp.customerLocation.customerAddress.zipCd}-${getCustResp.customerLocation.customerAddress.zip4Cd}`
                : getCustResp.customerLocation.customerAddress.zipCd
              : getCustResp.customerLocation.customerAddress.zipCd,
          key: 'zipCd',
          country: getCustResp.customerLocation.customerAddress.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'COUNTRY',
          currentValue: getCustResp.customerLocation.customerAddress.countryCd,
          key: 'countryCd',
          required: true,
          type: 'select',
          validators: [ValidatorHelper.required('Country is required.')],
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          disable: !isChangeRequest && loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          currentValue: !!currentLoc?.customerLocationFunctionParent,
          key: 'inheritCd',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
          excludeFromRequest: false,
        },
        {
          name: 'BANKRUPT',
          currentValue: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.bankruptInd
            : currentLoc.bankruptInd,
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          disable: currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'CREDIT STATUS',
          currentValue: CreditStatus[currentLoc.creditStatusCd],
          value: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.creditStatusCd
            : currentLoc.creditStatusCd,
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'CREDIT LIMIT',
          currentValue: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.authrzLimitAmount
            : currentLoc.authrzLimitAmount,
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          financial: true,
          validators: [
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.creditLimitValidator(currentLoc?.creditStatusCd),
          ],
          disable: !!currentLoc?.customerLocationFunctionParent,
        },
        {
          name: 'HRC ID',
          currentValue: currentLoc.authrzNbr,
          key: 'authorizationNbr',
          required: CreditStatus[currentLoc.creditStatusCd] === CreditStatusValue.Credit,
          type: 'text',
          editable: true,
          financial: true,
          validators:
            CreditStatus[currentLoc.creditStatusCd] === CreditStatusValue.Credit
              ? [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')]
              : [],
          disable: false,
        },
        {
          name: 'PAYMENT TERMS',
          currentValue:
            PaymentTerms[currentLoc.paymentTermCd] ||
            (currentLoc.statusCd === 'EXPIRED' ? PaymentTerms[defaultPaymentTerm(currentLoc?.creditStatusCd)] : ''),
          value: currentLoc?.customerLocationFunctionParent
            ? currentLoc?.customerLocationFunctionParent?.paymentTermCd
            : currentLoc.paymentTermCd ||
              (currentLoc.statusCd === 'EXPIRED' ? defaultPaymentTerm(currentLoc?.creditStatusCd) : ''),
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          disable: !!currentLoc?.customerLocationFunctionParent,
          validators: [ValidatorHelper.required('Enter value for required field.')],
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          currentValue: currentLoc?.inheritCd === 'D' || currentLoc?.inheritCd === 'B',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          financial: true,
          disable: !currentLoc?.customerLocationFunctionParent,
          excludeFromRequest: true,
        },
        {
          name: 'DUNS',
          currentValue: currentLoc.dbCustomerNbr,
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
        },
        {
          name: 'NAICS CODE',
          currentValue: currentLoc.standardIndlCd,
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          key: 'standardIndlCd',
        },
        {
          name: 'TAX ID',
          excludeFromRequest: true,
          currentValue: currentLoc?.taxId,
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
      ];
      break;
  }
  return accountDetails;
}
