import { YesNo, YesNoAbbreviated } from 'src/app/change-request-page/models/enums/yes-no';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { merge } from 'lodash';
import { AppState } from '..';
import { CustomerStatus } from '../../change-request-page/models/enums/customer-status';
import { LocationState } from './location.reducer';

export const getLocation = createFeatureSelector<AppState, LocationState>('customerLocation');

export const getLocationData = createSelector(getLocation, (locationState: LocationState) => locationState.customerLocation);

export const getLocationCardStatus = createSelector(getLocation, (locationState: LocationState) => locationState.cardStatus);

export const getLocationId = createSelector(getLocation, (locationState: LocationState) => locationState.locationId);

export const getShowChangesOnly = createSelector(getLocation, (locationState: LocationState) => locationState.showChangesOnly);

export const getLocalContacts = createSelector(getLocation, (locationState: LocationState) => locationState.localContacts);

export const getTypeLocation = createSelector(getLocation, (locationState: LocationState) => {
  return locationState.customerLocation && locationState.customerLocation.customerLocation.customerLocationFunction[0]
    ? locationState.customerLocation.customerLocation.customerLocationFunction[0].functionCd
    : null;
});

export const getMadCode = createSelector(getLocation, (locationState: LocationState) => {
  return locationState.customerLocation && locationState.customerLocation.customerLocation.customerLocationFunction[0]
    ? locationState.customerLocation.customerLocation.customerLocationFunction[0].madCd
    : null;
});

export const getAccountInfo = createSelector(getLocation, (locationState: LocationState) => {
  if (locationState.customerLocation && locationState.customerLocation.customerLocation.customerLocationFunction[0]) {
    const {
      legacyCisCustomerNbr,
      madCd,
      functionCd,
      statusCd,
      customerLocationFuncId,
      acctTypeCd,
      pricingInd,
      govtCustomerInd,
    } = locationState.customerLocation.customerLocation.customerLocationFunction[0];
    const territory = locationState.customerLocation.customerSalesTerritoryAssign;
    const customerAddress = locationState.customerLocation.customerLocation.customerAddress.address;
    const customerParty = locationState.customerLocation.customerLocation.party1.partyName;
    const cisPartyName2 = locationState.customerLocation.customerLocation.party2
      ? locationState.customerLocation.customerLocation.party2.partyName
      : '';

    return (
      {
        cisNumber: legacyCisCustomerNbr || 0,
        customerLocationFuncId: customerLocationFuncId || 0,
        madCode: madCd,
        locationType: functionCd,
        address: customerAddress,
        party: customerParty,
        party2: cisPartyName2,
        status: CustomerStatus[statusCd] || CustomerStatus.ACTIVE,
        customerId: legacyCisCustomerNbr.toString(),
        accountType: acctTypeCd,
        territorySalesPerson: territory && territory.salesTerritoryCd,
        pricing: YesNo[pricingInd.toString().toUpperCase()],
        govLocation: YesNoAbbreviated[govtCustomerInd.toString().toUpperCase()],
        OBIProgram: '', // TODO
        billToBillTo: '', // TODO
        openAr: '', // TODO
        lastMatched: '', // TODO
      } || {}
    );
  }
});

export const getAccountAlias = createSelector(getLocation, (locationState: LocationState) => {
  return locationState && locationState.customerLocation
    ? locationState.customerLocation.customerLocation.customerLocationFunction[0].customerLocationFuncAlias
    : null;
});

export const getAccountContact = createSelector(getLocation, getLocalContacts, (locationState, onlyLocalContacts) => {
  let accountContacts = [];

  if (onlyLocalContacts) {
    accountContacts = locationState.customerLocation?.customerContacts.map((c) => {
      const aux: any = {};
      aux.isLocal = true;

      return merge(aux, c.contactPerson, c);
    });
  } else {
    const localContacts = locationState.customerLocation?.customerContacts.map((c) => {
      const aux: any = {};
      aux.isLocal = true;

      return merge(aux, c.contactPerson, c);
    });
    const inheritContacts = locationState.customerLocation?.parentCustomerContacts?.map((c) => {
      const aux: any = {};
      aux.isLocal = false;

      return merge(aux, c.contactPerson, c);
    });
    accountContacts = [...(inheritContacts || []), ...(localContacts || [])];
  }

  return accountContacts;
});

export const getHeaderExpanded = createSelector(getLocation, (state) => {
  return state.headerExpanded;
});

export const getCurrentContact = createSelector(getAccountContact, getLocationData, (state, locData, { currentContactId }) => {
  const contact = state.find((c) => c.contactPersonId === currentContactId);
  contact.contactNote = locData.contactNotes
    ?.slice()
    .reverse()
    .find((n) => n.contactPersonId === currentContactId);

  return contact;
});
