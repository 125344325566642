import { GetCustomerResp } from '@xpo-ltl-2.0/sdk-customer';
import { LocationActionTypes } from './location.action';
export interface LocationState {
  customerLocation: GetCustomerResp;
  locationId: string | null;
  cardStatus: any;
  showChangesOnly: boolean;
  headerExpanded: boolean;
  localContacts: boolean;
}

export const initialState: LocationState = {
  customerLocation: null,
  locationId: null,
  cardStatus: {},
  showChangesOnly: false,
  headerExpanded: false,
  localContacts: true,
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LocationActionTypes.GetLocation: {
      return { ...state };
    }
    case LocationActionTypes.SetLocation: {
      return Object.assign({}, state, action.payload);
    }
    case LocationActionTypes.GetShowChangesOnly: {
      return { ...state };
    }
    case LocationActionTypes.SetLocalContacts:
    case LocationActionTypes.SetShowChangesOnly: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};

export const getLocationData = (state: LocationState) => state.customerLocation;
