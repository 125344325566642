import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';

import { DialogComponent } from './dialog.component';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, XpoDialogModule, MatButtonModule, MatIconModule],
    exports: [DialogComponent]
})
export class DialogModule {}
