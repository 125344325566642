import { GetCustomerResp } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerHistoryActionTypes } from './customer-history.actions';

export interface CustomerHistoryState {
  customerHistoryHeader: any[];
}

export const initialState: CustomerHistoryState = {
  customerHistoryHeader: [],
};

export const customerHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerHistoryActionTypes.GetCustomerHistory: {
      return { ...state };
    }
    case CustomerHistoryActionTypes.SetCustomerHistory: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};
