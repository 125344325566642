<div class="quick-search-form">
  <input inputTrim (blur)="checkBlankValue($event)" placeholder="MAD Code" [formControl]="searchValueControl" matInput
    type="text" [matAutocomplete]="auto" />
  <mat-icon style="opacity: 0.5; position: absolute; left: 85%; top: 3px;">search</mat-icon>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMadCode">
    <div *ngIf="!loading && !noResults">
      <mat-option (click)="selectMadCd()" style="height: auto;" *ngFor="let result of results"
        [value]="searchValueControl?.value?.toUpperCase()">
        <pre>{{ searchValueControl?.value?.toUpperCase() }}</pre>
        <p class="quick-search-link">{{ result.customerLocation.party1.partyName }}</p>
      </mat-option>
    </div>
    <mat-option *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-option>
    <mat-option *ngIf="noResults">
      <div>No results found.</div>
    </mat-option>
  </mat-autocomplete>
</div>
