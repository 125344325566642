import { CustomerRequest } from '@xpo-ltl-2.0/sdk-customer';
import { GetInterfaceEmployeeResp } from '@xpo-ltl-2.0/sdk-humanresource';
import { User } from '@xpo-ltl/sdk-common';
import { ChangeRequestActionTypes } from './change-request.action';

export interface ChangeRequestState {
  changeRequest: CustomerRequest;
  changeRequestChangesOnly: CustomerRequest;
  editFormStatus?: boolean;
  employees: GetInterfaceEmployeeResp[];
  loggedInUser: User;
  requestedChangesCurrentLocation: CustomerRequest;
  requestChangesCurrentLine: CustomerRequest;
}

export const initialState: ChangeRequestState = {
  changeRequest: null,
  editFormStatus: true,
  changeRequestChangesOnly: null,
  employees: [],
  loggedInUser: null,
  requestedChangesCurrentLocation: null,
  requestChangesCurrentLine: null,
};

export const changeRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ChangeRequestActionTypes.GetChangeRequest: {
      return { ...state };
    }
    case ChangeRequestActionTypes.SetChangeRequest: {
      return Object.assign({}, state, action.payload);
    }
    case ChangeRequestActionTypes.SetEmployees: {
      return Object.assign({}, state, action.payload);
    }
    case ChangeRequestActionTypes.GetEmployees: {
      return { ...state };
    }
    case ChangeRequestActionTypes.SetLoggedInUser: {
      return Object.assign({}, state, action.payload);
    }
    case ChangeRequestActionTypes.GetLoggedInUser: {
      return { ...state };
    }
    case ChangeRequestActionTypes.SetRequestedChangesCurrentLocation: {
      return Object.assign({}, state, action.payload);
    }
    case ChangeRequestActionTypes.SetRequestedChangesCurrentLine: {
      return Object.assign({}, state, action.payload);
    }
    case ChangeRequestActionTypes.GetRequestedChangesCurrentLocation: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
};

export const getChangeRequestData = (state: ChangeRequestState) => state.changeRequest;
