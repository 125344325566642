import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/* Interfaces */
import { DialogConfig } from './dialog.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['dialog.component.scss'],
  host: {
    class: 'ncis-Dialog',
  },
})
export class DialogComponent {
  get dialog(): DialogConfig {
    return this.data;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfig, public dialogRef: MatDialogRef<DialogComponent>) {}
}
