import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  BulkExpireCustomersEnsemblePayload,
  CustomerApiService,
  CustomerRequest,
  RequestCustomerLocationFunction,
  RequestEmailAddress,
  StartCstBulkExpireCustomersChEnsembleRqst,
} from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { AppState } from 'src/app/store';
import { getEmployees } from 'src/app/store/change-request/change-request.selectors';
import { ConstantsService } from '../../services/constants/constants.service';
import { DialogComponent, DialogConfig } from '../dialog';

@Component({
  selector: 'app-confirm-bulk-delete-dialog',
  templateUrl: './confirm-bulk-delete-dialog.component.html',
  styleUrls: ['./confirm-bulk-delete-dialog.component.scss'],
})
export class ConfirmBulkDeleteDialogComponent implements OnInit {
  totalLocations: number;
  changeRequestData: CustomerRequest;
  idsToExpire: number[];
  requesterEmail: string;

  constructor(
    private customerApi: CustomerApiService,
    private store: Store<AppState>,
    private router: Router,
    private dialogRef: MatDialogRef<ConfirmBulkDeleteDialogComponent>,
    private dialog: MatDialog,
    private snackbar: XpoSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.totalLocations = this.data.locations;
    this.changeRequestData = this.data.changeRequest;
    this.idsToExpire = this.changeRequestData.requestCustomerLocationFunction.map((loc) => loc.customerLocationFuncId);

    this.store.pipe(select(getEmployees)).subscribe((employees) => {
      const employee = employees.find(
        (emp) => emp?.interfaceEmployee?.employeeId === this.changeRequestData.submittedBy
      );

      if (employee) {
        this.requesterEmail = employee.interfaceEmployee.emailAddress;
      }
    });
  }

  cancel(): void {
    this.dialogRef.close({ isProcessing: false });
  }

  startBulkExpire(): void {
    const request = new StartCstBulkExpireCustomersChEnsembleRqst();
    const payload = new BulkExpireCustomersEnsemblePayload();
    payload.requestEmailAddress = new RequestEmailAddress();

    payload.customerLocationFuncId = this.idsToExpire;
    payload.requestEmailAddress.emailAddress = this.requesterEmail;
    payload.requestEmailAddress.listActionCd = ActionCd.DELETE;
    payload.requestEmailAddress.cstRequestId = this.changeRequestData.cstRequestId;
    payload.approverEmailAddress = this.data.ownerEmail;
    request.ensembleName = 'BulkExpireCustomers';
    request.payload = payload;

    this.customerApi.startCstBulkExpireCustomersChEnsemble(request).subscribe((res) => {
      if (res.ensembleInstId) {
        this.snackbar.open({
          message: 'Bulk delete has successfully started.',
          status: 'success',
          matConfig: {
            duration: ConstantsService.snackbarDuration,
          },
        });
      }
      this.dialogRef.close({ isProcessing: true });
    });
  }
}
