import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

/* Rxjs */
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/* Enums */
import { AppRoutes } from '../enums/app-routes.enum';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  handleError(err: HttpErrorResponse): Observable<any> {
    switch (err.status) {
      case 401: {
        this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        break;
      }
      case 403: {
        this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        break;
      }
      case 400: {
        if (err.error.error.message.includes('Validation Errors Found')) {
          const message = err.error.error?.moreInfo[0]?.message;

          if (message) {
            err.error.error.message = message;
          }
        }
        break;
      }
    }
    return throwError(err);
  }
}
