import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ncis-badge',
  templateUrl: './ncis-badge.component.html',
  styleUrls: ['./ncis-badge.component.scss']
})
export class NcisBadgeComponent {
  @Input() data;
  constructor() { }
}
