import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogAction, DialogComponent, DialogConfig } from '../../components/dialog';

@Injectable()
export class CheckUnsavedChangesGuard implements CanDeactivate<any> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.isCancelled() && typeof component.getFormComponent === 'function') {
      const formComponent: UntypedFormGroup = component.getFormComponent();
      if (formComponent.dirty) {
        return this.openDialogConfirmEditing().pipe(
          map((res: DialogAction) => {
            return res.resultAction;
          })
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  openDialogConfirmEditing(): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: 'You have uncommitted changes.',
      icon: 'report_problem',
      content: 'Do you want to continue editing?',
      actions: [
        {
          label: 'CONTINUE EDITING',
          type: 'primary',
          resultType: 'EDIT',
          resultAction: false,
        },
        {
          label: 'DISCARD CHANGES',
          type: 'secondary',
          resultType: 'DISCARD_CHANGE',
          resultAction: true,
        },
      ],
    };
    return this.dialog.open(DialogComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }
}
