import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StatesService } from 'src/app/shared/services/states/states.service';

@Component({
  selector: 'app-alias-dialog',
  templateUrl: './alias-dialog.component.html',
  styleUrls: ['./alias-dialog.component.scss'],
})
export class AliasDialogComponent implements OnInit {
  aliasDetailsForm: UntypedFormGroup;
  states$;
  countryList = ['US', 'MX', 'CA'];

  constructor(
    private statesService: StatesService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialogRef<AliasDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.states$ = this.statesService.getStates(this.data.countryCd);
    this.aliasDetailsForm = this.fb.group({
      name1: [this.data.name1],
      name2: [this.data.name2],
      countryCd: [this.data.countryCd],
      lastUpdateRemarks: [this.data.lastUpdateRemarks],
      address: [this.data.address],
      stateCd: [this.data.stateCd],
      cityName: [this.data.cityName],
      zipCd: [this.data.zip4Cd ? `${this.data.zipCd}-${this.data.zip4Cd}` : this.data.zipCd],
    });

    this.aliasDetailsForm.disable();
  }

  close(): void {
    this.dialog.close();
  }
}
