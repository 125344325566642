import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IFloatingFilter, TextFilter } from 'ag-grid-community';

@Component({
  selector: 'app-select-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'text-filter-container',
  },
})
export class TextFilterComponent implements IFloatingFilter {
  textControl = new UntypedFormControl('');

  private params;

  constructor() {}

  agInit(params): void {
    this.params = params;
  }

  onParentModelChanged(parentModel): void {
    if (!parentModel) {
      this.textControl.reset();
    }
  }

  onInputBlur(e): void {
    const field = this.params.field;
    this.params.parentFilterInstance(function(instance): void {
      (<TextFilter>instance).onFloatingFilterChanged(field, e.target.value || 'no-value');
    });
  }
}
