import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  CustomerApiService,
  GetInvoiceInstructionHistoryPath,
  InvoiceInstructionHistory,
} from '@xpo-ltl-2.0/sdk-customer';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { map } from 'rxjs/operators';

@Component({
  selector: 'instruction-history-component',
  templateUrl: './instruction-history.component.html',
  styleUrls: ['./instruction-history.component.scss'],
})
export class InstructionHistoryComponent {
  private gridApi;

  constructor(private customerApi: CustomerApiService) {
    this.noRowsTemplate = `<span">No History Found.</span>`;
  }
  @Input() custLocFunctionId: number;

  gridInstructionOptions: GridOptions = {
    defaultColDef: {
      sortable: false,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      resizable: true,
      flex: 1,
    },
    pagination: true,
    paginationPageSize: 25,
  };

  columnInstructionDefs: ColDef[] = [
    { headerName: 'EFF START DATE', field: 'effectiveStartDate' },
    { headerName: 'PREPAID ORIGINAL', field: 'prepaidOriginal' },
    { headerName: 'PREPAID CORRECTED', field: 'prepaidCorrected' },
    { headerName: 'PREPAID BALDUE', field: 'prepaidBaldue' },
    { headerName: 'PREPAID TRUE_DEBTOR', field: 'prepaidTrueDebtor' },
    { headerName: 'COLLECT ORIGINAL', field: 'collectOriginal' },
    { headerName: 'COLLECT CORRECTED', field: 'collectCorrected' },
    { headerName: 'COLLECT BALDUE', field: 'collectBaldue' },
    { headerName: 'COLLECT TRUE_DEBTOR', field: 'collectTrueDebtor' },
    { headerName: 'REQUEST ID', field: 'legacyRqstNbr' },
    { headerName: 'REQUESTER', field: 'requesterFullName' },
    { headerName: 'SPECIALIST', field: 'modifyByFullName' },
  ];

  noRowsTemplate: string;

  rowData: Array<InvoiceInstructionHistory> = [];

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    const pathParams: GetInvoiceInstructionHistoryPath = new GetInvoiceInstructionHistoryPath();
    pathParams.custLocFunctionId = this.custLocFunctionId;
    this.customerApi
      .getInvoiceInstructionHistory(pathParams, { toastOnError: false })
      .pipe(
        map((resp) => {
          return resp.invoiceInstructionHistory.map((history) => {
            history['effectiveStartDate'] = new DatePipe('en-US').transform(
              history.effectiveStartDateTime,
              'MM/dd/yyyy'
            );
            return history;
          });
        })
      )
      .subscribe((data) => (this.rowData = data));
  }

  onFirstDataRendered(params): void {
    params.api.sizeColumnsToFit();
  }
}
