import { Injectable } from '@angular/core';
import {XpoBoardData, XpoBoardDataSource, XpoBoardState} from '@xpo-ltl/ngx-board/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerSearchService extends XpoBoardDataSource {
  private results: any;

  constructor() {
    super();
  }

  getResults(): any {
    return this.results;
  }

  setResults(results): void {
    this.results = results;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return of(new XpoBoardData(state, this.getResults(), this.getResults()?.length, 10));
  }
}
