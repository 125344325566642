<p class="read-only-view" [matTooltip]="currentErrorMessage" *ngIf="!isEditMode; else isEdit">{{ parsedValue }}</p>
<ng-template #isEdit>
  <mat-form-field>
    <app-phone-number-input
      [showPhoneExtension]="showCountryCd"
      (formFocusedOut)="onBlur($event)"
      [formControl]="phoneNbrControl"
      [showCountryCd]="showCountryCd"
    ></app-phone-number-input>
  </mat-form-field>
</ng-template>
