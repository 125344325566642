import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IFloatingFilter } from 'ag-grid-community';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'select-filter-container',
  },
})
export class SelectFilterComponent implements IFloatingFilter {
  options: Observable<{ ['value']: any; ['label']: string }[]> = of([]);
  optionControl = new UntypedFormControl('');

  constructor() {}

  agInit(params): void {
    this.options = params.options;

    this.optionControl.valueChanges.subscribe((value) => {
      params.parentFilterInstance(function(instance): void {
        instance.onFloatingFilterChanged(params.field, value);
      });
    });
  }

  onParentModelChanged(parentModel): void {
    if (!parentModel) {
      this.optionControl.reset();
    }
  }
}
