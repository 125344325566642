import { Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import {XpoNotificationTemplate} from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { SnackbarStatus } from './shared/enums/snackbar-status.enum';
import { ConstantsService } from './shared/services/constants/constants.service';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-Notification',
  },
})
export class NotificationComponent implements OnInit, OnDestroy {
  message: string;
  show: boolean;

  @Input()
  showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  private snackBarRef: MatSnackBarRef<XpoNotificationTemplate>;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private snackbar: XpoSnackBar,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.message = message.text;
              this.show = message.type === NotificationTypeEnum.ShowOverlay;
            } else if (message.text === 'System error. Please contact support.') {
              this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
            } else if (message.text !== 'Error: Shipment not found.') {
              this.snackBarRef = this.snackbar.open({
                message:
                  message.text === 'Error: Entity Contact not found'
                    ? 'The contact has been deleted after this request has been submitted.  You must reject the line item.'
                    : message.text,
                status: SnackbarStatus[message.snackbarConfig.status],
                action:
                  message.snackbarConfig != null &&
                  message.snackbarConfig.actionHandler != null &&
                  message.snackbarConfig.actionHandler.actionLabel() != null
                    ? message.snackbarConfig.actionHandler.actionLabel()
                    : null,
                matConfig: {
                  duration: ConstantsService.snackbarDuration,
                },
              });

              if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
                if (message.snackbarConfig.actionHandler.onAction != null) {
                  this.snackBarRef.onAction().subscribe(message.snackbarConfig.actionHandler.onAction);
                }
                if (message.snackbarConfig.actionHandler.afterOpened != null) {
                  this.snackBarRef.afterOpened().subscribe(message.snackbarConfig.actionHandler.afterOpened);
                }
                if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                  this.snackBarRef.afterDismissed().subscribe(message.snackbarConfig.actionHandler.afterDismissed);
                }
              }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
