<div style="height: 100%;">
  <div class="ncis-LocationDetails-card ncis-LocationDetails-card-table">
    <div *ngIf="locationHasChanges()" class="ncis-requester-notes">
      <p class="ncis-requester-notes-title">Requester Notes and Remarks</p>
      <div class="ncis-requester-notes-content">
        <span>NOTES</span><span>{{ requesterNote }}</span>
      </div>
      <div class="ncis-requester-notes-content">
        <span>REMARKS</span><span>{{ requesterRemarks }}</span>
      </div>
    </div>
    <div class="table-container">
      <form
        class="ncis-BNARowForm-form"
        [formGroup]="invoiceForm"
        *ngIf="invoiceForm"
        autocomplete="none"
        ncisXpoFocusOnSubmit
      >
        <table class="invoice-table" mat-table [dataSource]="dataSourceInvoice" multiTemplateDataRows>
          <!-- invoicetype Column -->
          <ng-container matColumnDef="invoicetype">
            <th
              class="invoice-name-column invoice-table-title invoice-table-cell-id"
              mat-header-cell
              *matHeaderCellDef
            ></th>
            <td
              [ngClass]="{ 'ncis-row-edit': element?.changes, 'ncis-table-title': element?.isHeader }"
              class="invoice-table-cell invoice-table-cell-id"
              mat-cell
              *matCellDef="let element"
            >
              <span *ngIf="element.isHeader; else isInvoice">{{ element?.name }}</span>
              <ng-template #isInvoice>
                <span style="text-transform: uppercase;">
                  {{ invoiceInstructionLabels[element.current?.invoiceInstruction?.invoiceCd] }}
                </span>
              </ng-template>
            </td>
          </ng-container>

          <!-- current Column -->
          <ng-container matColumnDef="current">
            <th class="invoice-table-title" mat-header-cell *matHeaderCellDef>
              Current Send To
              <button
                [disabled]="userRoles$ | userAccess: ['noUpdateAuthorization'] | async"
                id="edit-icon-invoice"
                *ngIf="!locationHasChanges() && !isNewLocation"
                mat-icon-button
                (click)="enterEditMode()"
              >
                <mat-icon inline="true">create </mat-icon>
              </button>
            </th>
            <td
              [ngClass]="{ 'ncis-row-edit': element?.changes }"
              class="invoice-table-cell invoice-table-cell-current"
              mat-cell
              *matCellDef="let element"
            >
              <ng-container *ngIf="!element.isHeader">
                <div class="cell-value-wrapper">
                  <ng-container
                    *ngIf="
                      element.current?.invoiceInstruction?.useAsEnteredInd ||
                        madCode === element.current?.madCd ||
                        element.current?.invoiceInstruction?.billToRequest;
                      else hasinvoicing
                    "
                  >
                    <div *ngIf="element.current?.invoiceInstruction?.useAsEnteredInd">
                      {{ noInvoicingInstructions }}
                    </div>
                    <div *ngIf="element.current?.invoiceInstruction?.billToRequest">
                      {{ billToOnRequest }}
                    </div>
                    <div>
                      <strong>Remarks:</strong>&nbsp;<span>{{
                        element.current?.invoiceInstruction?.lastUpdateRemarks
                      }}</span>
                    </div>
                  </ng-container>
                  <ng-template #hasinvoicing>
                    <pre style="font-weight: 500;">{{ element.current?.madCd }}</pre>
                    <div>{{ element.current?.bill2CustLocation?.party1?.partyName }}</div>
                    <div>{{ element.current?.bill2CustLocation?.party2?.partyName }}</div>
                    <div>{{ element.current?.bill2CustLocation?.customerAddress?.address }}</div>
                    <div>
                      <ng-container *ngIf="element.current?.bill2CustLocation">
                        {{ element.current?.bill2CustLocation?.customerAddress?.cityName }},
                        {{ element.current?.bill2CustLocation?.customerAddress?.stateCd }},
                        {{ element.current?.bill2CustLocation?.customerAddress?.zipCd
                        }}{{
                          element.current?.bill2CustLocation?.customerAddress?.zip4Cd
                            ? '-' + element.current?.bill2CustLocation?.customerAddress?.zip4Cd
                            : ''
                        }},
                        {{ element.current?.bill2CustLocation?.customerAddress?.countryCd }}
                      </ng-container>
                    </div>
                    <div>
                      Effective: {{ element.current?.invoiceInstruction?.effectiveStartDateTime | date: 'MM/dd/yyyy' }}
                    </div>
                    <div>
                      <strong>Remarks:</strong><span>{{ element.current?.invoiceInstruction?.lastUpdateRemarks }}</span>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <!-- request Column -->
          <ng-container matColumnDef="request">
            <form [formGroup]="form">
              <th class="invoice-table-title " mat-header-cell *matHeaderCellDef>
                <span style="display: flex; align-items: center;">
                  {{ locationHasChanges() ? 'Requested Send To' : 'New Send To' }}
                  <button
                    [disabled]="userRoles$ | userAccess: ['noUpdateAuthorization'] | async"
                    *ngIf="
                      loggedInUserIsOwner &&
                      locationHasChanges() &&
                      !readOnly &&
                      !showChangesOnly &&
                      !allInvoicesChanged
                    "
                    mat-icon-button
                    (click)="enterEditMode()"
                  >
                    <mat-icon inline="true">create </mat-icon>
                  </button>
                  <xpo-status-indicator
                    *ngIf="isChangeRequest && shouldShowStatusIndicator"
                    [statusText]="getStatusText()"
                    [statusColor]="getStatusColor()"
                    isLabel="true"
                    style="margin-left: 8px;"
                  ></xpo-status-indicator>
                </span>
              </th>
              <td
                [ngClass]="{ 'ncis-row-edit': element?.changes }"
                class="invoice-table-cell"
                mat-cell
                *matCellDef="let element"
              >
                <div class="cell-value-wrapper">
                  <ng-container *ngIf="!element.isHeader">
                    <ng-container
                      *ngIf="
                        !locationHasChanges() ? readOnly || !isEditMode : element.changes ? false : !isEditMode;
                        else editFields
                      "
                    >
                      <div *ngIf="element.changes">
                        {{
                          element.request?.effectiveDate || element.request?.invoiceInstruction?.effectiveStartDateTime
                            | date: 'MM/dd/yyyy'
                        }}
                      </div>
                      <div *ngIf="element.changes">
                        {{ element.request?.madCd }}
                      </div>
                    </ng-container>
                    <ng-template #editFields>
                      <div *ngIf="form" class="invoice-table-cell-request-form-row">
                        <mat-form-field
                          floatLabel="always"
                          style="margin-right: 8px;"
                          [ngClass]="{
                            'invalid-bill2':
                              (form?.controls)[element.key + 'MadCd'].invalid ||
                              !!form?.controls[element.key + 'MadCd'].getError('alreadySelfInvoice')
                          }"
                        >
                          <app-madcode-search
                            formControlName="{{ element.key + 'MadCd' }}"
                            (madCdSelected)="onMadCdSelected($event)"
                            [element]="element"
                            [isOutside]="!locationHasChanges()"
                            [madCode]="madCode"
                            [isNewLocation]="isNewLocation"
                            [prepaidCopyMadCd]="prepaidCopyMadCd"
                            [collectCopyMadCd]="collectCopyMadCd"
                            [matTooltip]="getErrorMessage(element.key)"
                            matTooltipPosition="above"
                          >
                          </app-madcode-search>
                        </mat-form-field>
                        <span>effective as of</span>
                        <div class="effective-date">
                          <mat-form-field floatLabel="always">
                            <input
                              matInput
                              [min]="minDate"
                              [matDatepicker]="pickerStart"
                              formControlName="{{ element.key + 'EffectiveStart' }}"
                            />
                            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <mat-checkbox
                        [checked]="form.controls[element.key + 'SelfInvoicing'].value"
                        formControlName="{{ element.key + 'SelfInvoicing' }}"
                        (change)="handleSelfInvoicing($event, element)"
                        >Self Invoicing</mat-checkbox
                      >
                    </ng-template>
                    <ng-container
                      *ngIf="
                        !form.controls[element.key + 'SelfInvoicing'].value &&
                        (element.request?.customerLocation ||
                          (form.value[element.key + 'MadCd'] &&
                            element.changes &&
                            (element.request?.sendToCustomerLocationFuncId || element.request?.customerLocationFuncId)))
                      "
                    >
                      <div>
                        {{
                          element.request?.customerLocation?.party1?.partyName ||
                            element.request?.bill2CustLocation?.party1?.partyName
                        }}
                      </div>
                      <div>
                        {{
                          element.request?.customerLocation?.party2?.partyName ||
                            element.request?.bill2CustLocation?.party2?.partyName
                        }}
                      </div>
                      <div>
                        {{
                          element.request?.customerLocation?.customerAddress?.address ||
                            element.request?.bill2CustLocation?.customerAddress?.address
                        }},
                        {{
                          element.request?.customerLocation?.customerAddress?.cityName ||
                            element.request?.bill2CustLocation?.customerAddress?.cityName
                        }},
                        {{
                          element.request?.customerLocation?.customerAddress?.stateCd ||
                            element.request?.bill2CustLocation?.customerAddress?.stateCd
                        }},
                        {{
                          element.request?.customerLocation?.customerAddress?.zipCd ||
                            element.request?.bill2CustLocation?.customerAddress?.zipCd
                        }}{{
                          element.request?.customerLocation?.customerAddress?.zip4Cd ||
                          element.request?.bill2CustLocation?.customerAddress?.zip4Cd
                            ? '-' +
                              (element.request?.bill2CustLocation?.customerAddress?.zip4Cd ||
                                element.request?.customerLocation?.customerAddress?.zip4Cd)
                            : ''
                        }},
                        {{
                          element.request?.customerLocation?.customerAddress?.countryCd ||
                            element.request?.bill2CustLocation?.customerAddress?.countryCd
                        }}
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </form>
          </ng-container>

          <tr class="myqueue-table-header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div *ngIf="locationHasChanges() && this.dataSourceInvoice.data; else remarks" class="ncis-BNARowForm-comments">
          <span class="ncis-BNARowForm-label">Remarks</span>
          <mat-form-field class="ncis-CommentsInput" floatLabel="always">
            <textarea
              [readonly]="!loggedInUserIsOwner || readOnly || statusIsApproveOrRejected"
              matInput
              formControlName="comments"
              matTextareaAutosize
              matAutosizeMinRows="6"
              matAutosizeMaxRows="6"
              maxLength="800"
            >
            </textarea>
            <mat-error
              *ngIf="invoiceForm?.controls?.comments?.errors && invoiceForm?.controls?.comments?.errors.noWhiteSpace"
            >
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-error
              *ngIf="invoiceForm?.controls?.comments?.errors && invoiceForm?.controls?.comments?.errors.required"
            >
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-hint *ngIf="!invoiceForm?.controls?.comments?.errors" align="end"
              >{{ getRemainingCharacters() }} characters</mat-hint
            >
          </mat-form-field>
        </div>
        <ng-template #remarks>
          <ng-container *ngIf="isEditMode">
            <p style="font-weight: 500;" class="ncis-BNARowForm-label">Remarks</p>
            <mat-form-field style="padding-bottom: 16px;">
              <input #remarksInput type="text" matInput formControlName="comments" required maxlength="50" />
              <mat-error
                *ngIf="invoiceForm?.controls?.comments?.errors && invoiceForm?.controls?.comments?.errors.required"
              >
                <mat-icon>report_problem</mat-icon>Remarks field is required
              </mat-error>
              <mat-hint *ngIf="!invoiceForm?.controls?.comments?.errors" align="end">
                {{ 50 - remarksInput.value.length }} characters
              </mat-hint>
            </mat-form-field>
          </ng-container>
        </ng-template>
      </form>
      <div
        class="table-button-container table-button-container--spaceBetween"
        *ngIf="
          loggedInUserIsOwner && locationHasChanges() && this.dataSourceInvoice.data && hasChanges && !isNewLocation
        "
      >
        <div class="actions-container">
          <button
            id="approve-invoice"
            [disabled]="statusIsApproved || isCompleted || form?.invalid || invoiceForm?.invalid"
            mat-flat-button
            type="submit"
            appDebounceClick
            (debounceClick)="approve()"
            [debounceTime]="700"
          >
            APPROVE
          </button>
          <button
            id="reject-invoice"
            [disabled]="statusIsApproveOrRejected"
            mat-stroked-button
            appDebounceClick
            (debounceClick)="reject()"
            [debounceTime]="700"
          >
            REJECT
          </button>
          <button
            id="hold-invoice"
            [disabled]="statusIsApproveOrRejected || statusIsHeld"
            mat-stroked-button
            appDebounceClick
            (debounceClick)="hold()"
            [debounceTime]="700"
          >
            HOLD
          </button>
        </div>
        <button id="cancel-invoice" mat-stroked-button>CANCEL</button>
      </div>
    </div>
    <div *ngIf="isEditMode && !hasChanges" class="ncis-invoice-edit-outside-request-footer">
      <div class="ncis-invoice-edit-outside-request-buttons">
        <button
          id="save-invoice"
          mat-flat-button
          [disabled]="form.invalid || invoiceForm.invalid"
          appDebounceClick
          (debounceClick)="saveInvoices()"
          [debounceTime]="700"
        >
          SAVE
        </button>
        <button id="cancel-invoice" mat-stroked-button (click)="exitEditMode()">CANCEL</button>
      </div>
    </div>
  </div>
</div>
