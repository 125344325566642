import { ListRequestsByResp } from '@xpo-ltl-2.0/sdk-customer';
import { SearchRequestActionTypes } from './search-request.action';

export interface SearchRequestState {
  searchResults: number;
}

export const initialState: SearchRequestState = {
  searchResults: null,
};

export const searchRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SearchRequestActionTypes.GetSearchRequestResults: {
      return { ...state };
    }
    case SearchRequestActionTypes.SetSearchRequestResults: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};
