import { Injectable } from '@angular/core';
import { CustomerApiService, ListServiceRecipientQuery } from '@xpo-ltl-2.0/sdk-customer';
import { XpoAgGridBoardData } from '@xpo-ltl/ngx-board/ag-grid';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-board/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ServiceRecipientSortFields, SortOrder } from 'src/app/shared/enums/service-reciepient-sort-fields.enum';
import { GridApiContextService } from 'src/app/shared/services/grid-api-context/grid-api-context.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceRecipientSearchService extends XpoBoardDataSource {
  constructor(private customerApi: CustomerApiService, private gridApiContextService: GridApiContextService) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const gridApi = this.gridApiContextService.getCurrentGridApi();
    const listInfoQuery = {
      listInfo: {
        startAt: ((gridApi.paginationGetCurrentPage() || 1) - 1) * gridApi.paginationGetPageSize(),
        numberOfRows: gridApi.paginationGetPageSize(),
        sortFields:
          state.sortOrder && state.sortOrder.length
            ? state.sortOrder.map((col) => {
                return { sortByFieldName: ServiceRecipientSortFields[col.column], sortOrder: SortOrder[col.direction] };
              })
            : [
                {
                  sortByFieldName: ServiceRecipientSortFields.serviceRecipientName,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.state,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.city,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.address,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.typeOfServiceInd,
                  sortOrder: SortOrder.asc,
                },
              ],
      },
    };

    const searchCriteria = state.criteria ? state.criteria : {};
    const queryParams = new ListServiceRecipientQuery();

    Object.keys(searchCriteria).forEach((k) => (searchCriteria[k] === 'no-value' ? (searchCriteria[k] = '') : null));

    Object.assign(queryParams, searchCriteria, listInfoQuery);

    const subscription$ = this.customerApi.listServiceRecipient(queryParams, { loadingOverlayEnabled: false });

    return subscription$.pipe(
      switchMap((resp) => {
        const serviceRecipient = resp.serviceRecipient;
        if (serviceRecipient.length) {
          const serviceRecipientModified = serviceRecipient.map((singleRecipient) => {
            return {
              ...singleRecipient,
              id: singleRecipient.serviceRecipientNbr,
            };
          });

          return of(
            new XpoBoardData(state, serviceRecipientModified, resp.listInfo.totalRowCount, resp.listInfo.numberOfRows)
          );
        }
        return of(new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state)));
      }),
      catchError(() => of(new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state))))
    );
  }
}
