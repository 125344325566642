import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerRequest } from '@xpo-ltl-2.0/sdk-customer';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardApi, XpoAgGridBoardReadyEvent, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardViewConfig,
  XpoLocalStorageBoardViewDataStore,
} from '@xpo-ltl/ngx-board/core';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ContactRoleCd, CustomerLineStatusCd } from '@xpo-ltl/sdk-common';
import { ColumnApi, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { YesNo } from 'src/app/change-request-page/models/enums/yes-no';
import { CustomerContactsService } from 'src/app/location-details-page/company-information/services/customer-contacts.service';
import { LineStatusCellRendererComponent } from 'src/app/shared/components/line-status-cell-renderer/line-status-cell-renderer.component';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { ContactRoleCdLabel } from 'src/app/shared/enums/contact-role-code-label.enum';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { PhoneModel } from 'src/app/shared/models/phone';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import {
  getChangeRequest,
  getRequestedChangeCurrentLine,
  loggedInUserIsOwner,
} from 'src/app/store/change-request/change-request.selectors';
import { SetLocalContacts, SetShowChangesOnly } from 'src/app/store/location/location.action';
import { getCurrentContact, getShowChangesOnly } from 'src/app/store/location/location.selectors';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { EditCellRendererComponent } from '../cell-renderers/edit-cell-renderer/edit-cell-renderer.component';
import { PhoneNumberEditorComponent } from '../cell-renderers/phone-number-editor/phone-number-editor.component';
import { SelectCellRendererComponent } from '../cell-renderers/select-cell-renderer/select-cell-renderer.component';
import { EditIconComponent } from '../edit-icon/edit-icon.component';
import { DetailContactComponent } from './detail-contact/detail-contact.component';
import { NewContactDialogComponent } from './new-contact-dialog/new-contact-dialog.component';

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.scss', '../../styles/shared-styles.scss'],
  providers: [CustomerContactsService],
})
export class CustomerContactsComponent implements OnInit, OnDestroy {
  gridApi: GridApi;
  colApi: ColumnApi;
  gridBoardApi: XpoAgGridBoardApi;
  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  subscribeRoutes: any;
  allSelected = true;
  localSelected = false;
  loggedInUserIsOwner: boolean = false;
  isNewLocation: boolean = false;
  isChangeRequest = false;
  noData = false;
  hasChanges = false;
  notes: string = '';
  customerRequest: CustomerRequest;
  userRole$: Observable<UserRole>;

  boardOptions: XpoBoardOptions = {
    addNewViewButtonLabel: '',
    suppressViewSwitcher: true,
    enableFilterReset: false,
    enableQueryParamStatePersistance: false,
    persistFiltersBetweenViews: false,
    preloadViewData: false,
    suppressGridDensity: true,
    suppressGridSettingsPopover: false,
    suppressRecordCounts: false,
    suppressLastUpdateTime: true,
  };

  customGridOptions: GridOptions = {
    suppressRowClickSelection: true,
    localeText: { noRowsToShow: 'There are no contacts for this location.' },
    masterDetail: true,
    detailCellRenderer: 'detailCellRenderer',
    frameworkComponents: {
      detailCellRenderer: DetailContactComponent,
      editCellRenderer: EditIconComponent,
      editCellRendererComponent: EditCellRendererComponent,
      selectCellRendererComponent: SelectCellRendererComponent,
      phoneNumberEditor: PhoneNumberEditorComponent,
    },
    animateRows: true,
    detailCellRendererParams: {
      customActions: {
        toggle: this.toggleRow.bind(this),
        update: this.updateTable.bind(this),
      },
    },
    multiSortKey: 'ctrl',
    pagination: true,
    suppressPaginationPanel: false,
    getRowClass: (params) => {
      return this.isChangeRequest && params.node.data.actionCd ? 'ncis-GridRow--highlighted' : '';
    },
    onRowClicked: (params) => {
      this.toggleRow(params.node);
    },
    onRowDataChanged: (event) => {
      this.noData = event.api.getDisplayedRowCount() === 0;
    },
    domLayout: 'autoHeight',
    getRowHeight: (params) => {
      const height = params.node.detail
        ? this.isChangeRequest
          ? params.node.data.actionCd === 'UPDATE'
            ? 415
            : 341
          : params.node.data.actionCd === 'UPDATE'
          ? 265
          : 160
        : 30;
      return params.node.detail && this.isChangeRequest && !this.loggedInUserIsOwner ? height - 80 : height;
    },
    defaultColDef: {
      cellClassRules: {
        'cell-edition-error': (params) => {
          return this.errorStateManager.hasError(params.node.rowIndex + '', params.colDef.field);
        },
      },
    },
  };

  private destroy$ = new Subject<void>();

  constructor(
    public dataSource: CustomerContactsService,
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private errorStateManager: ErrorStateManagerService,
    private snackbar: XpoSnackBar
  ) {
    this.isChangeRequest = this.isChangeRequestFn();
    this.viewTemplates = this.getBoardViewTemplates();
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-customer-contacts', this.getBoardViews());
  }

  private getBoardViews(): XpoBoardViewConfig[] {
    return [
      {
        id: 'all-test-view',
        templateId: 'template1',
        name: 'All - SD',
        closeable: false,
        systemDefined: true,
        visible: true,
      },
    ];
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    const indexCol = { ...XpoAgGridColumns.RowIndex };
    indexCol.suppressSizeToFit = true;
    indexCol.width = 60;
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'template1',
        name: 'Template 1',
        allowAdditional: true,
        availableColumns: [
          {
            ...indexCol,
            cellClassRules: {
              'cell-edition-error': (params) => this.errorStateManager.hasError(params.node.rowIndex + ''),
            },
          },
          {
            headerName: 'Actions',
            cellRendererFramework: EditIconComponent,
            cellRendererParams: (params) => {
              return {
                customActions: {
                  toggle: this.toggleRow.bind(this),
                  update: this.updateTable.bind(this),
                },
                noChangeRequestActions:
                  ![
                    ContactRoleCd.ACCOUNTS_PAYABLE,
                    ContactRoleCd.NOA,
                    ContactRoleCd.DELIVERY,
                    ContactRoleCd.SALES,
                    ContactRoleCd.PICKUP,
                    ContactRoleCd.OPERATIONS,
                  ].includes(params.data.contactRoleCd) && params.data.isLocal
                    ? ['DELETE', 'UPDATE']
                    : [],
                moreDetailsAction:
                  this.isChangeRequest && params.data.actionCd ? null : this.showContactDetails.bind(this),
              };
            },
            sortable: false,
            suppressMenu: true,
            suppressSizeToFit: true,
            width: 97,
            colId: 'action',
            cellClassRules: {},
          },

          {
            headerName: 'Status',
            cellRendererFramework: LineStatusCellRendererComponent,
            sortable: false,
            suppressMenu: true,
            suppressSizeToFit: true,
            editable: false,
            minWidth: 90,
            cellClassRules: {},
            colId: 'status',
            hide: !this.isChangeRequest,
          },
          {
            headerName: 'Relationship',
            sortable: false,
            suppressMenu: true,
            suppressSizeToFit: true,
            editable: false,
            valueGetter: (params) => {
              return params.data.isLocal || this.isChangeRequest ? 'Local' : 'Parent';
            },
          },
          {
            headerName: 'Type',
            field: 'contactRoleCd',
            colId: 'contactRoleCd',
            width: 200,
            suppressMenu: true,
            valueFormatter(params): string {
              return ContactRoleCdLabel[params.value];
            },
          },
          {
            headerName: 'First Name',
            field: 'firstName',
            colId: 'firstName',
            width: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.firstName,
                validators: [
                  ValidatorHelper.required('Name is mandatory'),
                  ValidatorHelper.maxLength(10, 'Must contain up to 10 characters'),
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'firstName',
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Last Name',
            field: 'lastName',
            colId: 'lastName',
            width: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.lastName,
                validators: [ValidatorHelper.maxLength(20, 'Must contain up to 20 characters')],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'lastName',
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Phone',
            width: 285,
            suppressMenu: true,
            field: 'phone',
            colId: 'phone',
            cellRenderer: 'phoneNumberEditor',
            cellRendererParams: (params) => {
              return {
                isEditable: this.isCellEditable(params.node),
                key: 'phone',
                value: {
                  areaCd: params.data.businessPhoneAreaCd,
                  number: params.data.businessPhoneNbr,
                  countryCd: params.data.businessPhoneCountryCd,
                  extension: params.data.businessPhoneExt,
                },
                updateValue: this.updatePhoneData,
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Fax',
            width: 200,
            suppressMenu: true,
            field: 'faxNbr',
            colId: 'faxNbr',
            cellRenderer: 'phoneNumberEditor',
            cellRendererParams: (params) => {
              return {
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'faxNbr',
                value: params.data.faxNbr
                  ? {
                      areaCd: params.data.faxAreaCd,
                      number: params.data.faxNbr,
                    }
                  : null,
                updateValue: this.updateFaxData,
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Email',
            field: 'emailId',
            colId: 'emailId',
            width: 250,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.emailId,
                validators: [
                  ValidatorHelper.maxLength(70, 'Must contain up to 70 characters'),
                  ValidatorHelper.minLength(7, 'Must contain at least 7 characters'),
                  ValidatorHelper.emailFormatValidator(),
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'emailId',
              };
            },
            cellStyle: { padding: '0' },
          },
        ],
        keyField: 'index',
      }),
    ];
  }

  private isCellEditable(node): boolean {
    return (
      node.data.actionCd &&
      node.data.actionCd === 'ADD' &&
      node.expanded &&
      (node.data.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS || node.data.statusCd === CustomerLineStatusCd.HELD)
    );
  }

  updateTable(): void {
    this.refreshGrid();
  }

  refreshGrid(): void {
    this.dataSource.refresh();
  }

  /* This action could be called from parent or child, should be differenciate those use cases */
  toggleRow(params): void {
    if (!this.isNewLocation) {
      let canExpand = false;

      if (params && params.data) {
        if (!params.data.actionCd) {
          return;
        }
        canExpand = true;
      }
      if (canExpand && !params.detail) {
        params.setExpanded(!params.expanded);
        if (params && params.actionParams && params.actionParams.needRefresh) {
          this.refreshGrid();
        }
      }
    }
  }

  gridBoardReady(gridEvent: XpoAgGridBoardReadyEvent): void {
    this.gridApi = gridEvent.agGridApi;
    this.colApi = gridEvent.agGridColumnApi;
    this.gridBoardApi = gridEvent.agGridBoardApi;

    this.store
      .select(getRequestedChangeCurrentLine)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const currLoc = res?.requestCustomerLocationFunction[0];
        this.isNewLocation = !currLoc?.madCd;
        this.colApi.setColumnsVisible(
          ['status'],
          this.isChangeRequestFn() ? !!currLoc?.requestContactCustomerRelationship : false
        );
        this.hasChanges = !!currLoc?.requestContactCustomerRelationship;
        this.notes = res?.requestContactPerson
          ? res?.requestContactPerson[0]?.requestNote
            ? (res?.requestContactPerson[0]?.requestNote.find((n) => n.noteTypeCd === 'General') || ({} as any)).note
            : ''
          : '';
        this.dispatchIsLocalContacts();
      });
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  toggleAllOrLocal(which: 'all' | 'local'): void {
    if ((which === 'all' && this.allSelected) || (which === 'local' && this.localSelected)) {
      return;
    }

    if (this.isChangeRequest) {
      this.store
        .select(getShowChangesOnly)
        .pipe(take(1))
        .subscribe((is) => {
          if (is) {
            this.snackbar.open({
              message: 'Show Changes Only must be unchecked.',
              status: 'error',
              matConfig: {
                duration: ConstantsService.snackbarDuration,
              },
            });
          } else {
            this.setIsLocalOrAllSelected(which);
            this.dispatchIsLocalContacts();
          }
        });
    } else {
      this.setIsLocalOrAllSelected(which);
      this.dispatchIsLocalContacts();
    }
  }

  ngOnInit(): void {
    this.store.pipe(takeUntil(this.destroy$), select(getShowChangesOnly)).subscribe((_) => {
      this.dataSource.refresh();
    });
    this.store.pipe(takeUntil(this.destroy$), select(getChangeRequest)).subscribe((res) => {
      this.customerRequest = res.changeRequest;
      this.dataSource.refresh();
    });
    this.store.pipe(takeUntil(this.destroy$), select(loggedInUserIsOwner)).subscribe((isOwner) => {
      this.loggedInUserIsOwner = isOwner;
    });

    this.userRole$ = this.store.pipe(
      takeUntil(this.destroy$),
      select(getLoggedInUserRole),
      skipWhile((role) => !role)
    );

    this.store.dispatch(new SetShowChangesOnly({ showChangesOnly: true }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updatePhoneData(data: PhoneModel, node: RowNode): void {
    const newData = { ...node.data };
    newData.businessPhoneNbr = data.number;
    newData.businessPhoneAreaCd = data.areaCd;
    newData.businessPhoneCountryCd = data.countryCd;
    newData.businessPhoneExt = data.extension;

    node.setData(newData);
  }

  updateFaxData(data: PhoneModel, node: RowNode): void {
    const newData = { ...node.data };
    newData.faxNbr = data.number;
    newData.faxAreaCd = data.areaCd;

    node.setData(newData);
  }

  addContact(): void {
    this.dialog
      .open(NewContactDialogComponent, { minWidth: 900, data: { action: 'ADD' }, disableClose: true })
      .afterClosed()
      .subscribe((res) => {
        if (res?.confirmed) {
          this.dataSource.refresh();
        }
      });
  }

  showContactDetails(node): void {
    const data = node.data;
    data.isChangeRequest = true;

    this.store
      .select(getCurrentContact, { currentContactId: data.contactPersonId })
      .pipe(take(1))
      .subscribe((contact) => {
        data.contactNote = contact.contactNote;
        this.dialog.open(NewContactDialogComponent, { minWidth: 900, data: data, disableClose: true });
      });
  }

  private setIsLocalOrAllSelected(which): void {
    if (which === 'all') {
      this.allSelected = !this.allSelected;
      this.localSelected = false;
    } else {
      this.localSelected = !this.localSelected;
      this.allSelected = false;
    }
  }

  private dispatchIsLocalContacts(): void {
    this.store.dispatch(new SetLocalContacts({ localContacts: this.localSelected }));
    this.dataSource.refresh();
  }
}
