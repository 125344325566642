export enum CustomerRequestStatusCdLabel {
  Cancel = 'Requester Canceled',
  Completed = 'Completed',
  Expired = 'Expired',
  New = 'Unsubmitted',
  Process = 'In Progress',
  Submitted = 'Submitted',
  WaitApprover = 'Pending Approval',
  WaitRequestor = 'Pending Requester',
}
