import { Component, HostBinding, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AppState } from 'src/app/store';
import { SetCurrentCountry } from 'src/app/store/details/details.actions';
import { Store } from '@ngrx/store';
import { CustomerLineStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-cell-renderer',
  templateUrl: './select-cell-renderer.component.html',
  styleUrls: ['./select-cell-renderer.component.scss'],
})
export class SelectCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  @ViewChild(MatSelect) select: MatSelect;
  type: 'state' | 'country';
  params;
  isEditMode = false;
  options: Observable<any[]>;
  optionChanged$ = new Subject<void>();

  private isChangeRequest;

  constructor(private store: Store<AppState>) {}

  agInit(params): void {
    this.type = params.type;
    this.params = params;
    this.isChangeRequest = window.location.pathname.includes('change-request');

    if (this.params.isEditable) {
      this.isEditMode = !this.isEditMode;

      if (this.type === 'state') {
        this.options = params.options.pipe(takeUntil(this.optionChanged$));
      } else {
        this.options = params.options;
      }
    }
  }

  refresh(): boolean {
    return true;
  }

  onClick(e: MouseEvent): void {
    if (this.params.isEditable) {
      e.stopImmediatePropagation();
    }
  }

  onChange(e: MatSelectChange): void {
    if (this.type === 'country') {
      this.store.dispatch(new SetCurrentCountry({ currentCountrySelected: e.value }));
    } else {
      this.optionChanged$.next();
    }

    const newData = { ...this.params.node.data };
    newData[this.params.key] = e.value;
    this.params.node.setData(newData);
    this.isEditMode = false;

    if (this.params.keepFocus) {
      setTimeout(() => {
        let selector = `[row-index="${this.params.node.rowIndex}"] `;
        selector += `[col-id="${this.params.column.getColId()}"] .container`;

        (<HTMLElement>document.querySelector(selector))?.focus();
      });
    }
  }

  preventClose(e: MouseEvent): void {
    e.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    this.optionChanged$.next();
    this.optionChanged$.complete();
  }

  private isLineProcessable(node): boolean {
    return this.isChangeRequest
      ? node.data.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS || node.data.statusCd === CustomerLineStatusCd.HELD
      : node.expanded;
  }
}
