<!-- TODO: fix class names and markup structure -->
<div class="ncis-Dialog">
  <div class="ncis-Dialog-title" mat-dialog-title>
    <mat-icon class="ncis-Dialog-title-icon" *ngIf="dialog.icon">{{ dialog.icon }}</mat-icon>
    <span class="ncis-Dialog-title-text">{{ dialog.title }}</span>
  </div>
  <div
    class="ncis-Dialog-content"
    [ngClass]="{ 'ncis-Dialog-content--with-icon': dialog.icon }"
    mat-dialog-content
    [innerHTML]="dialog.content"
  ></div>
  <div class="ncis-Dialog-actions" mat-dialog-actions>
    <ng-container *ngFor="let action of dialog.actions; let i = index">
      <div class="cis-dialog-actions-left">
        <ng-container *ngIf="action.position !== 'right'">
          <button
            *ngIf="action.type === 'primary'"
            mat-flat-button
            [ngClass]="{ 'ncis-Dialog--btn-ml-1': i !== 0 }"
            (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
          >
            {{ action.label }}
          </button>
          <button
            *ngIf="action.type === 'secondary'"
            mat-stroked-button
            [ngClass]="{ 'ncis-Dialog--btn-ml-1': i !== 0 }"
            (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
          >
            {{ action.label }}
          </button>
        </ng-container>
      </div>
      <div class="ncis-Dialog-actions-right">
        <ng-container *ngIf="action.position === 'right'">
          <button
            *ngIf="action.type === 'primary'"
            mat-flat-button
            [ngClass]="{ 'ncis-Dialog--btn-ml-1': i !== 0 }"
            (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
          >
            {{ action.label }}
          </button>
          <button
            *ngIf="action.type === 'secondary'"
            mat-stroked-button
            [ngClass]="{ 'ncis-Dialog--btn-ml-1': i !== 0 }"
            (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
          >
            {{ action.label }}
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
