import { Component, ViewEncapsulation } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IFloatingFilter } from 'ag-grid-community';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'date-range-filter-container',
  },
})
export class DateRangeFilterComponent implements IFloatingFilter {
  dateRangeForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  agInit(params): void {
    this.dateRangeForm = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl(),
    });

    this.dateRangeForm.valueChanges.subscribe((value) => {
      if ((value.start !== null && value.end !== null) || (value.start === null && value.end === null)) {
        params.parentFilterInstance(function(instance): void {
          instance.onFloatingFilterChanged(params.field, value.start + '|' + value.end?.endOf('day'));
        });
      }
    });
  }

  clearForm(): void {
    this.dateRangeForm.reset();
  }

  onParentModelChanged(): void {}
}
