<form class="ncis-alias-details-form" [formGroup]="aliasDetailsForm">
  <div class="ncis-alias-details-form-title">ALIAS DETAILS</div>
  <div class="ncis-alias-details-form-full-name">
    <div class="ncis-alias-details-form-field-wrapper">
      <span>NAME 1</span>
      <mat-form-field>
        <input formControlName="name1" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-alias-details-form-field-wrapper">
      <span>NAME 2</span>
      <mat-form-field>
        <input formControlName="name2" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-alias-details-form-address">
    <div class="ncis-alias-details-form-field-wrapper">
      <span>ADDRESS</span>
      <mat-form-field>
        <input formControlName="address" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-alias-details-form-field-wrapper">
      <span>CITY</span>
      <mat-form-field>
        <input formControlName="cityName" matInput type="text" maxlength="20" />
      </mat-form-field>
    </div>
    <div class="ncis-alias-details-form-field-wrapper">
      <span>STATE</span>
      <mat-form-field>
        <mat-select formControlName="stateCd" [value]="aliasDetailsForm.controls['stateCd'].value">
          <mat-option *ngFor="let state of states$ | async" [value]="state.abbreviation">{{ state.abbreviation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ncis-alias-details-form-field-wrapper">
      <span>POSTAL CODE</span>
      <mat-form-field>
        <input formControlName="zipCd" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-alias-details-form-field-wrapper">
      <span>COUNTRY</span>
      <mat-form-field>
        <mat-select formControlName="countryCd" [value]="aliasDetailsForm.controls['countryCd'].value">
          <mat-option *ngFor="let country of countryList" [value]="country">{{ country }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-alias-details-form-notes">
    <div class="ncis-alias-details-form-field-wrapper">
      <span>REMARKS</span>
      <mat-form-field matTooltipPosition="above">
        <input formControlName="lastUpdateRemarks" matInput type="text" maxlength="50" />
      </mat-form-field>
    </div>
  </div>
  <div>
    <button mat-stroked-button (click)="close()">CLOSE</button>
  </div>
</form>
