import { Component, HostListener, OnDestroy, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, RowNode } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss'],
})
export class EditCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  @ViewChildren('input') input;
  value: string = '';
  valueControl: UntypedFormControl;
  isEditing = false;
  isEditable = false;
  currentErrorMessage = '';
  key = '';
  node: RowNode;
  gridApi: GridApi;

  private destroy$ = new Subject();

  constructor(private errorStateManager: ErrorStateManagerService) {}

  agInit(params): void {
    this.value = params.value;
    this.valueControl = new UntypedFormControl(params.value || '', params.validators);
    if (params.asyncValidators) {
      this.valueControl.setAsyncValidators(params.asyncValidators);
    }
    this.isEditable = params.isEditable;
    this.node = params.node;
    this.key = params.key;
    this.gridApi = params.api;
    this.valueControl.markAsTouched();
    if (!this.valueControl.valid && this.isEditable) {
      this.currentErrorMessage = this.errorStateManager.getErrorMessage(params.node.rowIndex + '', this.key);
    }

    this.valueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (this.valueControl.invalid) {
        this.currentErrorMessage = this.getErrorMessage();
      } else {
        this.currentErrorMessage = '';
      }
    });
  }

  refresh(params): boolean {
    return true;
  }

  toggleMode(): void {
    if (this.isEditable) {
      this.isEditing = !this.isEditing;
    }
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent): void {
    if (this.isEditable) {
      // stop event bubbling to prevent quitting edit mode
      e.stopImmediatePropagation();
      if (!this.isEditing) {
        this.toggleMode();
        window.setTimeout(() => {
          this.input.first.nativeElement.focus();
        });
      }
    }
  }

  onFocus(e: MouseEvent): void {
    if (this.isEditable) {
      // stop event bubbling to prevent quitting edit mode
      e.stopImmediatePropagation();
      if (!this.isEditing) {
        this.toggleMode();
        window.setTimeout(() => {
          this.input.first.nativeElement.focus();
        });
      }
    }
  }

  onKeypress(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.toggleMode();
      this.updateValue();
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    // prevent arrow keys causing blur
    if (e.key.includes('Arrow')) {
      e.stopImmediatePropagation();
    }
  }

  onBlur(): void {
    this.toggleMode();
    this.updateValue();
  }

  private updateValue(): void {
    const newData = { ...this.node.data };

    if (!this.valueControl.valid) {
      this.errorStateManager.setErrorState(this.node.rowIndex + '', { id: this.key, message: this.getErrorMessage() });
    } else {
      this.errorStateManager.cleanErrorState(this.node.rowIndex + '', this.key);
    }

    switch (this.key) {
      case 'zipCd':
        const [zip6, zip4] = (this.valueControl.value || '').split('-');

        newData.zipCd = zip6;
        newData.zip4Cd = zip4;
        this.value = this.valueControl.value;
        break;
      default:
        newData[this.key] = this.valueControl.value;
        this.value = this.valueControl.value;
        break;
    }

    this.node.setData(newData);
  }

  private getErrorMessage(): string {
    return Object.keys(this.valueControl.errors).reduce((acc, curr) => `${acc}${this.valueControl?.errors[curr]}. `, '');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
