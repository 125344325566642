import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { PhoneModel } from 'src/app/shared/models/phone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PhoneNumberInputComponent,
    },
  ],
  host: {
    class: 'phone-number-input-host',
  },
})
export class PhoneNumberInputComponent
  implements OnDestroy, OnInit, MatFormFieldControl<PhoneModel>, ControlValueAccessor {
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = !!req;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = !!value;
    this._disabled ? this.phoneNumberForm?.disable() : this.phoneNumberForm?.enable();
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  set value(phone: PhoneModel) {
    this.stateChanges.next();
  }

  get value(): PhoneModel {
    return {
      areaCd: this.phoneNumberForm.value.phoneAreaCd,
      countryCd: this.phoneNumberForm.value.phoneCountryCd,
      number: this.phoneNumberForm.value.phonePrefix + this.phoneNumberForm.value.phoneSuffix,
      extension: this.phoneNumberForm.value.phoneExtension,
    };
  }

  get empty(): boolean {
    const n = this.phoneNumberForm.value;
    return !n.phoneCountryCd && !n.phoneAreaCd && !n.phonePrefix && !n.phoneSuffix && !n.phoneExtension;
  }

  get errorState(): boolean {
    return this.ngControl?.control?.invalid;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() public parentFormField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  static nextId = 0;
  @ViewChild('phonePrefix') phonePrefix;
  @ViewChild('phoneSuffix') phoneSuffix;
  @ViewChild('phoneAreaCd') phoneAreaCd;
  @Input() showCountryCd: boolean;
  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() showPhoneExtension: boolean;
  @HostBinding() id: string = `phone-number-input-${PhoneNumberInputComponent.nextId}`;
  @Output() formFocusedOut = new EventEmitter<HTMLElement>();

  phoneNumberForm: UntypedFormGroup;
  stateChanges = new Subject<void>();
  placeholder: string;
  focused: boolean;
  controlType ? = 'mat-input';
  autofilled?: boolean;
  onChange;
  onTouched;

  private _required = false;
  private _disabled = false;
  private destroy$ = new Subject();

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement.querySelector('.phone-number-input-container');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  ngOnInit(): void {
    this.phoneNumberForm = this.fb.group({
      phoneCountryCd: [{ value: this.ngControl?.control?.value?.countryCd, disabled: this.disabled }],
      phoneAreaCd: [{ value: this.ngControl?.control?.value?.areaCd, disabled: this.disabled }],
      phonePrefix: [{ value: this.ngControl?.control?.value?.number?.slice(0, 3), disabled: this.disabled }],
      phoneSuffix: [{ value: this.ngControl?.control?.value?.number?.slice(3), disabled: this.disabled }],
      phoneExtension: [{ value: this.ngControl?.control?.value?.extension, disabled: this.disabled }],
    });

    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.phoneNumberForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (this.onChange) {
        this.onChange(this.value);
      }

      if (this.onTouched) {
        this.onTouched();
      }
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.destroy$.next();
    this.destroy$.complete();
  }

  areaCdChange(e): void {
    if (e.target.value.length === 3) {
      this.phonePrefix.nativeElement.focus();
    }
  }

  suffixChange(e): void {
    if (e.target.value.length === 3) {
      this.phoneSuffix.nativeElement.focus();
    }
  }

  emitBlur(e): void {
    this.formFocusedOut.emit(e.relatedTarget);
  }

  onKeyDown(event: KeyboardEvent): void {
    const regex = /\d/;

    const ctrl = event.ctrlKey;
    const cmd_held = event.metaKey;

    if (
      !(
        ctrl ||
        cmd_held ||
        (event.key === '86' && ctrl) ||
        (event.key === '67' && ctrl) ||
        (event.key === '86' && cmd_held) ||
        (event.key === '67' && cmd_held)
      )
    ) {
      if (
        [
          !regex.test(event.key),
          !event.key.includes('Arrow'),
          event.key !== 'Backspace',
          event.key !== 'Tab',
          event.key !== 'Shift',
          event.key !== 'Enter',
        ].reduce((acc, curr) => acc && curr, true)
      ) {
        event.preventDefault();
      }
    }
  }
}
