import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppRoutes } from '../../../../shared/enums/app-routes.enum';

@Component({
  selector: 'app-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  host: {
    class: 'ncis-LinkCellRenderer',
  },
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  locationPath: string;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
