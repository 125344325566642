<div class="appWrapper">
  <xpo-shell class="xpoShell" [routes]="routes | async" drawerClosedByDefault="true" [versionNumber]="build">
    <xpo-header-app-name>Edge</xpo-header-app-name>
    <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
    <xpo-header-actions>
      <app-quick-search></app-quick-search>
      <xpo-auth-app-switcher></xpo-auth-app-switcher>
      <xpo-auth-user-profile></xpo-auth-user-profile>
    </xpo-header-actions>
    <router-outlet></router-outlet>
  </xpo-shell>
</div>
<app-notification></app-notification>
