import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import {
  ApproveRequestResp,
  CustomerApiService,
  CustomerRequest,
  GetRequestCorporateHierarchyResp,
  GetRequestResp,
  RequestNote,
  UpdateCustomerRequestRqst,
  UpdateRequestCoderRqst,
} from '@xpo-ltl-2.0/sdk-customer';
import { HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerDetailCd, CustomerLineStatusCd, CustomerRequestStatusCd } from '@xpo-ltl/sdk-common';
import { CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { AppState } from 'src/app/store';
import {
  SetChangeRequest,
  SetEmployees,
  SetRequestedChangesCurrentLine,
  SetRequestedChangesCurrentLocation,
} from 'src/app/store/change-request/change-request.action';
import { getChangeRequestData, getChangeRequestId } from 'src/app/store/change-request/change-request.selectors';
import { CompleteRequestDialogComponent } from '../../shared/components/complete-request-dialog/complete-request-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestService {
  private corporateHierarchy: GetRequestCorporateHierarchyResp;
  private refreshRequestSummary$ = new BehaviorSubject<void>(null);

  constructor(
    private store: Store<AppState>,
    private snackbar: XpoSnackBar,
    private customerApi: CustomerApiService,
    private hrApiService: HumanResourceApiService,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {}

  getChangeRequestDetailAPI(changeRequestId, requestIdType = 'requestId'): Observable<GetRequestResp[]> {
    const source = forkJoin([
      this.customerApi.getRequest(
        { id: changeRequestId },
        { requestIdType: requestIdType, changes: false, customerLocationFuncId: null, requestFuncSequenceNbr: null }
      ),
      this.customerApi.getRequest(
        { id: changeRequestId },
        { requestIdType: requestIdType, changes: true, customerLocationFuncId: null, requestFuncSequenceNbr: null }
      ),
    ]);

    return source.pipe(catchError((err) => throwError(err)));
  }

  getRequestGroups(): Observable<GetRequestCorporateHierarchyResp> {
    return this.store.pipe(
      select(getChangeRequestId),
      switchMap((id) => {
        return this.customerApi
          .getRequestCorporateHierarchy({ id: id.toString() }, { requestIdType: 'requestId' })
          .pipe(
            take(1),
            map((res) => {
              this.corporateHierarchy = res;
              return res;
            })
          );
      }),
      take(1)
    );
  }

  getChangeRequestDetails(requestId, forceApiRequest = false): Observable<CustomerRequest> {
    return new Observable((observer) => {
      let data: CustomerRequest;

      this.store
        .select(getChangeRequestData)
        .pipe(take(1))
        .subscribe(
          (changeRequest) => {
            if (!changeRequest || changeRequest.cstRequestId.toString() !== requestId.toString() || forceApiRequest) {
              this.getChangeRequestDetailAPI(requestId)
                .pipe(
                  take(1),
                  tap((resp) => {
                    const ids = [];

                    if (resp[0].request.coderEmployeeId) {
                      ids.push(resp[0].request.coderEmployeeId);
                    }
                    if (resp[0].request.approverEmployeeId && !ids.includes(resp[0].request.approverEmployeeId)) {
                      ids.push(resp[0].request.approverEmployeeId);
                    }
                    if (resp[0].request.submittedBy && !ids.includes(resp[0].request.submittedBy)) {
                      ids.push(resp[0].request.submittedBy);
                    }

                    forkJoin(
                      ids.map((id) => {
                        return this.hrApiService.getInterfaceEmployee({ employeeId: id }).pipe(
                          catchError((e) => {
                            return throwError(e);
                          })
                        );
                      })
                    ).subscribe((employeesData) => {
                      this.store.dispatch(new SetEmployees({ employees: employeesData }));
                    });

                    return resp;
                  })
                )
                .subscribe(
                  ([changeRequestDetails, changesOnly]) => {
                    data = changeRequestDetails.request;
                    this.store.dispatch(
                      new SetChangeRequest({
                        changeRequest: data,
                        changeRequestChangesOnly: changesOnly.request,
                        requestIdType: '',
                      })
                    );
                    observer.next(data);
                    observer.complete();
                  },
                  (err) => observer.error(err)
                );
            } else {
              data = changeRequest;
              observer.next(data);
              observer.complete();
            }
          },
          (err) => observer.error(err)
        );
    });
  }

  approveCustomerRequest(
    customerRequest: CustomerRequest,
    lineStatus,
    comment: AbstractControl,
    requestType: string,
    line: any,
    ignoreDuplicateCheck = false,
    ignoreAddressValidation = false
  ): Observable<any> {
    this.checkLineStatus(customerRequest, lineStatus);
    line.requestNote = [];

    if (comment.value) {
      const note = {
        note: comment.value,
      } as any;

      line.requestNote.push(note);
    }

    customerRequest.requestNote = [];

    line.cstRequestId = customerRequest.cstRequestId;

    const location = customerRequest.requestCustomerLocationFunction[0];
    const ignoreRule = location?.functionCd === CustomerFunctionCd.BILL_TO && !!location?.serviceRecipientNbr;

    const payload: any = {
      request: customerRequest,
      customerDetailCd: requestType,
      ignoreDuplicateCheckInd: ignoreDuplicateCheck,
      ignoreAddressValidation: ignoreRule ? true : ignoreAddressValidation,
      pstlCertifiedInd: null,
    };

    return this.approveRequest(payload, customerRequest, requestType === CustomerDetailCd.ALIAS);
  }

  private approveRequest(payload, customerRequest, isAlias = false): Observable<ApproveRequestResp> {
    this.utilsService.objValuesToUpperCase(payload);
    return this.customerApi.approveRequest(payload, { loadingOverlayEnabled: true }).pipe(
      catchError((err) => this.displayErrorMessage(err)),
      switchMap((res) => {
        switch (true) {
          case !payload.ignoreAddressValidation && payload.customerDetailCd === CustomerDetailCd.ACCOUNT:
            this.utilsService.objValuesToUpperCase(res.finalStandardAddress);
            return this.utilsService
              .confirmAddress(
                res.finalStandardAddress,
                payload.request.requestCustomerLocationFunction[0],
                payload.request.requestCustomerLocationFunction[0].name1,
                res.customerAddressValidationCd
              )
              .pipe(
                switchMap(({ address, latitude, longitude }) => {
                  if (address && address.hasOwnProperty('address')) {
                    payload.ignoreAddressValidation = true;
                    payload.request.requestCustomerLocationFunction[0].address = address.address;
                    payload.request.requestCustomerLocationFunction[0].cityName = address.cityName;
                    payload.request.requestCustomerLocationFunction[0].countryCd = address.countryCd;
                    payload.request.requestCustomerLocationFunction[0].stateCd = address.stateCd;
                    payload.request.requestCustomerLocationFunction[0].zipCd = address.zipCd;
                    payload.request.requestCustomerLocationFunction[0].zip4Cd = address.zip4Cd || '';
                    payload.request.requestCustomerLocationFunction[0].geoCoordinates = {
                      latitude: latitude,
                      longitude: longitude,
                    };
                    payload.pstlCertifiedInd = 'N';
                    return this.approveRequest(payload, customerRequest);
                  } else if (address && address.hasOwnProperty('addressLine1')) {
                    payload.ignoreAddressValidation = true;
                    payload.request.requestCustomerLocationFunction[0].address = address.addressLine1;
                    payload.request.requestCustomerLocationFunction[0].cityName = address.cityName;
                    payload.request.requestCustomerLocationFunction[0].countryCd = address.countryCd;
                    payload.request.requestCustomerLocationFunction[0].stateCd = address.stateCd;
                    payload.request.requestCustomerLocationFunction[0].zipCd = this.removeSpaces(address.postalCd);
                    payload.request.requestCustomerLocationFunction[0].zip4Cd = address.usZip4 || '';
                    payload.request.requestCustomerLocationFunction[0].geoCoordinates = {
                      latitude: address.geoCoordinates.latitude,
                      longitude: address.geoCoordinates.longitude,
                    };
                    payload.pstlCertifiedInd = 'Y';
                    return this.approveRequest(payload, customerRequest);
                  } else {
                    return EMPTY;
                  }
                })
              );
          case res.complete:
            this.getRequestedChangesForLocation(
              customerRequest.cstRequestId,
              customerRequest.requestCustomerLocationFunction[0].customerLocationFuncId,
              customerRequest.requestCustomerLocationFunction[0].requestFuncSequenceNbr
            );
            return this.dialog
              .open(CompleteRequestDialogComponent, { minWidth: 600, disableClose: true })
              .afterClosed()
              .pipe(
                switchMap(() => {
                  return this.getChangeRequestDetails(customerRequest.cstRequestId, true);
                })
              );
          case res.matchedCustomers?.length > 0:
            return this.utilsService.duplicateMatch(
              res.matchedCustomers,
              isAlias,
              [payload, customerRequest],
              this.approveRequest.bind(this)
            );
          default:
            this.getRequestedChangesForLocation(
              customerRequest.cstRequestId,
              customerRequest.requestCustomerLocationFunction[0].customerLocationFuncId,
              customerRequest.requestCustomerLocationFunction[0].requestFuncSequenceNbr
            );
            return this.getChangeRequestDetails(customerRequest.cstRequestId, true);
        }
      })
    );
  }

  private removeSpaces(str: string): string {
    return str.replace(/\s/g, '');
  }

  rejectHoldCustomerRequest(
    customerRequest: CustomerRequest,
    lineStatus,
    comment: AbstractControl,
    action: string,
    requestType: string,
    status: string,
    line: any
  ): any {
    this.checkLineStatus(customerRequest, lineStatus);

    const note = {
      note: comment.value,
    } as any;

    line.requestNote = [note];
    line.statusCd = status;
    line.cstRequestId = customerRequest.cstRequestId;

    const request: UpdateCustomerRequestRqst = {
      customerRequest: customerRequest,
      requestTypeCd: requestType,
      statusCd: status.toUpperCase(),
    };
    return this.rejectHoldRequest(request, customerRequest);
  }

  private rejectHoldRequest(request, customerRequest): Observable<any> {
    return this.customerApi.updateCustomerRequest(request, { loadingOverlayEnabled: true }).pipe(
      catchError((err) => this.displayErrorMessage(err)),
      switchMap((resp) => {
        this.getRequestedChangesForLocation(
          customerRequest.cstRequestId,
          customerRequest.requestCustomerLocationFunction[0].customerLocationFuncId,
          customerRequest.requestCustomerLocationFunction[0].requestFuncSequenceNbr
        );

        if (resp.complete) {
          return this.dialog
            .open(CompleteRequestDialogComponent, { minWidth: 600, disableClose: true })
            .afterClosed()
            .pipe(
              switchMap((_) => {
                return this.getChangeRequestDetails(customerRequest.cstRequestId, true);
              })
            );
        } else {
          return this.getChangeRequestDetails(request.customerRequest.cstRequestId, true);
        }
      })
    );
  }

  checkLineStatus(customerRequest, lineStatus): any {
    if (customerRequest.statusCd === CustomerRequestStatusCd.PROCESS) {
      switch (lineStatus) {
        case CustomerLineStatusCd.SALES_PENDING: {
          this.snackbar.open({
            message: 'You cannot approve a line awaiting for approval.',
            status: 'error',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          return of();
        }
        case CustomerLineStatusCd.PENDING_CREDIT: {
          this.snackbar.open({
            message: 'You cannot approve a line awaiting for credit review.',
            status: 'error',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          return of();
        }
        case CustomerLineStatusCd.APPROVED: {
          this.snackbar.open({
            message: 'You cannot approve an approved line.',
            status: 'error',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          return of();
        }
      }
    } else {
      this.snackbar.open({
        message: 'You can only make changes to requests that are Work-in-Progress status.',
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
      return of();
    }
  }

  approveInvoiceCustomerRequest(
    customerRequest: CustomerRequest,
    lineStatus,
    comment: AbstractControl,
    requestType: string,
    line: any
  ): any {
    this.checkLineStatus(customerRequest, lineStatus);
    const invoices = cloneDeep(line.requestInvoicePreference);

    invoices.forEach((invoice) => {
      invoice.requestNote = [];
      invoice.statusCd = CustomerLineStatusCd.APPROVED;
      invoice.cstRequestId = customerRequest.cstRequestId;
    });

    if (comment.value) {
      invoices[0].requestNote = [
        {
          note: comment.value,
        },
      ];
    }

    line.requestInvoicePreference = invoices;

    const payload: any = {
      request: customerRequest,
      customerDetailCd: requestType,
    };
    return this.approveRequest(payload, customerRequest);
  }

  rejectHoldInvoiceCustomerRequest(
    customerRequest: CustomerRequest,
    lineStatus,
    comment: AbstractControl,
    action: string,
    requestType: string,
    status: string,
    line: any
  ): any {
    this.checkLineStatus(customerRequest, lineStatus);
    const invoices = cloneDeep(line.requestInvoicePreference);

    invoices.forEach((invoice) => {
      invoice.requestNote = [];
      invoice.statusCd = status;
      invoice.cstRequestId = customerRequest.cstRequestId;
    });

    invoices[0].requestNote = [
      {
        note: comment.value,
      },
    ];

    line.requestInvoicePreference = invoices;

    const request: UpdateCustomerRequestRqst = {
      customerRequest: customerRequest,
      requestTypeCd: requestType,
      statusCd: status.toUpperCase(),
    };
    return this.rejectHoldRequest(request, customerRequest);
  }

  lockUnLockRequest(cstRequestId, hasOwner): void {
    const request: UpdateRequestCoderRqst = {
      cstRequestId: cstRequestId,
      unlock: hasOwner,
    };
    this.customerApi
      .updateRequestCoder(request, {
        toastErrorMessage: 'Another coder is already working on this request.  Please refresh page.',
        toastOnError: true,
        loadingOverlayEnabled: true,
      })
      .subscribe((_) => {
        this.getChangeRequestDetails(cstRequestId, true)
          .pipe(take(1))
          .subscribe();
      });
  }

  getFirstLocationFromCorporateHierarchy(): any {
    const parents = this.corporateHierarchy.corporateHierarchy;

    if (parents && parents.length > 0) {
      if (this.corporateHierarchy.corporateHierarchy[0].newParentCorpLineSequenceNbr) {
        return this.corporateHierarchy.corporateHierarchy[0];
      } else {
        return this.corporateHierarchy.corporateHierarchy[0].requestCustomerLocationFunctionBoundedList[0];
      }
    } else {
      return this.corporateHierarchy.orphanLocations[0];
    }
  }

  getRequestedChangesForLocation(changeRequestId, locationId, seqNbr?): void {
    if (changeRequestId) {
      this.customerApi
        .getRequest(
          { id: changeRequestId },
          {
            changes: true,
            customerLocationFuncId: locationId,
            requestFuncSequenceNbr: seqNbr,
            requestIdType: 'requestId',
          }
        )
        .subscribe((resp) => {
          if (seqNbr) {
            this.store.dispatch(new SetRequestedChangesCurrentLine({ requestChangesCurrentLine: resp.request }));
          } else {
            this.store.dispatch(
              new SetRequestedChangesCurrentLocation({ requestedChangesCurrentLocation: resp.request })
            );
          }
        });
    }
  }

  getProcessNote(notes: RequestNote[] = []): RequestNote | null {
    let processNote;

    for (const note of notes) {
      if (note.noteTypeCd === 'Specialist') {
        processNote = note;
      }
    }

    return processNote;
  }

  notifyRefreshRequestSummary(): void {
    this.refreshRequestSummary$.next();
  }

  refreshRequestSummaryListen(): BehaviorSubject<void> {
    return this.refreshRequestSummary$;
  }

  private displayErrorMessage(err): Observable<never> {
    if (err.error.message.toLowerCase().includes('validation errors')) {
      this.snackbar.open({
        message: err.error.moreInfo[0].message,
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    } else {
      this.snackbar.open({
        message: err.error.message,
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    }
    return throwError(err);
  }
}
