import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromChangeRequest from './change-request/change-request.reducer';
import * as fromCustomerHistory from './customer-history/customer-history.reducer';
import * as fromDetails from './details/details.reducer';
import * as fromInvoice from './invoice/invoice.reducer';
import * as fromLocation from './location/location.reducer';
import * as fromSearch from './search-request/search-request.reducer';
import * as fromServiceRecipient from './service-recipient/service-recipient.reducer';
import * as fromUser from './user/user.reducer';

export interface AppState {
  customerLocation: fromLocation.LocationState;
  changeRequest: fromChangeRequest.ChangeRequestState;
  details: fromDetails.DetailsState;
  search: fromSearch.SearchRequestState;
  invoice: fromInvoice.InvoiceState;
  serviceRecipient: fromServiceRecipient.ServiceRecipientState;
  customerHistory: fromCustomerHistory.CustomerHistoryState;
  user: fromUser.UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  customerLocation: fromLocation.locationReducer,
  changeRequest: fromChangeRequest.changeRequestReducer,
  details: fromDetails.detailsReducer,
  search: fromSearch.searchRequestReducer,
  invoice: fromInvoice.invoiceReducer,
  customerHistory: fromCustomerHistory.customerHistoryReducer,
  serviceRecipient: fromServiceRecipient.serviceRecipientReducer,
  user: fromUser.userReducer,
};
