export enum CustomerInfoGroup {
  BusinessNameAliases = 'Business Name Aliases',
  BusinessNameAlias = 'Business Name Aliases',
  Contacts = 'Contacts',
  Contact = 'Contacts',
  Financial = 'Financial',
  AccountInformation = 'Account Information',
  ServiceRecipient = 'Service Recipient',
  TrueDebtor = 'True Debtor',
  NameAndAddress = 'Account Information',
  BasicInfo = 'Account Information',
  InvoiceInstruction = 'Invoice Instructions',
}

export enum LocationTypeTranslate {
  'Bill-To' = 'Bill To',
  PickupOrDelivery = 'Pickup & Delivery',
  Corporate = 'Corporate',
}

export enum CustomerInfoGroupStatus {
  PROCESSED = 'success',
  ON_HOLD = 'warn',
  UNPROCESSED = '',
  SUBMITTED = 'submitted',
}

export enum ChangesStatusIcons {
  APPROVED = 'check_circle',
  REJECTED = 'cancel',
  ON_HOLD = 'lens',
}

export enum CustomerActionsGroup {
  DELETE = 'Delete',
  ADD = 'Add',
  UPDATE = 'Update',
}
