import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ErrorState } from '../models/error-state';

@Injectable({
  providedIn: 'root',
})
export class ErrorStateManagerService {
  private errors = new Map<string, ErrorState[]>();
  private errorsChange = new BehaviorSubject<void>(null);

  constructor() {}

  setErrorState(key: string, value: ErrorState): void {
    if (this.errors.has(key)) {
      this.errors.get(key).push(value);
    } else {
      this.errors.set(key, [value]);
    }
    this.errorsChange.next();
  }

  cleanErrorState(key: string, value: string): void {
    if (this.errors.has(key)) {
      let errors = this.errors.get(key);
      if (errors.length === 1) {
        this.errors.delete(key);
      } else {
        errors = errors.filter((e) => e.id !== value);
        this.errors.set(key, errors);
        if (errors.length === 0) {
          this.errors.delete(key);
        }
      }
    }
    this.errorsChange.next();
  }

  removeKeyErrors(key: string): void {
    if (this.errors.has(key)) {
      this.errors.delete(key);
      this.errorsChange.next();
    }
  }

  hasError(key: string, value?: string): boolean {
    if (!value) {
      return this.errors.has(key);
    } else if (this.errors.has(key)) {
      return this.errors.get(key).some((e) => e.id === value);
    } else {
      return false;
    }
  }

  getErrorMessage(id: string, key: string): string {
    return this.errors.has(id) ? this.errors.get(id).find((e) => e.id === key)?.message : '';
  }

  clearAll(): void {
    this.errors.clear();
  }

  errorChanges(): Observable<void> {
    return this.errorsChange;
  }
}
