<div class="ncis-ContactRowForm">
  <div class="ncis-ContactRowForm-ribbon"></div>
  <div class="ncis-ContactRowForm-action"></div>
  <div class="ncis-ContactRowForm-wrapper">
    <form
      class="ncis-ContactRowForm-form"
      [formGroup]="contactForm"
      *ngIf="contactForm"
      autocomplete="none"
      ncisXpoFocusOnSubmit
    >
      <ng-container *ngIf="actionType === 'UPDATE'">
        <span class="ncis-ContactRowForm-label">{{ actionText }}</span>
        <div class="ncis-InputRow">
          <mat-form-field>
            <input type="text" formControlName="contactRoleCd" matInput readonly />
          </mat-form-field>
          <mat-form-field [ngClass]="{ 'value-changed': valueHasChanged('firstName') }">
            <input
              matInput
              formControlName="firstName"
              autocomplete="off"
              maxlength="30"
              [matTooltip]="getErrorMessage('firstName')"
            />
          </mat-form-field>
          <mat-form-field [ngClass]="{ 'value-changed': valueHasChanged('lastName') }">
            <input
              matInput
              formControlName="lastName"
              autocomplete="off"
              maxlength="30"
              [matTooltip]="getErrorMessage('lastName')"
            />
          </mat-form-field>
          <mat-form-field
            style="min-width: 250px;"
            [matTooltip]="getErrorMessage('phoneNbr')"
            [ngClass]="{ 'value-changed': phoneNbrHasChanged() }"
          >
            <app-phone-number-input formControlName="phoneNbr" [showCountryCd]="true" [showPhoneExtension]="true">
            </app-phone-number-input>
          </mat-form-field>
          <mat-form-field [matTooltip]="getErrorMessage('faxNbr')" [ngClass]="{ 'value-changed': faxNbrHasChanged() }">
            <app-phone-number-input formControlName="faxNbr" [showPhoneExtension]="false"></app-phone-number-input>
          </mat-form-field>
          <mat-form-field [ngClass]="{ 'value-changed': valueHasChanged('emailId') }">
            <input
              matInput
              formControlName="emailId"
              autocomplete="off"
              [matTooltip]="getErrorMessage('emailId')"
              maxlength="70"
            />
          </mat-form-field>
        </div>
        <a
          *ngIf="!isChangeRequest && actionType === 'UPDATE'"
          class="ncis-edit-additional-details"
          role="button"
          (click)="openEditDialog()"
          >Edit Additional Contact Details</a
        >
      </ng-container>
      <a *ngIf="isChangeRequest" class="ncis-edit-additional-details" role="button" (click)="openContactDetailsDialog()"
        >Show Additional Contact Details</a
      >

      <span class="ncis-ContactRowForm-label" *ngIf="isChangeRequest">Remarks</span>
      <div class="ncis-ContactRowForm-remarks">
        <ng-container *ngIf="isChangeRequest; else isOutside">
          <mat-form-field class="ncis-CommentsInput" floatLabel="always">
            <textarea
              [readonly]="!loggedInUserIsOwner || statusIsApproveOrRejected"
              matInput
              formControlName="comments"
              matTextareaAutosize
              matAutosizeMinRows="6"
              matAutosizeMaxRows="6"
              maxLength="800"
            >
            </textarea>
            <mat-error
              *ngIf="contactForm?.controls?.comments?.errors && contactForm?.controls?.comments?.errors.noWhiteSpace"
            >
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-error
              *ngIf="contactForm?.controls?.comments?.errors && contactForm?.controls?.comments?.errors.required"
            >
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-hint align="end">{{ getRemainingCharacters() }} characters</mat-hint>
          </mat-form-field>
        </ng-container>
        <ng-template #isOutside>
          <mat-form-field>
            <input
              type="text"
              formControlName="comments"
              autocomplete="off"
              matInput
              [matTooltip]="getErrorMessage('comments')"
            />
          </mat-form-field>
        </ng-template>
      </div>
      <ng-container *ngIf="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async | not">
        <div
          *ngIf="isChangeRequest ? loggedInUserIsOwner : true"
          class="ncis-ContactRowForm-actions"
          [class.ncis-ContactRowForm-actions--spaceBetween]="isChangeRequest"
        >
          <ng-container *ngIf="isChangeRequest; else editActions">
            <div class="ncis-ContactRowFormActions-group">
              <button
                id="approve-contact"
                mat-flat-button
                type="submit"
                [disabled]="!isAbleToProcess || isCompleted"
                appDebounceClick
                (debounceClick)="approveRequest()"
                [debounceTime]="700"
              >
                APPROVE
              </button>
              <button
                id="reject-contact"
                mat-stroked-button
                [disabled]="statusIsApproveOrRejected || isCompleted"
                appDebounceClick
                (debounceClick)="rejectRequest()"
                [debounceTime]="700"
              >
                REJECT
              </button>
              <button
                id="hold-contact"
                mat-stroked-button
                [disabled]="statusIsApproveOrRejected || statusIsHeld || isCompleted"
                appDebounceClick
                (debounceClick)="holdRequest()"
                [debounceTime]="700"
              >
                HOLD
              </button>
            </div>
            <button id="cancel-contact" mat-stroked-button (click)="closeForm()">CANCEL</button>
          </ng-container>
          <ng-template #editActions>
            <div class="ncis-ContactRowFormActions-group">
              <button
                id="save-contact"
                [disabled]="contactForm.invalid"
                appDebounceClick
                (debounceClick)="updateCustomer(contactForm.value)"
                [debounceTime]="700"
                mat-flat-button
                type="submit"
              >
                SAVE
              </button>
              <button id="cancel-contact" mat-stroked-button type="button" (click)="closeForm()">CANCEL</button>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </form>
  </div>
</div>
