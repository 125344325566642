<div style="padding: 16px 16px 0 16px;" mat-dialog-content>
  <div class="confirm-bulk-delete-header cdk-cursor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Bulk Delete
  </div>
  <div>Do you want to continue with expiring {{ totalLocations }} locations?</div>
</div>
<div class="confirm-bulk-delete-actions" mat-dialog-actions>
  <button id="approve-bulk" mat-flat-button type="submit" (click)="startBulkExpire()">
    APPROVE
  </button>
  <button id="cancel-bulk" mat-stroked-button (click)="cancel()">
    CANCEL
  </button>
</div>
