import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { reducers } from 'src/app/store/index';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const ROOT_REDUCER = new InjectionToken<any>('Root Reducer', { factory: () => reducers });
@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forRoot(ROOT_REDUCER), StoreDevtoolsModule.instrument({ maxAge: 10 })],
})
export class NcisStoreModule {}
