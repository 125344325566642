import { ServiceRecipient } from '@xpo-ltl-2.0/sdk-customer';
import { ServiceRecipientActionTypes } from './service-recipient.actions';

export interface ServiceRecipientState {
  isProfile: boolean;
  currentServiceRecipient: ServiceRecipient;
}

export const initialState: ServiceRecipientState = {
  isProfile: false,
  currentServiceRecipient: null,
};

export const serviceRecipientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ServiceRecipientActionTypes.GetIsProfile:
    case ServiceRecipientActionTypes.GetCurrentServiceRecipient:
      return { ...state };
    case ServiceRecipientActionTypes.SetIsProfile:
    case ServiceRecipientActionTypes.SetCurrentServiceRecipient:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
