import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { LoginGuard } from './shared/guards/login/login.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    loadChildren: () => import('./not-authorized-page/not-authorized-page.module').then((m) => m.NotAuthorizedPageModule),
  },
  {
    path: AppRoutes.DASHBOARD_CODER_PAGE,
    loadChildren: () => import('./dashboard-coder-page/dashboard-coder-page.module').then((m) => m.DashboardCoderPageModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.CIS_SEARCH_PAGE,
    loadChildren: () => import('./cis-search-page/cis-search-page.module').then((m) => m.CisSearchPageModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.CHANGE_REQUEST_PAGE,
    loadChildren: () => import('./change-request-page/change-request-page.module').then((m) => m.ChangeRequestPageModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.LOCATION_PAGE,
    loadChildren: () => import('./location-details-page/location-details.module').then((m) => m.LocationDetailsModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.SERVICE_RECIPIENT_PAGE,
    loadChildren: () => import('./service-recipient-page/service-recipient-page.module').then((m) => m.ServiceRecipientPageModule),
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.CREDIT_MANAGEMENT_PAGE,
    loadChildren: () =>
      import('./credit-group-management-page/credit-group-management-page.module').then((m) => m.CreditGroupManagementPageModule),
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.CUSTOMER_SEARCH_PAGE,
    loadChildren: () => import('./customer-search-page/customer-search-page.module').then((m) => m.CustomerSearchPageModule),
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.PND_MERGE_PAGE,
    loadChildren: () => import('./pnd-merge-page/pnd-merge-page.module').then((m) => m.PnDMergePageModule),
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: AppRoutes.CREATE_CUSTOMER_PAGE,
    loadChildren: () => import('./create-costumer-page/create-costumer-page.module').then((m) => m.CreateCostumerModule),
    canActivate: [XpoAuthenticationGuard, LoginGuard],
  },
  {
    path: '**',
    redirectTo: `/${AppRoutes.DASHBOARD_CODER_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
