export enum ContactRoleCdLabel {
  AccountsPayable = 'Accounts Payable',
  BalanceDue = 'Balance Due',
  Claims = 'Claims',
  Delivery = 'Delivery',
  EDI = 'EDI',
  Invoicing = 'Invoicing',
  NOA = 'NOA',
  Operations = 'Operations',
  OSD = 'OS And D',
  PaymentPlan = 'Payment Plan',
  Pickup = 'Pickup',
  Purchasing = 'Purchasing',
  Sales = 'Sales',
}
