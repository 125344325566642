import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { getEmployees } from 'src/app/store/change-request/change-request.selectors';

@Pipe({
  name: 'employeeNameJobDescription',
})
export class EmployeeNameJobDescriptionPipe implements PipeTransform {
  removeRoles = ['ANALYST', 'ASSOCIATE', 'SPECIALIST', 'CWR', 'FIELD', 'DIRECTOR', 'SENIOR', 'MANAGER', 'SUPERVISOR'];
  shortJobDesc: string = '';

  constructor(private store: Store<AppState>) {}

  transform(value: string): Observable<string> {
    return this.store.pipe(
      select(getEmployees),
      map((employees) => {
        if (!value || (employees && !employees.length)) {
          return '';
        }

        const employee = employees.find((emp) => emp?.interfaceEmployee?.employeeId === value);
        let jobDesc = employee?.interfaceEmployee?.jobDescription;

        if (employee) {
          console.log('Job Description: ' + jobDesc);

          jobDesc = jobDesc
            .replace(/ *\([^)]*\) */g, '')
            .replace('II', '')
            .replace(' I ', '')
            .replace(',', '')
            .replace('TO', '2')
            .replace('-', '')
            .trim();
          this.removeRoles.forEach((role) => {
            jobDesc = jobDesc
              .toLowerCase()
              .replace(role.toLowerCase(), '')
              .trim();
          });
          this.shortJobDesc = jobDesc
            .replace('CUSTOMER SERVICE REPRESENTATIVE'.toLowerCase(), 'CSR')
            .replace('CUSTOMER SERVICE REP'.toLowerCase(), 'CSR')
            .replace('LOCAL ACCOUNT EXECUTIVE'.toLowerCase(), 'LAE')
            .replace('NATIONAL ACCOUNT EXECUTIVE'.toLowerCase(), 'NAE')
            .replace('INSIDE ACCOUNT EXECUTIVE'.toLowerCase(), 'IAE')
            .replace('ORDER TO CASH'.toLowerCase(), 'O2C');

          return employee
            ? employee.interfaceEmployee.firstName +
                ' ' +
                employee.interfaceEmployee.lastName +
                ' (' +
                this.shortJobDesc +
                ')'
            : '';
        } else {
          return value;
        }
      })
    );
  }
}
