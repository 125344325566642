import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocationTypeTranslate } from 'src/app/change-request-page/models/enums/customer-details';
import { AppState } from 'src/app/store';
import { getLocationId } from 'src/app/store/location/location.selectors';
import { select, Store } from '@ngrx/store';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerIdTypeCd, CustomerStatusCd } from '@xpo-ltl/sdk-common';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-related-locations',
  templateUrl: './related-locations.component.html',
  styleUrls: ['./related-locations.component.scss'],
})
export class RelatedLocationsComponent implements OnInit, OnDestroy {
  relatedLocations = [];
  private destroy$ = new Subject();

  constructor(private store: Store<AppState>, private customerApi: CustomerApiService) {}

  ngOnInit(): void {
    this.store.pipe(select(getLocationId), takeUntil(this.destroy$)).subscribe((id) => {
      this.customerApi
        .getRelatedLocations(
          { id: id },
          { customerIdTypeCd: /^\d+$/g.test(id) ? CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID : CustomerIdTypeCd.MAD_CODE },
          { loadingOverlayEnabled: false }
        )
        .pipe(take(1))
        .subscribe((res) => {
          if (res.relatedLocations) {
            this.relatedLocations = res.relatedLocations.map((loc) => {
              const address = res.customerLocation.customerAddress;
              return {
                type: LocationTypeTranslate[loc.functionCd],
                id: loc.madCd,
                link: loc.customerLocationFuncId,
                text: `, ${res.customerLocation.party1.partyName}, ${
                  res.customerLocation.party2 ? res.customerLocation.party2.partyName + ', ' : ''
                }${address.address}, ${address.cityName}, ${address.stateCd}, ${
                  address.zip4Cd ? address.zipCd + '-' + address.zip4Cd : address.zipCd
                }, ${address.countryCd}, Group: ${loc.groupNumber || 'None'}${
                  loc.statusCd !== CustomerStatusCd.ACTIVE ? ' (' + loc.statusCd + ')' : ''
                }`,
              };
            });
          } else {
            this.relatedLocations = [];
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
