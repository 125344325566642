import { Component } from '@angular/core';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
  host: {
    class: 'ncis-NotAuthorized',
  },
})
export class NotAuthorizedComponent {
  constructor() {}
}
