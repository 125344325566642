<div
  class="service-recipient-input-container"
  style="position: relative;"
  [attr.aria-labelledby]="parentFormField?.getLabelId()"
>
  <button
    mat-icon-button
    style="position: absolute; right: 16px; height: auto;"
    matTooltipPosition="above"
    matTooltip="Remove Service Recipient"
    (click)="removeValue($event)"
    *ngIf="hasValue && !this.disabled"
  >
    <mat-icon inline="true">delete</mat-icon>
  </button>
  <input [formControl]="searchControl" (focusout)="handleBlur()" matInput type="text" [matAutocomplete]="auto" />
  <mat-icon style="opacity: 0.5; margin-top: 2px;" matSuffix>search</mat-icon>
  <mat-autocomplete [displayWith]="showServiceRecipientNbr" (optionSelected)="updateValue($event)" #auto>
    <ng-container *ngIf="!loading && !noResults">
      <mat-option *ngFor="let result of results | async" [value]="result.value">{{ result.text }}</mat-option>
    </ng-container>
    <mat-option *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-option>
    <mat-option *ngIf="noResults">
      <div>No results found.</div>
    </mat-option>
  </mat-autocomplete>
</div>
