import { Component, Inject, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatchCustomerResp } from '@xpo-ltl-2.0/sdk-customer';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardApi, XpoAgGridBoardReadyEvent, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardViewConfig,
  XpoLocalStorageBoardViewDataStore,
} from '@xpo-ltl/ngx-board/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { ReplaySubject, Subject } from 'rxjs';
import { CustomerSearchService } from 'src/app/customer-search-page/services/customer-search.service';
import { LinkCellRendererComponent } from 'src/app/location-details-page/company-hierarchy/components/link-cell-renderer/link-cell-renderer.component';
import { RequesterNameRendererComponent } from 'src/app/shared/components/requester-name-cell-renderer/requester-name-cell-renderer.component';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { FunctionCodeLabel } from 'src/app/shared/enums/function-code.enum';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { GridApiContextService } from 'src/app/shared/services/grid-api-context/grid-api-context.service';

@Component({
  selector: 'app-match-customer-table',
  templateUrl: './match-customer-table.component.html',
  styleUrls: ['./match-customer-table.component.scss'],
  providers: [CustomerSearchService],
  encapsulation: ViewEncapsulation.None,
})
export class MatchCustomerTableComponent implements OnInit, OnDestroy, OnChanges {
  results: MatchCustomerResp;
  gridApi: GridApi;
  colApi: ColumnApi;
  gridBoardApi: XpoAgGridBoardApi;
  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  subscribeRoutes$: any;
  noData: boolean = false;
  hasChanges = false;
  dataSourceValue: any;
  titleText: string = '';
  googleAddress: string;
  boardOptions: XpoBoardOptions;
  customGridOptions: GridOptions;

  private destroy$ = new Subject<void>();
  constructor(
    private dialog: MatDialogRef<MatchCustomerTableComponent>,
    private errorStateManager: ErrorStateManagerService,
    private gridApiContextService: GridApiContextService,
    public dataSource: CustomerSearchService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.boardOptions = {
      addNewViewButtonLabel: '',
      suppressViewSwitcher: true,
      enableFilterReset: false,
      enableQueryParamStatePersistance: false,
      persistFiltersBetweenViews: false,
      preloadViewData: false,
      suppressGridDensity: true,
      suppressGridSettingsPopover: false,
      suppressRecordCounts: false,
    };
    this.customGridOptions = {
      suppressRowClickSelection: true,
      localeText: { noRowsToShow: 'No requests were found for your search criteria.' },
      frameworkComponents: {
        linkRenderer: LinkCellRendererComponent,
        requesterRenderer: RequesterNameRendererComponent,
      },
      animateRows: true,
      detailCellRendererParams: {
        customActions: {},
      },
      multiSortKey: 'ctrl',
      pagination: true,
      defaultColDef: {
        suppressMenu: true,
      },
      domLayout: 'normal',
      suppressPaginationPanel: true,
    };

    this.viewTemplates = this.getBoardViewTemplates();
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-match-customer', this.getBoardViews());
  }

  ngOnChanges(): void {
    this.setTitleText();
  }

  ngOnDestroy(): void {
    if (this.subscribeRoutes$) {
      this.subscribeRoutes$.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.errorStateManager.clearAll();
  }

  gridBoardReady(gridEvent: XpoAgGridBoardReadyEvent): void {
    this.gridApi = gridEvent.agGridApi;
    this.gridApiContextService.saveGridApi(this.gridApi);
    this.colApi = gridEvent.agGridColumnApi;
    this.gridBoardApi = gridEvent.agGridBoardApi;
  }

  ngOnInit(): void {
    this.dataSource.setResults(this.data);
    this.dataSourceValue = this.data;
    this.setTitleText();
  }

  updateTable(): void {
    this.refreshGrid();
  }

  refreshGrid(): void {
    this.dataSource.refresh();
  }

  private getBoardViews(): XpoBoardViewConfig[] {
    return [
      {
        templateId: 'template1',
        name: 'All - SD',
        closeable: false,
      },
    ];
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    const indexCol = {
      ...XpoAgGridColumns.RowIndex,
    };
    indexCol.suppressSizeToFit = true;
    indexCol.width = 32;
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'template1',
        name: 'Template 1',
        allowAdditional: true,
        availableColumns: [
          indexCol,
          {
            headerName: 'Account',
            field: 'madCd',
            width: 90,
            suppressMenu: true,
            sortable: false,
            cellRenderer: 'linkRenderer',
            cellRendererParams: (data) => {
              return {
                url: data.value && `${AppRoutes.LOCATION_PAGE}/${data.node.data.customerNbr}`,
                text: data.value,
              };
            },
          },
          {
            headerName: 'Type',
            field: 'customerTypeCd',
            width: 150,
            suppressMenu: true,
            valueFormatter: this.typeFormat,
            sortable: false,
          },
          {
            headerName: 'Name/Name2',
            field: 'name1',
            minWidth: 160,
            suppressMenu: true,
            valueFormatter: this.nameFormat,
            sortable: false,
          },
          {
            headerName: 'Address',
            field: 'addressLine1',
            minWidth: 200,
            suppressMenu: true,
            valueFormatter: this.addressFormat,
            sortable: false,
          },
          {
            headerName: 'Score',
            field: 'accuracyPercentage',
            width: 45,
            valueFormatter: this.scoreFormat,
            suppressMenu: true,
            sortable: false,
          },
        ],
        keyField: 'index',
      }),
    ];
  }

  nameFormat(params): string {
    return params.data?.name2 ? `${params.data?.name1}, ${params.data?.name2}` : `${params.data?.name1}`;
  }

  typeFormat(params): string {
    return `${FunctionCodeLabel[params.value]}/${params.node?.data?.matchResultTypeCd}`;
  }

  scoreFormat(params): string {
    return Math.round(params.value).toString();
  }

  addressFormat(params): string {
    return `${params.data?.addressLine1}, ${params.data?.cityName}, ${params.data?.stateCd} ${params.data?.postalCd}, ${params.data?.countryCd}`;
  }

  private setTitleText(): void {
    if (this.dataSourceValue) {
      let text: string;

      switch (true) {
        case this.dataSourceValue.length === 0:
          text = 'No customers were found for your search criteria.';
          break;
        case this.dataSourceValue.length > 1:
          text = `${this.dataSourceValue.length} potential duplicates found.`;
          break;
        case this.dataSourceValue.length === 1:
          text = `${this.dataSourceValue.length} potential duplicate found.`;
          break;
      }

      this.titleText = text;
    }
  }

  onCancel(): void {
    this.closeForm({ ignoreDuplicateCheck: false });
  }

  onContinue(): void {
    this.closeForm({ ignoreDuplicateCheck: true });
  }

  private closeForm(result): void {
    this.dialog.close(result);
  }
}
