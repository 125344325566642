export enum ExpireCustomerErrorCode {
  'CERR_000' = 'Customer cannot be expired, please contact Administrator.',
  'CERR_001' = 'Customer not found.',
  'CERR_002' = 'Customer is already expired.',
  'CERR_003' = 'Cannot expire Corporate customers with children.',
  'CERR_004' = 'Cannot expire a bill-to with invoice instructions.',
  'CERR_005' = 'Cannot expire customer with Active Pricing.',
  'CERR_006' = 'Cannot expire customer. An pricing agreement which expired within that last 16 months is part of an OBI program.',
  'CERR_007' = 'You cannot expire this MAD code.  It has been matched within the last 24 months.',
  'CERR_008' = 'You cannot expire a customer with open AR.',
  'CERR_009' = 'Wrong parameters used for expiring a customer',
  'CERR_010' = 'Customer does not have legacyCisCustNbr',
  'CERR_011' = 'Missing remarks',
  'CERR_012' = 'There was an error contacting Open AR API for validation. Please contact Administrator.',
  'CERR_013' = 'There was an error contacting Pricing Workbench API for validation. Please contact Administrator.',
  'CERR_014' = 'There was an error contacting Shipment ODS API for validation. Please contact Administrator.',
  'CERR_015' = 'There was an error contacting Ensemble Api Channel. Please contact Administrator.',
  'CERR_016' = 'Error getting the customer to expire.',
  'CERR_017' = 'You cannot expire this MAD code.  It has been matched within the last 18 months.',
}
