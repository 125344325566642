export enum AccountStatus {
  new = 'NEW',
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  expired = 'EXPIRED',
}

export enum AccountStatusValue {
  new = 'NEW',
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  expired = 'EXPIRED',
}
