import { Action } from '@ngrx/store';

export enum UserActionTypes {
  GetUser = '[Change Request] GetUser',
  GetUserRole = '[Change Request] GetUser Role',
  SetUser = '[Change Request] SetUser',
  SetUserRole = '[Change Request] SetUser Role',
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GetUser;
  constructor() {}
}
export class GetUserRole implements Action {
  readonly type = UserActionTypes.GetUserRole;
  constructor() {}
}

export class SetUser implements Action {
  readonly type = UserActionTypes.SetUser;
  constructor(public payload) {}
}
export class SetUserRole implements Action {
  readonly type = UserActionTypes.SetUserRole;
  constructor(public payload) {}
}

export type UserActions = GetUserRole | SetUserRole;
