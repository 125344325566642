import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ContactCustomerRelationship,
  ContactNote,
  ContactPerson,
  CustomerRequest,
  RequestContactPerson,
  UpdateCustomerRqst,
} from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import {
  ActionCd,
  CustomerDetailCd,
  CustomerIdTypeCd,
  CustomerLineStatusCd,
  CustomerRequestStatusCd,
} from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Observable, of, Subject } from 'rxjs';
import { map, skipWhile, take, takeUntil } from 'rxjs/operators';
import { ChangeRequestService } from 'src/app/change-request-page/services/change-request.service';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { ContactRoleCdLabel } from 'src/app/shared/enums/contact-role-code-label.enum';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import {
  getChangeRequest,
  getChangeRequestId,
  getRequestedChangeCurrentLine,
  loggedInUserIsOwner,
} from 'src/app/store/change-request/change-request.selectors';
import { getCurrentContactChanges } from 'src/app/store/details/details.selectors';
import { getLocationData } from 'src/app/store/location/location.selectors';
import { getCurrentContact } from 'src/app/store/location/location.selectors';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { DialogComponent, DialogConfig } from '../../dialog';
import { NewContactDialogComponent } from '../new-contact-dialog/new-contact-dialog.component';

@Component({
  selector: 'detail-contact',
  templateUrl: './detail-contact.component.html',
  styleUrls: ['./detail-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-Contact',
  },
})
export class DetailContactComponent implements ICellRendererAngularComp, OnDestroy {
  private destroy = new Subject();
  params: any;
  isChangeRequest = false;
  contactForm: UntypedFormGroup;
  originalData: any;
  gridActions: any;
  actionType: 'ADD' | 'UPDATE' | 'DELETE' | null = null;
  actionText = '';
  changeRequestId = null;
  remarks = '';
  roleTypeArray = Object.keys(ContactRoleCdLabel).map((key) => ({ label: ContactRoleCdLabel[key], value: key }));
  contactSeqNbr = 0;
  locationSeqNbr = '';
  customerRequest: CustomerRequest;
  requestChangesCurrentLocation: CustomerRequest;
  loggedInUserIsOwner: boolean = false;
  statusIsApproveOrRejected: boolean;
  statusIsRejected: boolean;
  statusIsApproved: boolean;
  statusIsHeld: boolean;
  isCompleted: boolean;
  isAbleToProcess: boolean;
  contactData;
  userRole$: Observable<UserRole>;

  private contactPersonDataChanged = false;
  private contactRelationshipChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private changeRequestService: ChangeRequestService,
    private snackbar: XpoSnackBar,
    private dialog: MatDialog,
    private locationDetailsService: LocationDetailService,
    private errorStateManager: ErrorStateManagerService
  ) {}

  agInit(params: any): void {
    this.isChangeRequest = this.isChangeRequestFn();
    this.params = params;
    this.actionType = params.data ? params.data.actionCd : null;
    this.statusIsApproved = this.params.data.statusCd === CustomerLineStatusCd.APPROVED;
    this.statusIsRejected = this.params.data.statusCd === CustomerLineStatusCd.REJECTED;
    this.statusIsApproveOrRejected = this.statusIsRejected || this.statusIsApproved;
    this.statusIsHeld = this.params.data.statusCd === CustomerLineStatusCd.HELD;
    this.store
      .select(getChangeRequestId)
      .pipe(takeUntil(this.destroy))
      .subscribe((changeRequestId) => {
        this.changeRequestId = changeRequestId;
      });
    this.store.select(getRequestedChangeCurrentLine).subscribe((res) => {
      const currLoc = res?.requestCustomerLocationFunction[0];
      this.requestChangesCurrentLocation = res;
      this.locationSeqNbr = currLoc?.requestFuncSequenceNbr;

      this.setInitialValues();
    });
    this.store.select(getChangeRequest).subscribe((res) => {
      this.customerRequest = res.changeRequest;
      this.isCompleted = this.customerRequest?.statusCd === CustomerRequestStatusCd.COMPLETED;
    });
    this.store.pipe(take(1), select(loggedInUserIsOwner)).subscribe((isOwner) => {
      this.loggedInUserIsOwner = isOwner;
    });
    this.gridActions = params.customActions || null;
    this.actionText = this.setActionText();
    this.remarks = params.data.requestNote
      ? (params.data.requestNote.find((n) => n.noteTypeCd === 'General') || {}).note
      : '';
    this.contactForm.controls['comments'].setValue(this.remarks);
    this.errorStateManager
      .errorChanges()
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.setAbleToProcess();
      });
    this.userRole$ = this.store.pipe(
      select(getLoggedInUserRole),
      skipWhile((role) => !role)
    );
  }

  refresh(): boolean {
    return false;
  }

  setInitialValues(): void {
    this.createFormGroup();
  }

  setActionText(): string {
    return this.isChangeRequest ? 'Requested Information' : 'Change To';
  }

  private setAbleToProcess(): void {
    if (this.actionType === 'UPDATE') {
      this.isAbleToProcess = !this.statusIsApproveOrRejected && !this.contactForm.errors;
    } else {
      this.isAbleToProcess =
        !this.errorStateManager.hasError(this.params.node.parent.rowIndex.toString()) && !this.statusIsApproved;
    }
  }

  createFormGroup(): void {
    const source = this.isChangeRequest
      ? this.store.pipe(
          select(getCurrentContactChanges, {
            currentContactId: this.params.data.contactPersonId,
            sequenceNbr: this.params.data.requestContactPersonSequenceNbr,
          })
        )
      : this.store.select(getCurrentContact, {
          currentContactId: this.params.data.contactPersonId,
        });

    source.subscribe((contact) => {
      this.contactData = contact;
      const contactPhoneData = {
        areaCd: contact.businessPhoneAreaCd,
        number: contact.businessPhoneNbr,
        countryCd: contact.businessPhoneCountryCd,
        extension: contact.businessPhoneExt,
      };
      const contactFaxData = {
        areaCd: contact.faxAreaCd,
        number: contact.faxNbr,
      };
      this.originalData = this.params.data;
      const formData = this.isChangeRequest ? contact : this.params.data;
      const processNote = this.changeRequestService.getProcessNote(this.params.data.requestNote);

      this.contactForm = this.fb.group({
        contactRoleCd: [formData.contactRoleCd || ''],
        firstName: [
          formData.firstName || '',
          [
            ValidatorHelper.required('Name is mandatory'),
            ValidatorHelper.maxLength(10, 'Must contain up to 10 characters'),
          ],
        ],
        lastName: [formData.lastName || '', [ValidatorHelper.maxLength(20, 'Must contain up to 20 characters')]],
        phoneNbr: [
          contactPhoneData,
          [
            ValidatorHelper.phoneNbrValidator('Invalid phone number'),
            ValidatorHelper.requiredPhoneNbr('Phone number is mandatory'),
          ],
        ],
        faxNbr: [contactFaxData, [ValidatorHelper.phoneNbrValidator('Enter a valid fax number.')]],
        emailId: [
          formData.emailId || '',
          [
            ValidatorHelper.maxLength(70, 'Must contain up to 70 characters'),
            ValidatorHelper.minLength(7, 'Must contain at least 7 characters'),
            ValidatorHelper.emailFormatValidator(),
          ],
        ],
        comments: [
          this.isChangeRequest && this.statusIsApproveOrRejected ? processNote && processNote.note : '',
          this.isChangeRequest
            ? [Validators.maxLength(800)]
            : [
                ValidatorHelper.required('Remarks is required.'),
                ValidatorHelper.maxLength(50, 'Must be less than 50 characters'),
              ],
        ],
      });

      if (this.actionType === 'UPDATE') {
        this.contactForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
          this.setAbleToProcess();

          if (!this.isChangeRequest) {
            this.contactPersonDataChanged = Object.keys(this.contactForm.controls)
              .filter((k) => !['primaryInd', 'comments'].includes(k))
              .some((key) => {
                switch (key) {
                  case 'phoneNbr':
                    return (
                      !this.compareStringEquals(this.contactData.businessPhoneNbr, value.phoneNbr.number) ||
                      !this.compareStringEquals(this.contactData.businessPhoneAreaCd, value.phoneNbr.areaCd) ||
                      !this.compareStringEquals(this.contactData.businessPhoneExt, value.phoneNbr.extension) ||
                      !this.compareStringEquals(this.contactData.businessPhoneCountryCd, value.phoneNbr.countryCd)
                    );
                  case 'faxNbr':
                    return (
                      !this.compareStringEquals(this.contactData.faxNbr, value.faxNbr.number) ||
                      !this.compareStringEquals(this.contactData.faxAreaCd, value.faxNbr.faxAreaCd)
                    );
                  default:
                    return !this.compareStringEquals(this.contactData[key], value[key]);
                }
              });
          }
        });
      }

      if (this.statusIsApproveOrRejected) {
        Object.keys(this.contactForm.controls).forEach((k) => this.contactForm.controls[k].disable());
      }
      this.setAbleToProcess();
      this.contactForm.markAllAsTouched();
    });
  }

  enableValidators(): void {
    this.contactForm.controls['comments'].setValidators([
      Validators.required,
      ValidatorHelper.noWhiteSpace(),
      Validators.maxLength(800),
    ]);
    this.contactForm.controls['comments'].updateValueAndValidity();
  }

  getErrorMessage(controlName: string): string {
    return this.contactForm &&
      this.contactForm.controls[controlName].invalid &&
      this.contactForm.controls[controlName].touched
      ? Object.keys(this.contactForm.controls[controlName].errors).reduce((acc, curr) => {
          acc += this.contactForm.controls[controlName].errors[curr] + '\n';
          return acc;
        }, '')
      : '';
  }

  onPhoneInputFocus(e: FocusEvent): void {
    const { value } = <HTMLInputElement>e.target;
    if (value.length > 3) {
      (<HTMLInputElement>e.target).value = value.replace(/[\(\) -]/g, '');
    }
  }

  onPhoneInputBlur(e: FocusEvent): void {
    const { value } = <HTMLInputElement>e.target;
    if (value.length > 3) {
      (<HTMLInputElement>e.target).value = `(${value?.substring(0, 3) || ''}) ${value?.substring(3, 6) ||
        ''}-${value?.slice(6) || ''}`;
    }
  }

  private compareStringEquals(str1, str2): boolean {
    return str1 === (str2 || undefined);
  }

  private processRequest(action, status): void {
    const requestPayload = Object.assign({}, this.customerRequest);
    this.changeRequestService
      .rejectHoldCustomerRequest(
        requestPayload,
        this.params.data.statusCd,
        this.contactForm.get('comments'),
        action,
        'CONTACT',
        status,
        this.getContactLine(requestPayload)
      )
      .subscribe((response) => {
        if (response) {
          this.snackbar.open({
            message: 'A requested change has been processed.',
            status: 'success',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          this.params.node.parent.setExpanded(false);
        }
      });
  }

  private getContactLine(requestPayload): RequestContactPerson {
    const currentLocation = Object.assign({}, this.requestChangesCurrentLocation.requestCustomerLocationFunction[0]);

    const contactLineRequest = Object.assign(
      {},
      this.customerRequest.requestContactPerson.find(
        (contact) => contact.requestContactPersonSequenceNbr === this.params.node.data.requestContactPersonSequenceNbr
      )
    );

    requestPayload.requestCustomerLocationFunction = [currentLocation];

    currentLocation.requestContactCustomerRelationship = [
      Object.assign(
        {},
        currentLocation.requestContactCustomerRelationship.find(
          (rc) => rc.requestContactPersonSequenceNbr === this.params.data.requestContactPersonSequenceNbr
        )
      ),
    ];

    if (currentLocation.requestContactCustomerRelationship[0].actionCd === 'DELETE') {
      contactLineRequest.requestContactNote = undefined;
    }

    requestPayload.requestContactPerson = [contactLineRequest];

    return contactLineRequest;
  }

  private getCurrentContactData(): any {
    const currentData: any = {};
    if (this.params.data.actionCd === 'UPDATE') {
      const phoneData = this.contactForm.controls['phoneNbr'].value;
      const faxData = this.contactForm.controls['faxNbr'].value;

      currentData.businessPhoneNbr = phoneData?.number;
      currentData.businessPhoneAreaCd = phoneData?.areaCd;
      currentData.businessPhoneCountryCd = phoneData?.countryCd;
      currentData.businessPhoneExt = phoneData?.extension;
      currentData.faxNbr = faxData?.number;
      currentData.faxAreaCd = faxData?.areaCd;
      currentData.firstName = this.contactForm.controls['firstName'].value;
      currentData.lastName = this.contactForm.controls['lastName'].value;
      currentData.emailId = this.contactForm.controls['emailId'].value;
      currentData.contactRoleCd = this.contactForm.controls['contactRoleCd'].value;
    } else {
      const parentNodeData = this.params.node.parent.data;

      currentData.firstName = parentNodeData.firstName;
      currentData.lastName = parentNodeData.lastName;
      currentData.emailId = parentNodeData.emailId;
      currentData.faxNbr = parentNodeData.faxNbr;
      currentData.faxAreaCd = parentNodeData.faxAreaCd;
      currentData.businessPhoneNbr = parentNodeData.businessPhoneNbr;
      currentData.businessPhoneAreaCd = parentNodeData.businessPhoneAreaCd;
      currentData.businessPhoneCountryCd = parentNodeData.businessPhoneCountryCd;
      currentData.businessPhoneExt = parentNodeData.businessPhoneExt;
      currentData.contactRoleCd = parentNodeData.contactRoleCd;
    }

    return currentData;
  }

  approveRequest(): void {
    if ((this.actionType !== 'ADD' && this.contactData.contactPersonId) || this.actionType === 'ADD') {
      const requestPayload = Object.assign({}, this.customerRequest);
      const currentLine = this.getContactLine(requestPayload);
      const madCd = requestPayload.requestCustomerLocationFunction[0].customerLocationFuncId;
      const currentContactData = this.getCurrentContactData();

      requestPayload.requestContactPerson[0].businessPhoneNbr = currentContactData.businessPhoneNbr;
      requestPayload.requestContactPerson[0].businessPhoneExt = currentContactData.businessPhoneExt;
      requestPayload.requestContactPerson[0].businessPhoneAreaCd = currentContactData.businessPhoneAreaCd;
      requestPayload.requestContactPerson[0].businessPhoneCountryCd = currentContactData.businessPhoneCountryCd;
      requestPayload.requestContactPerson[0].faxNbr = currentContactData.faxNbr;
      requestPayload.requestContactPerson[0].faxAreaCd = currentContactData.faxAreaCd;
      requestPayload.requestCustomerLocationFunction[0].requestContactCustomerRelationship[0].contactRoleCd =
        currentContactData.contactRoleCd;
      requestPayload.requestContactPerson[0].firstName = currentContactData.firstName;
      requestPayload.requestContactPerson[0].lastName = currentContactData.lastName;
      requestPayload.requestContactPerson[0].emailId = currentContactData.emailId;
      requestPayload.requestContactPerson[0].requestContactNote =
        this.actionType !== 'DELETE' && this.contactData.contactNote?.note ? this.contactData.contactNote : undefined;

      this.confirmApprove().subscribe((resp) => {
        if (resp) {
          this.changeRequestService
            .approveCustomerRequest(
              requestPayload,
              this.params.data.statusCd,
              this.contactForm.get('comments'),
              CustomerDetailCd.CONTACT,
              currentLine,
              false,
              true
            )
            .subscribe((response) => {
              if (response) {
                this.locationDetailsService
                  .getLocationData(madCd.toString(), CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID, true)
                  .subscribe((_) => {
                    this.params.customActions.update();
                    this.snackbar.open({
                      message: 'A requested change has been processed.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    this.params.node.parent.setExpanded(false);
                  });
              }
            });
        }
      });
    } else {
      this.snackbar.open({
        message: 'The contact has been deleted after this request has been submitted.  You must reject the line item.',
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    }
  }

  rejectRequest(): void {
    this.enableValidators();
    if (this.contactForm.get('comments').value) {
      this.processRequest('reject', CustomerLineStatusCd.REJECTED);
    }
  }

  holdRequest(): void {
    this.enableValidators();
    if (this.contactForm.get('comments').value) {
      this.processRequest('hold', CustomerLineStatusCd.HELD);
    }
  }

  closeForm(): void {
    this.params.node.parent.setExpanded(false);
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  getRemainingCharacters(): number {
    const charCount = this.contactForm.get('comments').value ? this.contactForm.get('comments').value.length : 0;
    return ConstantsService.textAreaMaxLength - charCount;
  }

  openEditDialog(): void {
    const data = { ...this.params.node.data, contactRelationshipChanged: this.contactRelationshipChanged };
    data.contactNote = this.contactData.contactNote;
    data.isChangeRequest = false;

    this.dialog
      .open(NewContactDialogComponent, { minWidth: 900, data: data, disableClose: true })
      .afterClosed()
      .subscribe((res) => {
        if (res.confirmed) {
          this.params.node.parent.setExpanded(false);
          this.params.customActions.update();
        }
      });
  }

  valueHasChanged(controlName: string): boolean {
    return (
      this.contactForm && (this.contactForm.controls[controlName].value || undefined) !== this.originalData[controlName]
    );
  }

  phoneNbrHasChanged(): boolean {
    const phoneData = this.contactForm?.get('phoneNbr')?.value;

    if (phoneData) {
      return (
        phoneData.areaCd !== this.originalData.businessPhoneAreaCd ||
        phoneData.countryCd !== this.originalData.businessPhoneCountryCd ||
        phoneData.number !== this.originalData.businessPhoneNbr ||
        phoneData.extension !== this.originalData.businessPhoneExt
      );
    } else {
      return false;
    }
  }

  faxNbrHasChanged(): boolean {
    const faxData = this.contactForm?.get('faxNbr')?.value;

    if (faxData) {
      return faxData.areaCd !== this.originalData.faxAreaCd || faxData.number !== this.originalData.faxNbr;
    } else {
      return false;
    }
  }

  openContactDetailsDialog(): void {
    const data = { ...this.params.node.data };
    data.contactNote = data.requestContactNote;
    data.isChangeRequest = true;

    this.dialog.open(NewContactDialogComponent, { minWidth: 900, data: data, disableClose: true });
  }

  updateCustomer(data, contactOnly = false): void {
    const contactData = this.contactData;
    this.store
      .select(getLocationData)
      .pipe(take(1))
      .subscribe((locData) => {
        const requestPayload = new UpdateCustomerRqst();
        requestPayload.customerDetailCd = CustomerDetailCd.CONTACT;
        const contactPerson = new ContactPerson();
        const contactRelationship = new ContactCustomerRelationship();
        const contactNote = new ContactNote();
        const [zipCd, zip4Cd] = data.zipCd !== undefined ? data.zipCd?.split('-') : contactData.zipCd || '';

        contactPerson.businessPhoneAreaCd = data.phoneNbr.areaCd;
        contactPerson.businessPhoneCountryCd = data.phoneNbr.countryCd;
        contactPerson.businessPhoneExt = data.businessPhoneExt;
        contactPerson.businessPhoneNbr = data.phoneNbr.number;
        contactPerson.firstName = data.firstName;
        contactPerson.lastName = data.lastName;
        contactPerson.faxNbr = data.faxNbr.number;
        contactPerson.faxAreaCd = data.faxNbr.areaCd;
        contactPerson.contactPersonId = this.params.data.contactPersonId;
        contactPerson.emailId = data.emailId;
        // if values are == undefined means they are not being changed
        // therefore just keep existing value
        contactPerson.title = data.title !== undefined ? data.title : contactData.title;
        contactPerson.address = data.address !== undefined ? data.address : contactData.address;
        contactPerson.cityName = data.cityName !== undefined ? data.cityName : contactData.cityName;
        contactPerson.stateCd = data.stateCd !== undefined ? data.stateCd : contactData.stateCd;
        contactPerson.countryCd = data.countryCd !== undefined ? data.countryCd : contactData.countryCd;
        contactPerson.zipCd = zipCd;
        contactPerson.zip4Cd = zip4Cd;
        contactRelationship.contactRoleCd = data.contactRoleCd;
        contactRelationship.customerLocationFuncId = locData.customerLocationFuncId;
        contactRelationship.inboundOutbCd = this.params.data.inboundOutbCd;
        contactNote.note = data.contactNote || contactData.contactNote?.note;
        contactNote.contactPersonId = contactData.contactPersonId;
        contactNote.sequenceNbr = contactData.contactNote?.sequenceNbr + 1 || null;
        if (contactData.contactNote?.sequenceNbr || data.contactNote) {
          requestPayload.contactNote = contactNote;
        }
        requestPayload.contactPerson = contactPerson;
        requestPayload.contacCustomerRel = contactRelationship;
        requestPayload.actionCd = ActionCd[this.actionType];
        requestPayload.ignoreAddressValidation = true;
        contactPerson.lastUpdateRemarks = data.remarks || data.comments;
        contactRelationship.lastUpdateRemarks =
          (!contactOnly && this.contactRelationshipChanged) || this.actionType === 'DELETE'
            ? data.comments
            : contactData.lastUpdateRemarks;

        this.locationDetailsService.updateCustomer(
          requestPayload,
          locData.customerLocationFuncId,
          () => {
            this.params.node.parent.setExpanded(false);
            this.params.customActions.update();
          },
          false,
          false,
          false,
          true
        );
      });
  }

  private confirmApprove(): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Update request line',
      content: `This request line was previously <b>Rejected</b>. Would you like to <b>Approve</b> it?.
      <br\><br\>
      NOTE: You can't undo this change.
      `,
      icon: 'help',
      actions: [
        {
          label: 'CONFIRM',
          type: 'primary',
          resultType: 'CLOSE',
          resultAction: true,
          position: 'right',
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CLOSE',
          resultAction: false,
          position: 'left',
        },
      ],
    };

    if (this.statusIsApproveOrRejected) {
      return this.dialog
        .open(DialogComponent, { maxWidth: '600px', data: dialog })
        .afterClosed()
        .pipe(map((resp) => resp.resultAction));
    }

    return of(true);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
