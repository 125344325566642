export enum Country {
  unitedStates = 'US',
  canada = 'CA',
  mexico = 'MX',
}

export enum CountryValue {
  unitedStates = 'US',
  canada = 'CA',
  mexico = 'MX',
}
