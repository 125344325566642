<div
  class="container"
  [matTooltipDisabled]="valueControl.valid"
  tabindex="0"
  (focusin)="onFocus($event)"
  [matTooltip]="currentErrorMessage"
  matTooltipPosition="above"
>
  <div *ngIf="!isEditing; else editMode">
    {{ value }}
  </div>
  <ng-template #editMode>
    <mat-form-field>
      <input
        #input
        matInput
        (blur)="onBlur()"
        (keypress)="onKeypress($event)"
        (keydown)="onKeyDown($event)"
        type="text"
        [formControl]="valueControl"
      />
    </mat-form-field>
  </ng-template>
</div>
