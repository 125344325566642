import { Pipe, PipeTransform } from '@angular/core';
import { CustomerLineStatusCd } from '@xpo-ltl/sdk-common';
import { CustomerLineStatusCdLabel } from '../enums/customer-line-status-cd-label.enum';

@Pipe({
  name: 'requestLineStatus',
})
export class RequestLineStatusPipe implements PipeTransform {

  transform(statusCd: CustomerLineStatusCd): string {
    switch (true) {
      case statusCd === CustomerLineStatusCd.HELD:
        return 'On Hold';
      default:
        return CustomerLineStatusCdLabel[statusCd];
    }
  }
}
