import { Injectable } from '@angular/core';
import { CustomerApiService, ListServiceRecipientQuery } from '@xpo-ltl-2.0/sdk-customer';
import {XpoAgGridBoardData} from '@xpo-ltl/ngx-board/ag-grid';
import {XpoBoardData, XpoBoardDataSource, XpoBoardState} from '@xpo-ltl/ngx-board/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ServiceRecipientSortFields, SortOrder } from 'src/app/shared/enums/service-reciepient-sort-fields.enum';
import { GridApiContextService } from 'src/app/shared/services/grid-api-context/grid-api-context.service';

@Injectable({ providedIn: 'root' })
export class ServiceRecipientResultDialogService extends XpoBoardDataSource {
  private results: any;
  private search: string;

  constructor(private customerApi: CustomerApiService, private gridApiContextService: GridApiContextService) {
    super();
  }

  setSearch(search: string): void {
    this.search = search;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const gridApi = this.gridApiContextService.getCurrentGridApi();
    const listInfoQuery = {
      listInfo: {
        startAt: ((state.pageNumber || 1) - 1) * gridApi.paginationGetPageSize(),
        numberOfRows: gridApi.paginationGetPageSize(),
        sortFields:
          state.sortOrder && state.sortOrder.length
            ? state.sortOrder.map((col) => {
                return { sortByFieldName: ServiceRecipientSortFields[col.column], sortOrder: SortOrder[col.direction] };
              })
            : [
                {
                  sortByFieldName: ServiceRecipientSortFields.serviceRecipientName,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.state,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.city,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.address,
                  sortOrder: SortOrder.asc,
                },
                {
                  sortByFieldName: ServiceRecipientSortFields.typeOfServiceInd,
                  sortOrder: SortOrder.asc,
                },
              ],
      },
    };

    const queryParams = new ListServiceRecipientQuery();
    queryParams.serviceRecipientName = this.search;

    Object.assign(queryParams, listInfoQuery);

    const subscription$ = this.customerApi.listServiceRecipient(queryParams, { loadingOverlayEnabled: false });

    return subscription$.pipe(
      take(1),
      map((resp) => {
        return resp.serviceRecipient.length === 0
          ? new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state))
          : new XpoBoardData(state, resp.serviceRecipient, resp.listInfo.totalRowCount, resp.listInfo.numberOfRows);
      })
    );
  }
}
