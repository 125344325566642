import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-view-details-icon',
  templateUrl: './view-details-icon.component.html',
  styleUrls: ['./view-details-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-GridActions-wrapper ncis-EditIconComponent',
  },
})
export class ViewDetailsComponent implements ICellRendererAngularComp {
  params: any;

  constructor(private router: Router, private store: Store<AppState>) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
