export enum AppRoutes {
  DASHBOARD_PAGE = 'dashboard',
  DASHBOARD_CODER_PAGE = 'dashboard-coder',
  CHANGE_REQUEST_PAGE = 'change-request',
  CIS_SEARCH_PAGE = 'cis-search',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  LOCATION_PAGE = 'location',
  NEW_LOCATION_PAGE = 'new-location',
  SERVICE_RECIPIENT_PAGE = 'service-recipient',
  CREDIT_MANAGEMENT_PAGE = 'credit-management',
  CUSTOMER_SEARCH_PAGE = 'customer-search',
  PND_MERGE_PAGE = 'pnd-merge',
  CREATE_CUSTOMER_PAGE = 'create-customer',
}
