import { Action } from '@ngrx/store';

export enum ChangeRequestActionTypes {
  GetChangeRequest = '[Change Request] GetChangeRequest',
  SetChangeRequest = '[Change Request] SetChangeRequest',
  SetEmployees = '[Change Request] SetEmployees',
  GetEmployees = '[Change Request] GetEmployees',
  GetLoggedInUser = '[Change Request] GetLoggedInUser',
  SetLoggedInUser = '[Change Request] SetLoggedInUser',
  GetRequestedChangesCurrentLocation = '[Change Request] GetRequestedChangesCurrentLocation',
  SetRequestedChangesCurrentLocation = '[Change Request] SetRequestedChangesCurrentLocation',
  SetRequestedChangesCurrentLine = '[Change Request] SetRequestedChangesCurrentLine',
}

export class GetChangeRequest implements Action {
  readonly type = ChangeRequestActionTypes.GetChangeRequest;
  constructor() {}
}

export class SetChangeRequest implements Action {
  readonly type = ChangeRequestActionTypes.SetChangeRequest;
  constructor(public payload) {}
}

export class SetEmployees implements Action {
  readonly type = ChangeRequestActionTypes.SetEmployees;
  constructor(public payload) {}
}

export class GetEmployees implements Action {
  readonly type = ChangeRequestActionTypes.GetEmployees;
  constructor() {}
}

export class SetLoggedInUser implements Action {
  readonly type = ChangeRequestActionTypes.SetLoggedInUser;
  constructor(public payload) {}
}

export class GetLoggedInUser implements Action {
  readonly type = ChangeRequestActionTypes.GetLoggedInUser;
  constructor() {}
}
export class SetRequestedChangesCurrentLocation implements Action {
  readonly type = ChangeRequestActionTypes.SetRequestedChangesCurrentLocation;
  constructor(public payload) {}
}

export class GetRequestedChangesCurrentLocation implements Action {
  readonly type = ChangeRequestActionTypes.GetRequestedChangesCurrentLocation;
  constructor() {}
}

export class SetRequestedChangesCurrentLine implements Action {
  readonly type = ChangeRequestActionTypes.SetRequestedChangesCurrentLine;
  constructor(public payload) {}
}

export type ChangeRequestActions =
  | GetChangeRequest
  | SetChangeRequest
  | SetEmployees
  | GetEmployees
  | GetLoggedInUser
  | SetLoggedInUser
  | SetRequestedChangesCurrentLocation
  | GetRequestedChangesCurrentLocation
  | SetRequestedChangesCurrentLine;
